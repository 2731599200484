import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

//Pages and components
import Login from '../pages/Login';
import Cgu from '../pages/Cgu';

const unregistered = () => {
  //Render
  return (
    <Routes>
      <Route
        path="/"
        element={<Login />}
      />
      <Route
        path="/*"
        element={
          <Navigate
            replace
            to="/"
          />
        }
      />
      <Route
        path="/politique-de-confidentialite&mentions-legales"
        element={<Cgu />}
      />
    </Routes>
  );
};

export default unregistered;
