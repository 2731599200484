import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import components
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';

const Materials = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListMaterials = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.getListMaterials);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listMaterials, setListMaterials] = useState([]);

  //  🌱🌱 Request call List Material  🌱🌱
  const callListMaterials = async () => {
    try {
      const res = await getListMaterials();
      setListMaterials(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des marchandises');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListMaterials();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="h-full overflow-y-auto">
      {/* div main */}
      <div className="flex flex-col justify-between h-full">
        {/* div table */}
        <div className="mt-12 mx-5 md:mx-9">
          <Table>
            <thead>
              <tr>
                <th>Matériaux</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {listMaterials.length ? (
                listMaterials.map((material) => (
                  <tr key={material.id}>
                    <td>{material?.name}</td>
                    <td>
                      <Link
                        to={`/settings/edit-materials/${material.id}`}
                        className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                      >
                        <img
                          src={More}
                          alt="more icon"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                  >
                    Liste des marchandises est vide
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default Materials;

