import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationCompany from '../shared/layout/NavigationCompany';

const NameCompany = () => {
  return (
    <>
      <NavigationCompany />
      <Outlet />
    </>
  );
};

export default NameCompany;

