const listMonth = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const oneDay = 86400000; // milliseconde
export const timeToMs = {
  day: oneDay,
  mounth: oneDay * 31,
  year: oneDay * 365,
};

export const dateFormat = (date, separation = '-') => {
  const temps = new Date(date);

  return {
    year: temps.getFullYear().toString(),
    month: ('0' + (temps.getMonth() + 1)).slice(-2),
    day: ('0' + temps.getDate()).slice(-2),
    full: `${('0' + temps.getDate()).slice(-2)}${separation}${('0' + (temps.getMonth() + 1)).slice(
      -2
    )}${separation}${temps.getFullYear().toString()}`,
    stringFull: `${joursUS[temps.getDay()]} ${('0' + temps.getDate()).slice(-2)} ${
      mois[temps.getMonth()]
    } ${temps.getFullYear()}`,
  };
};

export const timeFormat = (date, separation = ':') => {
  const temps = new Date(date);

  return {
    hours: temps.getHours().toString(),
    minutes: temps.getMinutes().toString(),
    seconds: temps.getSeconds.toString(),
    full: `${('0' + temps.getHours()).slice(-2)}${separation}${('0' + (temps.getMinutes() + 1)).slice(-2)}`,
  };
};

export const requestFormatDate = (date) => {
  const temps = new Date(date);

  return {
    year: temps.getFullYear().toString(),
    month: listMonth[temps.getMonth() + 1],
    day: ('0' + temps.getDate()).slice(-2),
  };
};

export const jours = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
export const joursUS = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export const mois = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const whatMorning = (date) => {
  const currentDate = new Date(date);
  let dayNumber = currentDate.getDay() - 1;
  if (dayNumber === -1) {
    dayNumber = 6;
  }
  const lastMonday = dayNumber === 0 ? currentDate : new Date(currentDate.getTime() - oneDay * dayNumber);

  let result = {
    curentDayIndex: dayNumber,
    Morning: [],
  };

  for (let index = 0; index < 7; index++) {
    result.Morning[index] = { date: new Date(lastMonday.getTime() + oneDay * index) };
  }

  return result;
};

