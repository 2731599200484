import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const OrderMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST ALL ORDER
  //✨✨==========================================✨✨
  getListAllOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders {
          items {
            numero
            id
            quantity
            price
            marchandise {
              id
              name
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST ORDER FINISH
  //✨✨==========================================✨✨
  getListOrderFinish: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {eq: "done"}}) {
          items {
            id
            date
            numero
            shipType
            document
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST ORDER NOT FINISH
  //✨✨==========================================✨✨
  getListOrderNotFinish: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {notContains: "done"}}) {
          items {
            id
            date
            numero
            shipType
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST ORDER FINISH BY CLIENT
  //✨✨============================================✨✨
  getListOrderFinishByClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {eq: "done"}, clientOrderId: {eq: "${payload}"}}) {
          items {
            id
            date
            numero
            shipType
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST ORDER NOT FINISH BY CLIENT
  //✨✨============================================✨✨
  getListOrderNotFinishByClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {notContains: "done"}, clientOrderId: {eq: "${payload}"}}) {
          items {
            id
            date
            numero
            shipType
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST ORDER BY DATE
  //✨✨============================================✨✨
  getListOrderByDate: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listOrders(filter: {datePlanned: {contains: "${payload?.month} ${payload?.day} ${payload?.year}"}}) {
        items {
          id
          date
          numero
          shipType
          statut
          address {
            address
          }
          client {
            id
            firstName
            lastName
            company
            tokenFcm
          }
          marchandise {
            id
            name
          }
          datePlanned
          driver {
            id
            firstName
            lastName
            tokenFcm
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST ORDER BY DATE BY DRIVER
  //✨✨============================================✨✨
  getListOrderByDateByDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listOrders(filter: {datePlanned: {contains: "${payload?.month} ${payload?.day} ${payload?.year}"}, and: {driverOrderId: {eq: "${payload?.idDriver}"}}}) {
        items {
          id
          date
          numero
          shipType
          address {
            address
          }
          client {
            id
            firstName
            lastName
            company
            tokenFcm
          }
          marchandise {
            id
            name
          }
          datePlanned
          driver {
            id
            firstName
            lastName
            tokenFcm
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET ORDER
  //✨✨============================================✨✨
  getOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getOrder(id: "${payload}") {
        id
        date
        numero
        price
        document
        orderAddressId
        orderModelDayId
        modelDay {
          id
          price
          name
        }
        client {
          id
          firstName
          lastName
          company
          address {
            items {
              id
              address
              price
              archived
            }
          }
        }
        marchandise {
          id
          name
        }
        datePlanned
        driver {
          id
          firstName
          lastName
          tokenFcm
        }
        statut
        shipType
        quantity
        address {
          id
          address
          price
        }
        driver {
          id
          firstName
          lastName
        }
        datePlanned
        client {
          id
          firstName
          lastName
          company
          address {
            items {
              id
              address
            }
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getOrder;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            CREATE ORDER
  //✨✨============================================✨✨

  creatOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    console.log("test");
    const graphqlQuery = `
    mutation MyMutation {
        createOrder(input: {
            clientOrderId: "${payload?.clientOrderId}", 
            date: "${payload?.date}", 
            datePlanned: "${payload?.datePlanned}", 
            numero: "${payload?.numero}", 
            orderAddressId: "${payload?.orderAddressId}", 
            orderMarchandiseId: "${payload?.orderMarchandiseId || ''}", 
            shipType: "${payload?.shipType}", 
            price: "${payload?.price}", 
            quantity: "${payload?.quantity}", 
            driverOrderId: "${payload?.driverOrderId || ''}",
            orderModelDayId: "${payload?.orderModelDayId || ''}",
            statut: "en cours",
            step: "0"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createOrder;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            UPDATE ORDER
  //✨✨============================================✨✨

  updateOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateOrder(input: {
            id: "${payload?.id}", 
            clientOrderId: "${payload?.clientOrderId}", 
            datePlanned: "${payload?.datePlanned}", 
            orderAddressId: "${payload?.orderAddressId}", 
            orderMarchandiseId: "${payload?.orderMarchandiseId || ''}",
            shipType: "${payload?.shipType}", 
            price: "${payload?.price}", 
            quantity: "${payload?.quantity}", 
            driverOrderId: "${payload?.driverOrderId}",
            orderModelDayId: "${payload?.orderModelDayId || ''}",
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateOrder;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            UPDATE MOVE ORDER
  //✨✨============================================✨✨

  updateMoveOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateOrder(input: {
            id: "${payload?.id}", 
            datePlanned: "${payload?.datePlanned}"
        }) 
        {
          id
        }
      }
    `;
    // getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateOrder;
      return myData;
    } finally {
      // getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            UPDATE DRIVER ORDER
  //✨✨============================================✨✨

  updateDriverOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateOrder(input: {
            id: "${payload?.id}", 
            driverOrderId: "${payload?.driverOrderId}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateOrder;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            DELETE ORDER
  //✨✨============================================✨✨

  deleteOrder: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        deleteOrder(input: {id: "${payload}"}) {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteOrder;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default OrderMiddleware;

