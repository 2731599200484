import { thunk } from 'easy-peasy';
import AWS from 'aws-sdk';
import AWSIAM from './config/awsIAMConfigure';

//Config AWS SDK S3
const S3_BUCKET = AWSIAM.bucketName;
const REGION = AWSIAM.region;

AWS.config.update(AWSIAM);

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const FileS3 = {
  //✨✨==========================================✨✨
  //            UPLOAD FILE S3
  //✨✨==========================================✨✨

  uploadFile: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const params = {
      Body: payload?.file,
      Bucket: S3_BUCKET,
      Key: payload?.nameFile,
      ContentType: payload?.contentType,
    };
    getStoreActions().global.setActivity(true);
    return myBucket.putObject(params).send((err) => {
      if (err) {
        throw err;
      } else {
        return true;
      }
    });
  }),
};

export default FileS3;

