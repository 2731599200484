import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import './containerStyle.css';

//components custom
import SideBar from './SideBar';
import NaveBar from './NaveBar';
import Activity from '../components/Activity';

const Container = ({ children }) => {
  //init navigation
  const navigate = useNavigate();

  //init store
  const connected = useStoreState((state) => state.route.connected);

  //init location
  const location = useLocation();
  const pathLocation = location.pathname.split('/');
  const isVisibleBtnBack =
    pathLocation.length === 2 || (pathLocation.length === 4 && pathLocation[3] === 'list-order-not-finish')
      ? false
      : true;

  //init ref
  const btnOpenSidebar = useRef(null);
  const btnCloseSidebar = useRef(null);
  const sideBar = useRef(null);

  const showSideBar = () => {
    sideBar.current.style.height = '100%';
  };

  const hiddenSideBar = () => {
    sideBar.current.style.height = '0%';
  };

  // func responsive js sidebar
  const sideBarEvent = (event) => {
    if (event.target.innerWidth > 767 && !!connected) {
      showSideBar();
    } else if (event.target.innerWidth < 768 && !!connected) {
      hiddenSideBar();
    }
  };

  //init effect
  useEffect(() => {
    window.addEventListener('resize', sideBarEvent);
    return () => {
      window.removeEventListener('resize', sideBarEvent);
    };
  }, []);

  //init effect
  useEffect(() => {
    if (window.innerWidth < 768 && !!connected) {
      hiddenSideBar();
    }
  }, [location]);

  //Render
  return (
    <>
      <div className="containerDSH ">
        <div
          className="sideBar"
          ref={sideBar}
        >
          <img
            src="/assets/images/btnClose.svg"
            className="btnCloseSidebar stroke-red-admin cursor-pointer"
            ref={btnCloseSidebar}
            onClick={hiddenSideBar}
            alt="btn close"
          ></img>
          <SideBar />
        </div>
        <div className="child">
          <div className="bar_side">
            <img
              className={`h-8 cursor-pointer ${isVisibleBtnBack ? '' : 'hidden'}`}
              src="/assets/images/backBtn.svg"
              alt="logo"
              onClick={() => navigate(-1)}
            />
            <p>Transport Ozier & Fils</p>
            <button
              className="btnOpenSidebar text-xl text-blue-admin"
              ref={btnOpenSidebar}
              onClick={showSideBar}
            >
              |||
            </button>
          </div>
          <div className="header">
            <NaveBar />
          </div>
          <div className="body">
            <Outlet />
            {children}
            <Activity />
          </div>
        </div>
      </div>
    </>
  );
};

export default Container;
