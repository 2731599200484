import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const FollowMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST FOLLOW
  //✨✨==========================================✨✨
  getListFollow: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listFollows(filter: {truckFollowId: {eq: "${payload}"}}) {
          items {
            id
            title
            date
            description
            document
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listFollows.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE FOLLOW
  //✨✨==========================================✨✨
  createFollow: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createFollow(input: {
            truckFollowId: "${payload?.idTruck}", 
            title: "${payload?.title}", 
            document: "${payload?.document}",
            date: "${payload?.date}",
            description:"${payload?.description}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createFollow;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE FOLLOW
  //✨✨==========================================✨✨
  deleteFollow: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        deleteFollow(input: {id: "${payload}"}) {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteFollow;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default FollowMiddleware;

