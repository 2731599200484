import React from 'react';
import { useStoreState } from 'easy-peasy';

//logging
import Registed from './Registed';
import Unregistered from './Unregistered';

const Index = () => {
  //init store
  const connected = useStoreState((state) => state.route.connected);

  //render
  return connected ? <Registed /> : <Unregistered />;
};

export default Index;
