import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationSettings from '../shared/layout/NavigationSettings';

const Settings = () => {
  return (
    <>
      <NavigationSettings />
      <Outlet />
    </>
  );
};

export default Settings;

