/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import SecondaryButton from '../SecondaryButton';
import PrimaryButton from '../PrimaryButton';
import PrimaryInput from '../PrimaryInput';
import Modal from '../Modal';
import DatePicker_date from '../DatePicker_date';
import SoloInputForm from '../SoloInputForm';
import { dateFormat } from '../../tools/date';
import { lastNumberCoupon } from '../../tools/file';

//import icons
import Check from '../../../assets/images/svg/check.svg';
import PlusIcon from '../../../assets/images/png/plus_icon.png';

const refShip = [{ name: 'Tournée' }, { name: 'Journée' }];

const AddOrder = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱 Init store(services)  🌱🌱
  const getListClients = useStoreActions((actions) => actions.middleware.ClientsMiddleware.getListClients);
  const getListMaterials = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.getListMaterials);
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);
  const getListPricesDays = useStoreActions((actions) => actions.middleware.PricesDayMiddleware.getListPricesDays);
  const getListAllOrder = useStoreActions((actions) => actions.middleware.OrderMiddleware.getListAllOrder);
  const creatOrder = useStoreActions((actions) => actions.middleware.OrderMiddleware.creatOrder);
  const sendNotificationPush = useStoreActions((actions) => actions.middleware.Notification.sendNotificationPush);
  const addPriceAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.addPriceAddress);
  const createNotif = useStoreActions((actions) => actions.middleware.Notification.createNotif);
  const createAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.createAddress);

  //  🌱🌱  Init use Ref  🌱🌱
  const submitRefLivraison = useRef();
  const submitRefCommande = useRef();
  const submitRefCommande2 = useRef();
  const submitRefCommande3 = useRef();
  const formFull = useRef({});

  //  🌱🌱  Etats (données)  🌱🌱
  const [listClients, setListClients] = useState([]);
  const [listShipTypes] = useState(refShip);
  const [listMaterials, setListMaterials] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectClient, setselectClient] = useState('');
  const [selectAddress, setselectAddress] = useState('');
  const [listPricesDay, setListPricesDay] = useState([]);
  const [msgErrStClient, setmsgErrStClient] = useState(false);
  const [msgErrStaddres, setmsgErrStaddres] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalAddres, setShowModalAddres] = useState(false);
  const [listeAddressSelect, setlisteAddressSelect] = useState([]);
  const [datePlanned, setdatePlanned] = useState(new Date());
  const [inputPrice, setInputPrice] = useState(0);
  const [tonnage, setTonnage] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shipType, setshipType] = useState('');
  const [add_adresse, setAdd_adresse] = useState('');
  const [add_price, setAdd_price] = useState('');
  const [nbTournne, setNbTournne] = useState(1);
  const [totalTonnage, setTotalTonnage] = useState(0);
  const [arrayTonnage, setArrayTonnage] = useState([]);

  //  🌱🌱  formulaire commande  🌱🌱
  const FormOrderShip = [
    {
      label: 'Créée le :',
      inputType: 'text',
      valueName: 'date',
      placeholder: 'XX . XX . XX',
      errorMsg: 'erreur date',
      required: false,
      halfWidth: true,
      disabled: true,
      defaultValue: dateFormat(new Date()).full,
      style: ' placeholder:text-blue-admin',
    },
    {
      label: 'Type de commande',
      inputType: 'select',
      valueName: 'shipType',
      options: listShipTypes,
      keyOptions: 'name',
      keyValues: 'name',
      defaultValue: 'Rotation',
      errorMsg: 'Veuillez sélectionner un type de commande',
      halfWidth: true,
      required: true,
      placeholder: '- Type -',
      style: ' placeholder:text-blue-admin',
      onChange: true,
    },
  ];

  //  🌱🌱  formulaire Marchandises  🌱🌱
  const FormOrderMaterial = [
    {
      label: 'Marchandises',
      inputType: 'select',
      valueName: 'orderMarchandiseId',
      options: listMaterials,
      keyOptions: 'name',
      keyValues: 'id',
      errorMsg: 'choisir une marchandise',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      placeholder: '- Marchandises -',
    },
  ];

  //  🌱🌱  formulaire livraison  🌱🌱
  const FormShip = [
    {
      label: 'Assigné à',
      inputType: 'select',
      valueName: 'driverOrderId',
      options: drivers,
      keyOptions: 'fullName',
      keyValues: 'id',
      errorMsg: 'Veuillez sélectionner un chauffeur',
      halfWidth: '1/2-full',
      required: false,
      style: ' placeholder:text-blue-admin',
    },
  ];

  //  🌱🌱  formulaire type journée  🌱🌱
  const FormShipDay = [
    {
      label: 'Type de camion',
      inputType: 'select',
      valueName: 'orderModelDayId',
      options: listPricesDay,
      keyOptions: 'name',
      keyValues: 'price',
      errorMsg: 'Veuillez sélectionner un type de Journée',
      halfWidth: true,
      required: true,
      placeholder: '- Mode -',
      style: ' placeholder:text-blue-admin',
      onChange: true,
    },
  ];

  //  🌱🌱 Request call List Admins  🌱🌱
  const callListClients = async () => {
    try {
      const res = await getListClients();
      // Sort by company name
      res.sort((a, b) => {
        if (a.company < b.company) {
          return -1;
        }
        if (a.company > b.company) {
          return 1;
        }
        return 0;
      });
      const listClientData = res.map((el) => {
        return { ...el, fullName: `${el.firstName} ${el.lastName}` };
      });
      setListClients(listClientData);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des clients');
    }
  };

  //  🌱🌱 Request call List Material  🌱🌱
  const callListMaterials = async () => {
    try {
      const res = await getListMaterials();
      setListMaterials(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des marchandises');
    }
  };

  //  🌱🌱 Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      // Sort by name
      res.sort((a, b) => {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      });
      setDrivers(
        res.map((truck) => {
          return { ...truck, fullName: `${truck.firstName} ${truck.lastName}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱 Request call List Order  🌱🌱
  const callListOrder = async () => {
    try {
      const res = await getListAllOrder();
      arrayTonnage.forEach((el) => {
        console.log('el', el);
        formFull.current = {
          ...formFull.current,
          quantity: el.value,
          numero: lastNumberCoupon(res, 'numero'),
        };
        createProfileOrder(formFull.current);
      });
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la creation de la commade.');
    }
  };

  //  🌱🌱 Request create notification (history)  🌱🌱
  const callcreateNotif = async (data) => {
    try {
      await createNotif(data);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la mise à jours de l'historique");
    }
  };

  //  🌱🌱 Request create Order  🌱🌱
  const createProfileOrder = async (data) => {
    console.log('data', data);
    try {
      const res = await creatOrder(data);
      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error('❗️ Une erreur est survenue lors de la creation de la commade.');
      formFull.current = {};
    }
    try {
      if (!!formFull.current.alldataclient.tokenFcm) {
        const newData = {
          title: 'Ozier Transport',
          message: `La commande n°${data.numero} vient d'être ajouté à votre liste de commade`,
          tokenFcm: formFull.current.alldataclient.tokenFcm,
        };
        sendNotificationPush(newData);
        callcreateNotif({
          ...newData,
          idUser: formFull?.current?.alldataclient?.id,
          date: Date.now(),
          title: 'Commande',
        });
      }
      if (!!formFull.current.alldataDriver.tokenFcm) {
        const newData = {
          title: 'Ozier Transport',
          message: `La commande n°${data.numero} vient d'être ajouté à votre liste de livraison`,
          tokenFcm: formFull.current.alldataDriver.tokenFcm,
        };
        sendNotificationPush(newData);
        callcreateNotif({
          ...newData,
          idUser: formFull?.current?.alldataDriver?.id,
          date: Date.now(),
          title: 'Commande',
        });
      }
      setShowModal(true);
    } catch (error) {
      console.log(error);
      toast.error(
        "❗️ Une erreur est survenue lors de l'envoie des notifications il est possible que le client ou le chauffeur ne recoivent rien "
      );
      formFull.current = {};
    } finally {
    }
  };

  //  🌱🌱 Request call List prices day  🌱🌱
  const callListPricesDay = async () => {
    try {
      const res = await getListPricesDays();
      setListPricesDay(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des prix a la journée');
    }
  };

  //  🌱🌱 Request add price adress client  🌱🌱
  const updatePriceAdress = async (data) => {
    try {
      await addPriceAddress(data);
      callListOrder();
    } catch (error) {
      toast.error("❗️ Une erreur est survenue lors de l'ajout du nouveau prix à l'adresse.");
    }
  };

  //  🌱🌱 Request create Adresse  🌱🌱
  const createProfileAddress = async () => {
    const newData = {
      address: add_adresse,
      price: add_price,
      idClient: selectClient,
    };
    try {
      await createAddress(newData);
      await callListClients();
      setShowModalAddres(false);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant l'ajout de l'adresse, veuillez réessayer.");
    }
  };

  //  🌱🌱  manage select client  🌱🌱
  const handleChangeSelectClient = (e) => {
    if (e.target.value !== '') {
      const myList = listClients.find((el) => el.id === e.target.value).address.items;
      setlisteAddressSelect(myList.filter((ad) => ad.archived === false));
    } else {
      setlisteAddressSelect([]);
    }
    setselectClient(e.target.value);
    setselectAddress('');
    setInputPrice('');
    setmsgErrStClient(false);
  };

  //   🌱🌱 manage select address  🌱🌱
  const handleChangeSelectAddress = (e) => {
    setselectAddress(e.target.value);
    setmsgErrStaddres(false);

    setInputPrice(
      listClients.find((cl) => cl?.id === selectClient)?.address?.items?.find((ad) => ad?.id === e.target.value)?.price
    );
  };

  //   🌱🌱 manage on change input FormOrderShip   🌱🌱
  const handleChangeFormOrderShip = (data) => {
    if (data?.shipType) {
      setshipType(data.shipType);
    }
    if (data?.shipType === '') {
      setshipType('');
    }
    setTonnage(1);
    formFull.current = {};
  };

  //   🌱🌱 manage on change input FormShipDay   🌱🌱
  const handleChangeFormShipDay = (e) => {
    setTotalPrice(e.orderModelDayId);
  };

  //  🌱🌱  submit form  🌱🌱
  const onSubmit = (value) => {
    if (selectClient === '') {
      setmsgErrStClient(true);
    } else {
      formFull.current = { ...formFull.current, clientOrderId: selectClient };
    }
    if (selectAddress === '') {
      setmsgErrStaddres(true);
    } else {
      console.log('ici', formFull.current);
      formFull.current = { ...formFull.current, orderAddressId: selectAddress };
    }
    if (value.status) {
      formFull.current = { ...formFull.current, ...value.values, date: new Date(), datePlanned: datePlanned };
    }
    formFull.current = { ...formFull.current, price: totalPrice, quantity: tonnage };
  };

  //  🌱🌱  action btn submit  🌱🌱
  const submitForms = () => {
    console.log(formFull.current);
    listShipTypes.length && submitRefCommande.current.submit();
    shipType === refShip[0].name && listMaterials.length && submitRefCommande2.current.submit();
    shipType === refShip[1].name && listMaterials.length && submitRefCommande3.current.submit();
    drivers.length && submitRefLivraison.current.submit();

    if (shipType === refShip[1].name && formFull.current.orderModelDayId) {
      formFull.current = {
        ...formFull.current,
        orderModelDayId: listPricesDay.find((pr) => pr.price === formFull.current.orderModelDayId).id,
      };
    }

    if (Object.keys(formFull.current).length === 9) {
      formFull.current = {
        ...formFull.current,
        alldataclient: listClients.find((el) => el.id === selectClient),
        alldataDriver: drivers.find((el) => el.id === formFull.current.driverOrderId),
      };
      if (
        listClients.find((cl) => cl?.id === selectClient)?.address?.items?.find((ad) => ad?.id === selectAddress)
          ?.price === undefined &&
        !!selectAddress &&
        shipType === refShip[0].name
      ) {
        if (inputPrice) {
          updatePriceAdress({
            id: selectAddress,
            price: inputPrice,
          });
        } else {
          formFull.current = {};
        }
      } else {
        callListOrder();
      }
    } else {
      formFull.current = {};
    }
  };

  const handleChangeFormTournne = (e) => {
    const { id, value } = e.target;
    console.log('id', id);
    console.log('value', value);
    if (arrayTonnage.find((el) => el.id === id)) {
      const index = arrayTonnage.findIndex((el) => el.id === id);
      arrayTonnage[index].value = value;
      setArrayTonnage([...arrayTonnage]);
    } else {
      setArrayTonnage([...arrayTonnage, { id: id, value: value }]);
    }

    // setArrayTonnage([...arrayTonnage, { id: id, value: value }]);
    console.log('arrayTonnage', arrayTonnage);
  };

  //  🌱🌱  effect watch listClients  🌱🌱
  useEffect(() => {
    if (selectClient) {
      handleChangeSelectClient({ target: { value: selectClient } });
    }
  }, [listClients]);

  //  🌱🌱  effect watch showModalAddres reset state  🌱🌱
  useEffect(() => {
    if (showModalAddres) {
      setAdd_adresse('');
      setAdd_price('');
    }
  }, [showModalAddres]);

  //  🌱🌱  effect watch input price  🌱🌱
  useEffect(() => {
    if (inputPrice && arrayTonnage.length >= 0) {
      setTotalPrice(0);
      arrayTonnage.forEach((el) => {
        setTotalPrice((prev) => parseInt(prev) + parseInt(el.value) * parseInt(inputPrice));
      });
      setTotalTonnage(0);
      arrayTonnage.forEach((el) => {
        setTotalTonnage((prev) => parseInt(prev) + parseInt(el.value));
      });
    } else {
      setTotalPrice(0);
    }
  }, [inputPrice, arrayTonnage, shipType, nbTournne]);

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListClients();
    callListMaterials();
    callListDriver();
    callListPricesDay();
  }, []);

  var myloop = [];

  for (let i = 0; i < nbTournne; i++) {
    myloop.push(
      <>
        <div className="flex flex-col md:flex-row md:w-[49%] justify-between">
          <div className="mt-5  md:w-[49%] flex flex-col">
            <label
              className="text-sm font-bold text-blue-admin"
              htmlFor={`idtonnage${i}`}
            >
              {shipType === 'Journée' ? 'Nombre de jour' : 'Tonnage'}
            </label>
            <input
              label="Tonnage"
              type="number"
              placeholder="T"
              id={`idtonnage${i}`}
              min={1}
              className="w-full pl-3 mt-2 border h-14 rounded-xl inputFocus "
              onChange={handleChangeFormTournne}
              defaultValue={1}
            />
          </div>

          <div className="mt-5  md:w-[49%] flex flex-col">
            <label
              className="text-sm font-bold text-blue-admin"
              htmlFor="idprice"
            >
              {shipType === 'Journée' ? 'Prix / Jour' : 'Prix / Tonne'}
            </label>
            <input
              id="idprice"
              className="w-full pl-3 mt-2 border h-14 rounded-xl inputFocus "
              type="number"
              placeholder="€"
              disabled={true}
              value={parseInt(inputPrice)}
            />
          </div>
        </div>
      </>
    );
  }

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full pt-16">
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-7 md:mx-9">
        <h3 className="text-xl font-bold ">Nouvelle commande</h3>
      </div>
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-7 md:mx-9">
        <h3 className="text-xl font-bold ">Infos client</h3>
      </div>
      <div className="max-w-[1100px] mx-4 md:mx-9">
        {!!listClients?.length && (
          <div className="flex flex-col mt-4">
            <label
              htmlFor="idclient"
              className="text-sm font-bold text-blue-admin"
            >
              Nom de la société
            </label>
            <select
              id="idclient"
              className={`h-14 rounded-xl inputFocus w-full md:w-[49%] pl-3 border mt-3 ${
                !msgErrStClient ? '' : 'input_error'
              }`}
              onChange={handleChangeSelectClient}
            >
              <option value="">-</option>
              {listClients.map((client) => (
                <option
                  key={client.id}
                  value={client.id}
                >
                  {client.company}
                </option>
              ))}
            </select>
            <p className="text-red-error">{msgErrStClient ? 'Veuillez sélectionner un client' : ''}</p>
          </div>
        )}
      </div>
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-7 md:mx-9">
        <h3 className="text-xl font-bold ">Infos commande</h3>
      </div>

      {/* 2e formulaire  : Commande*/}
      <div className="max-w-[1100px] mx-4 md:mx-9">
        {!!listShipTypes.length && (
          <FormCustom
            data={FormOrderShip}
            resultSubmit={onSubmit}
            ref={submitRefCommande}
            onChange={handleChangeFormOrderShip}
          />
        )}
        <div className="mt-4">
          <label
            htmlFor="idadd"
            className="text-sm font-bold text-blue-admin"
          >
            Lieu de livraison
          </label>
          <select
            id="idadd"
            className={`h-14 rounded-xl inputFocus w-full pl-3 border mt-3 ${!msgErrStaddres ? '' : 'input_error'}`}
            onChange={handleChangeSelectAddress}
          >
            <option value="">-- selectionner une adresse --</option>
            {listeAddressSelect.map((add) => (
              <option
                key={add?.id}
                value={add?.id}
              >
                {add?.address}
              </option>
            ))}
          </select>
          <p className="text-red-error">{msgErrStaddres ? 'Veuillez sélectionner une adresse' : ''}</p>
          {!!selectClient && (
            <SecondaryButton
              title="Ajouter une adresse"
              style="w-full md:w-1/3 mt-2"
              onClick={() => setShowModalAddres(true)}
            />
          )}
          {listClients.find((cl) => cl?.id === selectClient)?.address?.items?.find((ad) => ad?.id === selectAddress)
            ?.price === undefined &&
            !!selectAddress &&
            shipType === refShip[0].name && (
              <SoloInputForm
                label="Ajouter un prix"
                type="number"
                placeholder="Ajouter tarification € / Tonne"
                styleContainer={'md:w-[49%] mt-4'}
                styleInput={'w-full mt-2'}
                onChangeText={setInputPrice}
                isError={!inputPrice}
                msgError="Ajouter une tarification à cette nouvelle adresse"
              />
            )}
        </div>
        {shipType === refShip[0].name && !!listMaterials.length && (
          <FormCustom
            data={FormOrderMaterial}
            resultSubmit={onSubmit}
            ref={submitRefCommande2}
          />
        )}
        {shipType === refShip[1].name && !!listPricesDay.length && (
          <FormCustom
            data={FormShipDay}
            resultSubmit={onSubmit}
            ref={submitRefCommande3}
            onChange={handleChangeFormShipDay}
          />
        )}
        <div className="flex flex-col md:flex-row md:w-[49%] justify-between">
          {shipType === refShip[0].name && (
            <SoloInputForm
              label="Nombre de tournées"
              type="number"
              placeholder="Nombre de tournées"
              styleContainer={'md:w-[49%] mt-4'}
              styleInput={'w-full mt-2'}
              onChangeText={setNbTournne}
              isError={false}
              defaultValue={1}
            />
          )}
        </div>
        {myloop}
        <div className="flex flex-col md:flex-row md:w-[49%] justify-between">
          <div className="flex flex-col w-full pl-3 mt-4">
            <label
              htmlFor="idttprice"
              className="text-sm font-bold text-blue-admin"
            >
              Prix total
            </label>
            <input
              id="idttprice"
              className="p-2 mt-4 rounded-lg"
              type="text"
              disabled
              value={`${totalPrice} €`}
            />
          </div>
          <div className="flex flex-col w-full pl-3 mt-4">
            <label
              htmlFor="idTotalTonnage"
              className="text-sm font-bold text-blue-admin"
            >
              {shipType === 'Journée' ? 'Total Jour' : 'Total Tonnage'}
            </label>
            <input
              id="idTotalTonnage"
              className="p-2 mt-4 rounded-lg"
              type="text"
              disabled
              value={`${totalTonnage} T`}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-7 md:mx-9">
        <h3 className="text-xl font-bold ">Infos livraison</h3>
      </div>

      {/* 3e formulaire: Livraison*/}
      <div className="max-w-[1100px] pb-5 grow px-4 md:px-9">
        {!!drivers.length && (
          <FormCustom
            data={FormShip}
            resultSubmit={onSubmit}
            ref={submitRefLivraison}
          />
        )}

        <div className="w-full md:w-[49%] mt-4">
          <label className="text-sm font-bold text-blue-admin">Date de livraison souhaité</label>
          <DatePicker_date
            selected={datePlanned}
            minDate={new Date()}
            onChange={setdatePlanned}
            styleInput="inputFocus w-full h-14 rounded-xl inputFocus mt-3 mb-4 md:mb-0 pl-3 border"
          />
        </div>
      </div>
      {/* Div 2 boutons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <PrimaryButton
          title="Créer une nouvelle commande"
          style="bg-blue-admin"
          onClick={submitForms}
        />
      </div>
      {/* Modal Add Order */}
      <Modal
        title="Nouvelle commande enregistré"
        text={`Nous avons bien enregistré votre nouvel commande N°${formFull?.current?.numero}`}
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
      <Modal
        title="Ajouter une adresse"
        text={`Ajouter une adresse à ${listClients?.find((el) => el.id === selectClient)?.firstName} ${
          listClients?.find((el) => el.id === selectClient)?.lastName
        } et un prix à la tonne.`}
        showModal={showModalAddres}
        image={PlusIcon}
      >
        {showModalAddres && (
          <>
            <PrimaryInput
              placeholder="123 rue du Loisir 97232 Lamentin"
              styleInput="my-2"
              onChangeText={setAdd_adresse}
            />
            <PrimaryInput
              placeholder="Prix / T"
              type="number"
              styleInput="mb-4"
              onChangeText={setAdd_price}
            />
          </>
        )}
        <PrimaryButton
          title="Ajouter"
          style="w-full"
          onClick={createProfileAddress}
          disabled={!add_adresse || !add_price}
        />
        <SecondaryButton
          title="retour"
          style="w-full mt-2"
          onClick={() => {
            setShowModalAddres(false);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddOrder;

