/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { twMerge } from 'tailwind-merge';
//components custom
import PrimaryInput from './PrimaryInput';

//Exemple Regex:
// - Password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/
// - Phone: /^[0-9]{10}$|([+])([0-9]{11,12})$/
// - Siret: /^[0-9]{14}$/
// - Email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
// - Adresse française: /^\d+\D+\d{5}\D+\w+$/

const FormCustom = forwardRef(({ data, resultSubmit, containerStyle, onChange }, ref) => {
  useImperativeHandle(ref, () => ({
    submit() {
      const form = myForm.current;
      const formData = new FormData(form);
      const values = Object.fromEntries(formData);
      verif(values);
    },
  }));
  //init ref
  const myForm = useRef(null);

  //init state
  const [valueErreur, setValueErreur] = useState([]);

  //effect
  useEffect(() => {
    const prime = data.map(() => true);
    setValueErreur(prime);
  }, []);

  //verification form
  const verif = (dataForm) => {
    let result = true;
    const tabKey = Object.keys(dataForm);
    const prime = [...valueErreur];

    tabKey.forEach((key, index) => {
      let instantResult = true;
      if (data[index].required === true) {
        if (data[index].regex && !dataForm[key].match(data[index].regex)) {
          result = false;
          instantResult = false;
        }
        if (!dataForm[key]) {
          result = false;
          instantResult = false;
        }
      } else {
        if (dataForm[key] && data[index].regex && !dataForm[key].match(data[index].regex)) {
          result = false;
          instantResult = false;
        }
      }

      if (instantResult === false) {
        prime[index] = false;
      } else {
        prime[index] = true;
      }
    });
    setValueErreur(prime);
    // submit(values)
    resultSubmit({ status: result, values: result && dataForm });
  };

  //component form
  const formInput = (
    <form
      className="flex flex-wrap justify-between"
      ref={myForm}
    >
      {data.map((form, index) =>
        form.inputType === 'select' ? (
          <div
            key={index}
            className={twMerge(
              `mt-5 w-full flex flex-col   ${form.halfWidth === 'full' ? 'md:w-full' : ''} ${
                form.halfWidth === '1/2' || form.halfWidth === true ? 'md:w-[49%]' : ''
              } ${form.halfWidth === '1/2-full' ? 'md:w-full' : ''} ${containerStyle}`
            )}
          >
            <label
              htmlFor={form.valueName}
              className="text-sm font-bold text-blue-admin"
            >
              {form.label}
            </label>
            <select
              name={form.valueName}
              className={twMerge(
                `h-14 text-blue-admin mt-3 font-medium rounded-xl inputFocus w-full pl-3 border bg-white-admin ${
                  form.halfWidth === 'full' ? 'md:w-full' : ''
                } ${form.halfWidth === '1/2-full' ? 'md:w-[49%]' : ''} ${form.style} ${
                  valueErreur[index] ? '' : 'input_error'
                }`
              )}
              id={form.valueName}
              defaultValue={form.defaultValue ? form.defaultValue : ''}
              onInput={(e) => {
                if (form.onChange) {
                  const myValue = { [form.valueName]: e.target.value };
                  onChange(myValue);
                }
              }}
            >
              <option value="">{form.placeholder ? form.placeholder : '-'}</option>
              {form.options.map((ele, ix) => (
                <option
                  key={ix}
                  value={ele[form.keyValues]}
                >
                  {ele[form.keyOptions]}
                </option>
              ))}
            </select>
            <p className="text-red-error">{valueErreur[index] ? '' : form.errorMsg}</p>
          </div>
        ) : (
          <div
            key={index}
            className={twMerge(
              `mt-5 w-full flex flex-col  ${form.halfWidth === 'full' ? 'md:w-full' : ''} ${
                form.halfWidth === '1/2' || form.halfWidth === true ? 'md:w-[49%]' : ''
              } ${form.halfWidth === '1/2-full' ? 'md:w-full' : ''} ${containerStyle}`
            )}
          >
            <label
              className="text-sm font-bold text-blue-admin"
              htmlFor={form.valueName}
            >
              {form.label}
            </label>
            <PrimaryInput
              type={form.inputType}
              name={form.valueName}
              id={form.valueName}
              placeholder={form.placeholder}
              disabled={form.disabled}
              defaultValue={form.defaultValue}
              onChangeText={(e) => {
                if (form.onChange) {
                  onChange({ [form.valueName]: e });
                }
              }}
              styleInput={twMerge(
                `mt-3 ${form.style}  ${form.halfWidth === 'full' ? 'md:w-full' : ''} ${
                  form.halfWidth === '1/2-full' ? 'md:w-1/2' : ''
                } ${valueErreur[index] ? '' : 'input_error'}`
              )}
            />
            <p className="text-red-error">{valueErreur[index] ? '' : form.errorMsg}</p>
          </div>
        )
      )}
    </form>
  );

  //Render
  return formInput;
});

export default FormCustom;
