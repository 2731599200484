import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Table from '../../Table';
import Modal from '../../Modal';
import { dateFormat } from '../../../tools/date';

// Import icons
import ReceiveSquare from '../../../../assets/images/svg/receive-square.svg';
import DeleteIcon from '../../../../assets/images/svg/delete.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

//env
const urlS3 = process.env.REACT_APP_URL_S3_OBJECT;

const ListRevision = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getListRevision = useStoreActions((actions) => actions.middleware.RevisionMiddleware.getListRevision);
  const deleteRevision = useStoreActions((actions) => actions.middleware.RevisionMiddleware.deleteRevision);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listRevision, setListRevision] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [ctSelected, setCtSelected] = useState({});

  //  🌱🌱 Request call List Revision  🌱🌱
  const callListRevision = async () => {
    try {
      const res = await getListRevision(idTruck);
      setListRevision(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des Revision');
    }
  };

  //  🌱🌱 Request delete revision  🌱🌱
  const deleteProfileRevision = async () => {
    try {
      const res = await deleteRevision(ctSelected?.id);
      callListRevision();
      setShowModalDelete(false);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la Revision');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListRevision();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-16 flex flex-col h-5/6 justify-between">
      {/* div table revision*/}
      <div className="px-4 md:px-9">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Réalisé par</th>

              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listRevision.length ? (
              listRevision.map((revision) => {
                const dateNow = dateFormat(revision?.date, '-').full;
                return (
                  <tr key={revision?.id}>
                    <td>{dateNow}</td>
                    <td>{revision?.garage}</td>
                    <td>
                      <div className="flex justify-end gap-x-2">
                        <button
                          className=" border border-separate p-3 rounded-xl"
                          onClick={() => {
                            setCtSelected(revision);
                            setShowModalDelete(true);
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt="more icon"
                          />
                        </button>
                        {revision?.document && (
                          <a
                            href={`${urlS3}${revision?.document}`}
                            className="border border-separate p-3 rounded-xl"
                          >
                            <img
                              src={ReceiveSquare}
                              alt="more icon"
                            />
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center text-lg"
                >
                  Ajouter une Révision
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className=" gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
      <Modal
        title="Supprimer cette révision"
        text="Êtes-vous sûre de vouloir supprimer cette révision ? 
        Cette action est définitive. Vous n’aurez plus accès à cette révision."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileRevision();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default ListRevision;

