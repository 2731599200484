import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Modal from '../../Modal';

//import icons
import Check from '../../../../assets/images/svg/check.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

const EditPricesDay = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idPricesDay } = useParams();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 init store(services)   🌱🌱
  const getPricesDay = useStoreActions((actions) => actions.middleware.PricesDayMiddleware.getPricesDay);
  const updatePricesDay = useStoreActions((actions) => actions.middleware.PricesDayMiddleware.updatePricesDay);
  const deletePricesDay = useStoreActions((actions) => actions.middleware.PricesDayMiddleware.deletePricesDay);

  //  🌱🌱  Etats (données)  🌱🌱
  const [typePricesDay, setTypePricesDay] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  formulaire PricesDay  🌱🌱
  const formulairPricesDay = [
    {
      label: 'Type',
      inputType: 'text',
      valueName: 'name',
      placeholder: 'Nom du type',
      errorMsg: 'Entrer un type de prix à la journée',
      required: true,
      halfWidth: true,
      defaultValue: typePricesDay?.name,
    },
    {
      label: 'Prix',
      inputType: 'number',
      valueName: 'price',
      placeholder: '€',
      errorMsg: 'Entrer un prix',
      required: true,
      halfWidth: true,
      defaultValue: typePricesDay?.price,
    },
  ];

  //  🌱🌱 Request call  PricesDay  🌱🌱
  const callPricesDay = async () => {
    try {
      const res = await getPricesDay(idPricesDay);
      setTypePricesDay(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement du type');
    }
  };

  //  🌱🌱 Request update  PricesDay  🌱🌱
  const updateProfilePricesDay = async (data) => {
    try {
      const res = await updatePricesDay(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors des changements du type');
    }
  };

  //  🌱🌱 Request delete  PricesDay  🌱🌱
  const deleteProfilePricesDay = async () => {
    try {
      const res = await deletePricesDay(idPricesDay);
      setShowModalDelete(false);
      navigate(-1);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression du type');
    }
  };

  // 🌱🌱 submit Form 🌱🌱
  const submit = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idPricesDay,
      };
      updateProfilePricesDay(newData);
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callPricesDay();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-16 h-full flex flex-col overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-9">
        <h3 className="font-bold text-xl ">Modifier un matériel</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {typePricesDay?.id && (
          <FormCustom
            data={formulairPricesDay}
            resultSubmit={submit}
            ref={submitRef}
          />
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer ce type"
            onClick={() => setShowModalDelete(true)}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      <Modal
        title="Modification du type enregistrée"
        text="Nous avons bien enregistré les modification du type. Vous la retrouverez dans la liste des types."
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>
      <Modal
        title="Supprimer un type"
        text="Êtes-vous sûre de vouloir supprimer ce type  ? 
        Cette action est définitive. Vous n’aurez plus accès."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfilePricesDay();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditPricesDay;

