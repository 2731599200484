import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const DriverMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST DRIVER
  //✨✨==========================================✨✨
  getListDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listDrivers(filter: {archived: {eq: false}}) {
        items {
          id
          firstName
          lastName
          phone
          datePermis
          dateVm
          tokenFcm
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listDrivers.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET DRIVER
  //✨✨==========================================✨✨
  getDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getDriver(id: "${payload}") {
        id
        firstName
        lastName
        phone
        docPermis
        datePermis
        docVm
        dateVm
        id_cognito
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getDriver;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE DRIVER
  //✨✨==========================================✨✨
  createDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createDriver(input: {
        firstName: "${payload?.firstName}",
        lastName: "${payload?.lastName}",
        phone: "${payload?.phone}",
        docPermis: "${payload?.docPermis}",
        datePermis: "${payload?.datePermis}",
        docVm: "${payload?.docVm}",
        dateVm: "${payload?.dateVm}",
        id_cognito: "${payload?.id_cognito}",
        archived: false
        }) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createDriver;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE DRIVER
  //✨✨==========================================✨✨
  updateDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateDriver(input: {
        id: "${payload?.id}",
        firstName:"${payload?.firstName}", 
        lastName: "${payload?.lastName}", 
        ${payload?.docPermis ? `docPermis: "${payload?.docPermis}",` : ``}
        ${payload?.docPermis ? `datePermis: "${payload?.datePermis}",` : ``}
        ${payload?.docVm ? `docVm: "${payload?.docVm}",` : ``}
        ${payload?.docVm ? `dateVm: "${payload?.dateVm}",` : ``}
        phone:"${payload?.phone}",
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateDriver;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE DRIVER (UPDATE ARCHIVED)
  //✨✨==========================================✨✨
  deleteDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateDriver(input: {
        id: "${payload}",
        archived: true
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateDriver;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default DriverMiddleware;

