import React from 'react';
import './css/HoverStatuts.css';

const HoverStatuts = ({ text }) => {
  return (
    <>
      <div className="hoverText">{text}</div>
      <div className="boxHover">
        <div className="boxInfos">
          <ul>
            <li>
              <div>
                <span style={{ backgroundColor: '#5BB264' }} /> Commande livrée
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#5354E5' }} /> Commande assigné
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#F9B653' }} />
                Commande en attente de gestion
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#ED4337' }} /> Commande non livrée
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HoverStatuts;

