import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import { typeFile } from '../../../tools/file';
import Modal from '../../Modal';

//Import icons
import Check from '../../../../assets/images/svg/check.svg';
import Warning from '../../../../assets/images/svg/warning.svg';
import FileUpload from '../../../../assets/images/svg/file-upload.svg';
import Download from '../../../../assets/images/svg/download.svg';

const VehicleFile = () => {
  //  🌱🌱 Init store(services)  🌱🌱
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);
  const getTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.getTruck);
  const updateTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.updateTruck);
  const deleteTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.deleteTruck);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);

  //  🌱🌱  Etats (données)  🌱🌱
  const [drivers, setDrivers] = useState([]);
  const [truck, setTruck] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedFileCarte, setSelectedFileCarte] = useState(null);
  const [msgErrorDocCarte, setMsgErrorDocCarte] = useState(false);

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱  Init Params  🌱🌱
  const { idTruck } = useParams();

  //  🌱🌱 Init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`${Date.now()}`).current;

  const formEditTruck = [
    {
      label: 'Modèle du véhicule',
      inputType: 'text',
      valueName: 'model',
      placeholder: 'Modèle du véhicule',
      errorMsg: 'Veuillez rentrer le modèle de votre véhicule',
      required: true,
      halfWidth: true,
      defaultValue: truck?.model,
    },
    {
      label: 'Année',
      inputType: 'number',
      valueName: 'year',
      placeholder: 'Année',
      errorMsg: "Veuillez rentrer l'année de votre véhicule",
      required: true,
      halfWidth: true,
      defaultValue: truck?.year,
    },
    {
      label: 'Immatriculation',
      inputType: 'text',
      valueName: 'registration',
      placeholder: 'Immatriculation',
      errorMsg: "Veuillez entrer une plaque d'immatriculation valide",
      regex: /^[A-Z]{2}-[0-9]{3}-[A-Z]{2}$/,
      required: true,
      halfWidth: true,
      defaultValue: truck?.registration,
    },
    {
      label: 'Attribution',
      inputType: 'select',
      valueName: 'truckDriverId',
      options: drivers,
      keyOptions: 'fullName',
      keyValues: 'id',
      defaultValue: truck?.truckDriverId,
      halfWidth: true,
      required: true,
      style: 'max-w-[194px] flex',
    },
  ];
  //  🌱🌱 Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      setDrivers(
        res.map((truck) => {
          return { ...truck, fullName: `${truck.firstName} ${truck.lastName}` };
        })
      );
      callTruck();
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱 Request call Get Truck  🌱🌱
  const callTruck = async () => {
    try {
      const res = await getTruck(idTruck);
      setTruck(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement du camion');
    }
  };

  //  🌱🌱 Request Update Truck  🌱🌱
  const updateProfileTruck = async (data) => {
    try {
      const res = await updateTruck(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la modification du camion');
    }
  };

  //  🌱🌱 Request delete Truck  🌱🌱
  const deleteProfileTruck = async () => {
    try {
      const res = await deleteTruck(idTruck);
      navigate(-1);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression du camion');
    }
  };

  //  🌱🌱 Function  handleFileInput vm  🌱🌱
  const handleFileCarteInput = (e) => {
    setSelectedFileCarte(e.target.files[0]);
    setMsgErrorDocCarte(false);
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListDriver();
  }, []);

  //  🌱🌱  Function onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idTruck,
        document: selectedFileCarte ? `grise-${fileNameRef}.${typeFile(selectedFileCarte.name)}` : null,
      };
      if (selectedFileCarte) {
        try {
          await uploadFile({
            file: selectedFileCarte,
            nameFile: `grise-${fileNameRef}.${typeFile(selectedFileCarte.name)}`,
            contentType: selectedFileCarte.type,
          });
          updateProfileTruck(newData);
        } catch (error) {
          toast.error("❗️Une erreur est survenue durant la création d'un nouveau camion, veuillez réessayer.");
        }
      } else {
        updateProfileTruck(newData);
      }
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-16  flex flex-col h-5/6 justify-between">
      {/* div form custom */}
      <div className="px-4 md:px-9 max-w-[1100px] ">
        {truck?.id && (
          <>
            <FormCustom
              data={formEditTruck}
              resultSubmit={onSubmit}
              ref={submitRef}
            />
            <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
              <label>Carte grise</label>
              <label
                htmlFor="dropzone-file-permis"
                className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
              >
                <img
                  className="w-1/6"
                  src={selectedFileCarte ? FileUpload : Download}
                  alt="icon"
                />
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <p className="mb-2 text-sm text-gray-file">
                    <span className="font-semibold">
                      {selectedFileCarte ? selectedFileCarte.name : ' Cliquez pour ajouter une carte grise'}
                    </span>
                  </p>
                  <p className="text-xs text-gray-file font-normal">
                    {selectedFileCarte ? '' : ' PNG, JPG, JPEG, PDF'}
                  </p>
                </div>
                <input
                  id="dropzone-file-permis"
                  type="file"
                  className="hidden"
                  onChange={handleFileCarteInput}
                  accept=".jpeg,.jpg,.png,.pdf"
                />
              </label>
              <p className="text-red-error">{msgErrorDocCarte ? 'Veuillez ajouter une carte grise' : ''}</p>
            </div>
          </>
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5 md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer ce véhicule"
            onClick={() => setShowModalDelete(true)}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur le camion ${truck?.registration}`}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>
      {/* Modal delete */}
      <Modal
        title="Supprimer un véhicule"
        text="Êtes-vous sûre de vouloir supprimer ce camion ? 
        Cette action est définitive. Vous n’aurez plus accès à ce véhicule."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileTruck();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default VehicleFile;

