import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

//react router dom
import { BrowserRouter } from 'react-router-dom';

//easy peasy
import { StoreProvider } from 'easy-peasy';

//services (store)
import services from './services';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <StoreProvider store={services}>
      <App />
    </StoreProvider>
  </BrowserRouter>
);
