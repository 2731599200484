import { createStore } from 'easy-peasy';

//import services
import middleware from './middleware';
import userData from './userData';
import global from './global';
import route from './route';

const services = createStore({
  middleware,
  userData,
  global,
  route,
});

export default services;
