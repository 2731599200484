import React from 'react';
import DashboardHome from '../shared/components/DashBoard/DashboardHome';

const Dashboard = () => {
  return (
    <div>
      <DashboardHome />
    </div>
  );
};

export default Dashboard;

