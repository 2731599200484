import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import Modal from '../../../components/Modal';

//Import icons
import Check from '../../../../assets/images/svg/check.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

const EditAddress = () => {
  //  🌱🌱  Etats (données)  🌱🌱
  const [myAddress, setMyAddress] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  Data Form Edit  🌱🌱
  const formAddAdress = [
    {
      label: 'Adresse de livraison',
      inputType: 'text',
      valueName: 'address',
      placeholder: '10 rue de la livraison 97200 fdf',
      errorMsg: 'Veuillez entrer une adresse',
      required: true,
      defaultValue: myAddress?.address,
    },
    {
      label: 'Prix / Tonne',
      inputType: 'number',
      valueName: 'price',
      placeholder: '€',
      errorMsg: 'Veuillez entrer un prix',
      required: true,
      halfWidth: true,
      defaultValue: myAddress?.price,
    },
  ];

  //  🌱🌱 init store(services)  🌱🌱
  const getAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.getAddress);
  const updateAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.updateAddress);
  const deleteAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.deleteAddress);

  //  🌱🌱  Init Navigation
  const navigate = useNavigate();
  const { idAddress } = useParams();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 Request call get address  🌱🌱
  const callGetAddress = async () => {
    try {
      const res = await getAddress(idAddress);
      setMyAddress(res);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue lors du chargement de l'adresse");
    }
  };

  //  🌱🌱  function update Profile Address   🌱🌱
  const updateProfileAddress = async (data) => {
    try {
      const res = await updateAddress(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la modification de l'adresse, veuillez réessayer.");
    }
  };

  //  🌱🌱  function Delete Profile Address   🌱🌱
  const deleteProfileAddress = async () => {
    setShowModalDelete(false);
    try {
      const res = await deleteAddress(myAddress.id);
      navigate(-1);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la suppression de l'adresse, veuillez réessayer.");
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetAddress();
  }, []);

  //  🌱🌱  function FormCustom   🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idAddress,
      };
      updateProfileAddress(newData);
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Modifier une adresse</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {myAddress.id && (
          <FormCustom
            data={formAddAdress}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer cette adresse"
            onClick={() => {
              setShowModalDelete(true);
            }}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur l'adresse`}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>

      {/* Modal delete */}
      <Modal
        title="Supprimer une adresse"
        text="Êtes-vous sûre de vouloir supprimer cette adresse ? 
        Cette action est définitive."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileAddress();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditAddress;

