import AdminMiddleware from './AdminMiddleware';
import ClientsMiddleware from './ClientsMiddleware';
import DriverMiddleware from './DriverMiddleware';
import TruckMiddleware from './TruckMiddleware';
import MaterialsMiddleware from './MaterialsMiddleware';
import ControlTechniqueMiddleware from './ControlTechniqueMiddleware';
import FuelMiddleware from './FuelMiddleware';
import OrderMiddleware from './OrderMiddleware';
import RevisionMiddleware from './RevisionMiddleware';
import FollowMiddleware from './FollowMiddleware';
import AddressMiddleware from './AddressMiddleware';
import PricesDayMiddleware from './PricesDayMiddleware';
import MonitoringMiddleware from './MonitoringMiddleware';
import ProblemsMiddleware from './ProblemsMiddleware'
import Cognito from './Cognito';
import FileS3 from './FileS3';
import Notification from './Notification';

const middleware = {
  AdminMiddleware,
  ClientsMiddleware,
  DriverMiddleware,
  TruckMiddleware,
  MaterialsMiddleware,
  ControlTechniqueMiddleware,
  FuelMiddleware,
  OrderMiddleware,
  RevisionMiddleware,
  FollowMiddleware,
  AddressMiddleware,
  PricesDayMiddleware,
  MonitoringMiddleware,
  ProblemsMiddleware,
  Cognito,
  FileS3,
  Notification,
};
export default middleware;

