import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

// Import Components
import PrimaryInput from './PrimaryInput';

const SoloInputForm = ({
  placeholder = 'Solo Input form',
  styleInput,
  styleContainer,
  styleLabel,
  type,
  myRef,
  onChangeText,
  disabled,
  name,
  id,
  defaultValue,
  isError = true,
  msgError = 'message erreur',
  label = 'label',
}) => {
  //  🌱🌱  Etats (données)  🌱🌱
  const [err, setErr] = useState(isError);

  //init effect
  useEffect(() => {
    setErr(isError);
  }, [isError]);

  //  🎉🎉  Render  🎉🎉
  return (
    <div className={twMerge(`flex flex-col ${styleContainer}`)}>
      <label className={twMerge(`text-sm font-bold text-blue-admin mb-1 ${styleLabel}`)}>{label}</label>
      <PrimaryInput
        placeholder={placeholder}
        styleInput={twMerge(`${styleInput} ${err ? 'input_error' : ''}`)}
        type={type}
        myRef={myRef}
        onChangeText={onChangeText}
        disabled={disabled}
        name={name}
        id={id}
        defaultValue={defaultValue}
      />
      <p className="text-red-error">{err ? msgError : ''}</p>
    </div>
  );
};

export default SoloInputForm;

