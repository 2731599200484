export const awsAuthAdmin = {
  region: 'eu-west-3',
  userPoolId: process.env.REACT_APP_POOL_ID_ADMIN,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_ADMIN,
};

export const awsAuthClient = {
  region: 'eu-west-3',
  userPoolId: process.env.REACT_APP_POOL_ID_CLIENT,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_CLIENT,
};

export const awsAuthDriver = {
  region: 'eu-west-3',
  userPoolId: process.env.REACT_APP_POOL_ID_DRIVER,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_DRIVER,
};
