import React, { useRef, useState } from 'react';
import { useStoreActions } from 'easy-peasy';

//components custom
import PrimaryButton from '../shared/components/PrimaryButton';
import Activity from '../shared/components/Activity';
import FormCustom from '../shared/components/FormCustom';

// import logo
import LogoTransport from '../assets/images/svg/logo_transport.svg';

const formLogin = [
  {
    label: '',
    inputType: 'mail',
    valueName: 'mail',
    placeholder: 'E-mail',
    errorMsg: 'Veuillez entrer un e-mail valide.',
    required: true,
    style: 'rounded-3xl',
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    onChange: true,
  },
  {
    label: '',
    inputType: 'password',
    valueName: 'password',
    placeholder: 'Mot de passe',
    errorMsg: 'Veuillez entrer un mot de passe de plus de  7 caractères valide.',
    required: true,
    style: 'rounded-3xl',
    regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/,
    onChange: true,
  },
];

const Login = () => {
  //  🌱🌱  initRef  🌱🌱
  const submitRef = useRef(null);

  //  🌱🌱  init service  🌱🌱
  const setConnected = useStoreActions((action) => action.route.setConnected);
  const loginAws = useStoreActions((action) => action.middleware.Cognito.loginAws);
  const getAdminCognito = useStoreActions((actions) => actions.middleware.AdminMiddleware.getAdminCognito);

  //  🌱🌱  state  🌱🌱
  const [MsgError, setMsgError] = useState(false);

  //  🌱🌱 Request create Admins  🌱🌱
  const LoginProfileCognito = async (data) => {
    try {
      const res = await loginAws(data);
      getAdminCognito(res?.username);
      setConnected(true);
    } catch (error) {
      setMsgError(true);
    }
  };

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      LoginProfileCognito(value.values);
    }
  };

  //  🌱🌱  Manage input error  🌱🌱
  const HandleInputForm = () => {
    setMsgError(false);
  };

  //Render
  return (
    <>
      <section className=" h-screen md:p-7 ">
        {/* div logo */}

        {/* div main title + form */}
        <div className=" flex flex-col justify-center rounded-3xl bg-gray-sidebar h-full items-center w-full  mx-auto text-center px-4 py-5">
          <div className="max-w-[500px]">
            <div className="flex items-center justify-center mb-6 md:mb-16">
              <img
                src={LogoTransport}
                alt="logo transport"
              />
            </div>
            <h1 className="font-bold text-4xl pb-5 ">Bienvenue sur l’admin Transport Ozier & Fils</h1>
            <h3 className="text-xl font-medium">Veuillez renseigner vos identifiants</h3>

            <section className="w-full pt-20  md:px-0">
              <div className="flex flex-col space-y-4">
                <FormCustom
                  data={formLogin}
                  resultSubmit={onSubmit}
                  ref={submitRef}
                  onChange={HandleInputForm}
                />
              </div>
              <p className="text-red-error mt-2">{MsgError && 'E-mail ou mot de passe incorrect'}</p>
              <PrimaryButton
                title="Se connecter"
                onClick={() => {
                  submitRef.current.submit();
                }}
                style={'w-full mt-14 py-4 font-semibold'}
              />
            </section>
          </div>
        </div>
      </section>
      <Activity />
    </>
  );
};

export default Login;
