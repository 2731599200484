import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../SecondaryButton';
import Table from '../../Table';
import { dateFormat } from '../../../tools/date';

const Refueling = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getListFuels = useStoreActions((actions) => actions.middleware.FuelMiddleware.getListFuels);

  //  🌱🌱  Etats (données)  🌱🌱
  const [fuels, setFuels] = useState([]);

  //  🌱🌱 Request call List fuels  🌱🌱
  const callListFuels = async () => {
    try {
      const res = await getListFuels(idTruck);
      setFuels(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste du ravitaillement');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListFuels();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-16 flex flex-col  h-5/6 justify-between">
      <div className="px-4 md:px-9">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Littrage ravitaillement</th>
              <th>Kilomètre de départ</th>
              <th>Kilomètre d’arrivée</th>
            </tr>
          </thead>
          <tbody>
            {fuels.length ? (
              fuels.map((fuel) => {
                const mydate = dateFormat(fuel?.date, '-').full;
                return (
                  <tr key={fuel.id}>
                    <td>{mydate}</td>
                    <td>{fuel.quantity} L</td>
                    <td>{fuel.startKm} Km</td>
                    <td>{fuel.endKm} Km</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="text-center text-lg"
                >
                  La liste du ravitaillement est vide
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className=" gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
    </section>
  );
};

export default Refueling;

