import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import Modal from '../../../components/Modal';

//import icons
import Check from '../../../../assets/images/svg/check.svg';

const formAddAdress = [
  {
    label: 'Adresse de livraison',
    inputType: 'text',
    valueName: 'address',
    placeholder: '10 rue de la livraison 97200 fdf',
    errorMsg: 'Veuillez entrer une adresse',
    required: true,
  },
  {
    label: 'Prix / Tonne',
    inputType: 'number',
    valueName: 'price',
    placeholder: '€',
    errorMsg: 'Veuillez entrer un prix',
    required: true,
    halfWidth: true,
  },
];

const AddAddress = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const createAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.createAddress);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);

  // 🌱🌱 Init Navigation 🌱🌱
  const navigate = useNavigate();
  const { idClient } = useParams();

  //init use Ref
  const submitRef = useRef();

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        idClient: idClient,
      };
      createProfileAddress(newData);
    }
  };

  //  🌱🌱 Request create Adresse  🌱🌱
  const createProfileAddress = async (data) => {
    try {
      const res = await createAddress(data);
      setShowModal(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant l'ajout de l'adresse, veuillez réessayer.");
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9 ">
        <h3 className="font-bold text-xl ">Ajouter une adresse</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formAddAdress}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer une adresse"
          style="bg-blue-admin"
          onClick={() => {
            submitRef.current.submit();
          }}
        />
      </div>

      {/* Modal Add Admin */}
      <Modal
        title="Nouvel adresse enregistré"
        text="Nous avons bien enregistré votre nouvelle adresse."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddAddress;

