import { action, thunk } from 'easy-peasy';

const userData = {
  cognitoData: {},
  setCognitoData: action((state, payload) => {
    state.cognitoData = payload;
  }),

  userAdmin: { firstName: '' },
  setUserAdmin: action((state, payload) => {
    state.userAdmin = payload;
  }),
};

export default userData;
