import React from 'react';
import { NavLink } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

// import logo
import LogoTransport from '../../assets/images/svg/logo_transport.svg';

// import icones
import Box from '../../assets/images/svg/box.svg';
import Buildings from '../../assets/images/svg/buildings.svg';
import Calendar from '../../assets/images/svg/calendar.svg';
import PersonalCard from '../../assets/images/svg/personalcard.svg';
import Setting from '../../assets/images/svg/setting.svg';
import Truck from '../../assets/images/svg/truck.svg';
import ProfileUser from '../../assets/images/svg/profile-user.svg';
import Elements from '../../assets/images/svg/element-3.svg';
import RedWarning from '../../assets/images/svg/warning_red.svg';
import Blocked from '../../assets/images/svg/blocked.svg'

const SideBar = () => {
  //init service
  const logOutAdminUser = useStoreActions((action) => action.middleware.Cognito.logOutAdminUser);
  const warnningOrder = useStoreState((state) => state.global.warnningOrder);
  const warnningNewClient = useStoreState((state) => state.global.warnningNewClient);

  //Render
  return (
    <div className="flex flex-col justify-between w-full h-full overflow-hidden ">
      <div className="flex flex-col h-full pt-10 overflow-hidden rounded-3xl bg-gray-sidebar md:my-5 md:ml-5">
        <div className="flex justify-center">
          <img
            src={LogoTransport}
            alt="logo"
            width={112}
          />
        </div>
        <div className="mt-20 overflow-hidden grow">
          <div className="flex flex-col h-full pb-10 pl-5 space-y-8 overflow-auto">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Elements}
                alt="icone elements-3"
              />
              <p> Tableau de bord </p>
            </NavLink>

            <NavLink
              to="/administrators"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={ProfileUser}
                alt="icone ProfileUser"
              />
              <p>Administrateurs</p>
            </NavLink>

            <NavLink
              to="/drivers"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                className="stroke-current"
                src={PersonalCard}
                alt="icone elements-3"
              />
              <p>Chauffeurs</p>
            </NavLink>

            <NavLink
              to="/trucks"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Truck}
                alt="icone Truck"
              />
              <p>Camions</p>
            </NavLink>

            <NavLink
              to="/clients"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Buildings}
                alt="icone Buildings"
              />
              <p> Clients </p>
              {warnningNewClient && (
                <img
                  src={RedWarning}
                  alt="icone Box"
                />
              )}
            </NavLink>

            <NavLink
              to="/orders"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Box}
                alt="icone Box"
              />
              <p> Commandes </p>
              {warnningOrder && (
                <img
                  src={RedWarning}
                  alt="icone Box"
                />
              )}
            </NavLink>

            <NavLink
              to="/planning"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Calendar}
                alt="icone Calendar"
              />
              <p> Planning</p>
            </NavLink>

            <NavLink
              to="/problems"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Blocked}
                alt="icone problems"
                width={15}
              />
              <p> Anomalie</p>
            </NavLink>

            <NavLink
              to="/settings"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Setting}
                alt="icone Setting"
              />
              <p> Paramètres</p>
            </NavLink>

            <button
              className="sideMenu btnLogOut"
              onClick={() => logOutAdminUser()}
            >
              Déconnexion
            </button>
          </div>
        </div>
      </div>

      <div className="imgBottomSidebar"></div>
    </div>
  );
};

export default SideBar;
