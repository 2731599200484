import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const ClientsMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST CLIENTS
  //✨✨==========================================✨✨

  getListClients: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listClients(filter: {archived: {eq: false}}) {
        items {
          id
          firstName
          lastName
          company
          phone
          mail
          siret
          tokenFcm
          confirmed
          address {
            items {
              id
              address
              price
              archived
            }
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listClients.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET CLIENTS
  //✨✨==========================================✨✨

  getClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getClient(id: "${payload}") {
        id
        firstName
        lastName
        company
        phone
        mail
        siret
        id_cognito
        address {
          items {
            address
            id
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getClient;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE CLIENT
  //✨✨==========================================✨✨
  createClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createClient(input: {
        company: "${payload?.company}", 
        firstName: "${payload?.firstName}", 
        lastName: "${payload?.lastName}", 
        phone: "${payload?.phone}", 
        mail: "${payload?.mail}",
        siret: "${payload?.siret || ''}",
        id_cognito: "${payload?.id_cognito}", 
        confirmed: true,
        archived: false
      }) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createClient;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==============================================✨✨
  //            UPDATE CLIENT (OR AND) ADD ADDRESS
  //✨✨==============================================✨✨
  updateDataClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateClient(input: {
        id: "${payload?.id}", 
        company: "${payload?.company}", 
        firstName: "${payload?.firstName}", 
        lastName: "${payload?.lastName}", 
        phone: "${payload?.phone}", 
        mail: "${payload?.mail}",
        siret: "${payload?.siret}"
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data.updateClient;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==============================================✨✨
  //            CONFIRM ACCOUT CLIENT (UPDATE)
  //✨✨==============================================✨✨
  confirmClientAccount: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateClient(input: {
        id: "${payload}", 
        confirmed: true
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data.updateClient;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE CLIENT (UPDATE ARCHIVED)
  //✨✨==========================================✨✨
  deleteClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateClient(input: {
        archived: true,
        id: "${payload}"
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data.updateClient;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default ClientsMiddleware;

