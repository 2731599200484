/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

// Import Components
import SecondaryButton from '../SecondaryButton';
import PrimaryButton from '../PrimaryButton';
import PrimaryInput from '../PrimaryInput';
import Table from '../Table';

// Import icons
import Search from '../../../assets/images/svg/search.svg';
import More from '../../../assets/images/svg/more.svg';
import Check from '../../../assets/images/svg/check.svg';

const ListClients = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListClients = useStoreActions((actions) => actions.middleware.ClientsMiddleware.getListClients);
  const confirmClientAccount = useStoreActions((actions) => actions.middleware.ClientsMiddleware.confirmClientAccount);
  const sendNotificationPush = useStoreActions((actions) => actions.middleware.Notification.sendNotificationPush);
  const createNotif = useStoreActions((actions) => actions.middleware.Notification.createNotif);
  const setWarnningNewClient = useStoreActions((actions) => actions.global.setWarnningNewClient);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listClients, setlistClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  //  🌱🌱 Request call List Clients  🌱🌱
  const callListClients = async () => {
    try {
      const res = await getListClients();
      const listClientData = res.map((el) => {
        return { ...el, fullName: `${el.firstName} ${el.lastName}` };
      });
      let tableConfirm = [];
      let tableNoConfirm = [];
      for (const el of listClientData) {
        if (el?.confirmed) {
          tableConfirm.push(el);
        } else {
          tableNoConfirm.push(el);
        }
      }
      setlistClients([...tableNoConfirm, ...tableConfirm]);
      setClients([...tableNoConfirm, ...tableConfirm]);
      const action = res.some((el) => el?.confirmed !== true);
      if (action) {
        setWarnningNewClient(true);
      } else {
        setWarnningNewClient(false);
      }
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des clients');
    }
  };

  //  🌱🌱 Request call List Clients  🌱🌱
  const callconfirmAccount = async (data) => {
    try {
      await confirmClientAccount(data);
      callListClients();
      sendDriverPush(data);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la confirmation du compte');
    }
  };

  //  🌱🌱 Request create notification (history)  🌱🌱
  const callcreateNotif = async (data) => {
    const newData = {
      date: Date.now(),
      idUser: data.id,
      title: 'Compte',
      message: 'Votre compte est validé, vous pouvez accéder à votre espace.',
    };
    try {
      await createNotif(newData);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la mise à jours de l'historique");
    }
  };

  //  🌱🌱  send notification push   🌱🌱
  const sendDriverPush = (id) => {
    const myClient = listClients.find((el) => el.id ===id);
    if (!!myClient?.tokenFcm) {
      const newData = {
        title: 'Ozier Transport',
        message: `Votre compte est validé, vous pouvez accéder à votre espace.`,
        tokenFcm: myClient?.tokenFcm,
      };
      sendNotificationPush(newData);
    }
    callcreateNotif(myClient);
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListClients();
  }, []);

  //  🌱🌱  2de effect  for searchBar 🌱🌱
  useEffect(() => {
    if (textSearch === '') {
      setlistClients(clients);
    } else {
      const tabFilter = clients.filter(
        (element) =>
          element.fullName?.toLowerCase().includes(textSearch.toLowerCase()) ||
          element.company?.toLowerCase().includes(textSearch.toLowerCase())
      );
      setlistClients(tabFilter);
    }
  }, [textSearch]);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="px-4 md:pt-14 md:px-9">
      {/* div Title = button */}
      <div className="flex flex-col justify-between pb-4 mb-4 border-b  items center border-gray-admin pt-11 md:pt-0 md:flex-row">
        <h3 className="pb-5 text-xl font-bold md:pb-0">Liste des clients</h3>
        <SecondaryButton
          title=" + Ajouter un nouveau client"
          link={'add'}
        />
      </div>
      {/* div Search bar */}
      <div className="relative ">
        <PrimaryInput
          styleInput={'pl-8 h-12 '}
          type="text"
          placeholder="Recherche (client ou société)"
          onChangeText={setTextSearch}
        />
        <img
          src={Search}
          alt="search icon"
          className="absolute left-0 flex items-center w-8 h-5 pl-3  top-4"
        />
      </div>
      {/* Table liste clients */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Vérifié</th>
              <th>Nom de la société</th>
              <th>Nom du client</th>
              <th>N° de Siret</th>
              <th>E-mail</th>
              <th>Téléphone</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listClients.length ? (
              listClients.map((client) => (
                <tr
                  key={client?.id}
                  className={client?.confirmed ? '' : 'bg-red-200'}
                >
                  <td>
                    {client?.confirmed ? (
                      <img
                        className="w-6 h-6 "
                        src={Check}
                        alt="icon"
                      />
                    ) : (
                      <PrimaryButton
                        title="Confirmer"
                        onClick={() => callconfirmAccount(client.id)}
                        styleInput={'p-0'}
                      />
                    )}
                  </td>
                  <td>{client?.company}</td>
                  <td>{client?.fullName}</td>
                  <td>
                    {client?.siret ? (
                      <a
                        className="text-blue-900 underline"
                        href={`https://www.societe.com/societe/novwell-${client?.siret?.slice(0, 9)}.html`}
                        target="blank"
                      >
                        {client?.siret}
                      </a>
                    ) : (
                      <p>-</p>
                    )}
                  </td>

                  <td>
                    <a
                      className="underline"
                      href={`mailto:${client?.mail}`}
                    >
                      {client?.mail}
                    </a>
                  </td>
                  <td>{client?.phone}</td>
                  <td className="flex justify-end">
                    <Link
                      to={`name-company/${client?.id}`}
                      className="w-8 rounded-lg bg-gray-sidebar py-0.5 px-1 mt-4"
                    >
                      <img
                        src={More}
                        alt="more icon"
                      />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-lg text-center text-opacity-50"
                >
                  {textSearch
                    ? "votre recherche n'a pas donné de résultats"
                    : 'La liste des clients est vide. Veuillez ajouter un client.'}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListClients;

