import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const ControlTechniqueMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST CONTROL TECHNIQUE
  //✨✨==========================================✨✨

  getListControlTechniques: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listControlTechniques(filter: {truckControlTechniqueId: {eq: "${payload}"}
    }) {
          items {
            date
            document
            garage
            truckControlTechniqueId
            id
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listControlTechniques.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE CONTROL TECHNIQUE
  //✨✨==========================================✨✨

  createControlTechniques: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createControlTechnique(input: {
        date: "${payload?.date}", 
        document: "${payload?.document}", 
        garage: "${payload?.garage}", 
        truckControlTechniqueId: "${payload?.idTruck}"
      }) 
      {
        id
      }
    }
    
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createControlTechnique;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE CONTROL TECHNIQUE
  //✨✨==========================================✨✨

  deleteTechniques: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteControlTechnique(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteControlTechnique;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default ControlTechniqueMiddleware;

