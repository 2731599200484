import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import components
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';

const ListPricesDay = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListPricesDays = useStoreActions((actions) => actions.middleware.PricesDayMiddleware.getListPricesDays);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listPricesDay, setListPricesDay] = useState([]);

  //  🌱🌱 Request call List prices day  🌱🌱
  const callListPricesDay = async () => {
    try {
      const res = await getListPricesDays();
      setListPricesDay(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des prix a la journée');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListPricesDay();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="h-full overflow-y-auto">
      {/* div main */}
      <div className="flex flex-col justify-between h-full">
        {/* div table */}
        <div className="mt-12 mx-5 md:mx-9">
          <Table>
            <thead>
              <tr>
                <th>type</th>
                <th>Prix</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {listPricesDay.length ? (
                listPricesDay.map((price) => (
                  <tr key={price.id}>
                    <td>{price?.name}</td>
                    <td>{price?.price} €</td>
                    <td>
                      <Link
                        to={`/settings/edit-prices-day/${price.id}`}
                        className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                      >
                        <img
                          src={More}
                          alt="more icon"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                  >
                    Liste des prix est vide
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default ListPricesDay;

