import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

//components custom
import SecondaryButton from '../components/SecondaryButton';
import Breadcrumbs from '../components/Breadcrumbs';

const NaveBar = () => {
  //init service
  const logOutAdminUser = useStoreActions((action) => action.middleware.Cognito.logOutAdminUser);
  const userAdmin = useStoreState((state) => state.userData.userAdmin);

  //Render
  return (
    <div className="h-full">
      <section className="flex justify-between mx-10 mt-10  pb-5 selection:items-center">
        <div>
          <h1 className="text-lg md:text-3xl">Bonjour {userAdmin?.firstName},</h1>
        </div>
        <SecondaryButton
          onClick={() => logOutAdminUser()}
          title={'Déconnexion'}
        />
      </section>
      {/* <div className="breadcrumb"><Breadcrumbs /></div> */}
    </div>
  );
};

export default NaveBar;
