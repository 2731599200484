import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const AddressMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST ADDRESS
  //✨✨==========================================✨✨
  getListAddress: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listAddresses(filter: {clientAddressId: {eq: "${payload}"}, and: {archived: {eq: false}}}) {
          items {
            id
            address
            price
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listAddresses.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ADDRESS
  //✨✨==========================================✨✨
  getAddress: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        getAddress(id: "${payload}") {
            id
            address
            price
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getAddress;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE ADDRESS
  //✨✨==========================================✨✨
  createAddress: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createAddress(input: {
            address: "${payload?.address}", 
            price: "${payload?.price}", 
            clientAddressId: "${payload?.idClient}", 
            archived: false
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createAddress;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE ADDRESS
  //✨✨==========================================✨✨
  updateAddress: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateAddress(input: {
            id: "${payload?.id}", 
            address: "${payload?.address}", 
            price: "${payload?.price}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateAddress;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            ADD PRICE (UPDATE ADDRESS)
  //✨✨==========================================✨✨
  addPriceAddress: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateAddress(input: {
            id: "${payload?.id}",
            price: "${payload?.price}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateAddress;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE ADDRESS (UPDATE)
  //✨✨==========================================✨✨
  deleteAddress: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateAddress(input: {
          id: "${payload}", 
          archived: true
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateAddress;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default AddressMiddleware;

