export const dateFormat = (date, separation = '-') => {
  const temps = new Date(date);
  return {
    year: temps.getFullYear().toString(),
    month: ('0' + (temps.getMonth() + 1)).slice(-2),
    day: ('0' + temps.getDate()).slice(-2),
    full: `${('0' + temps.getDate()).slice(-2)}${separation}${('0' + (temps.getMonth() + 1)).slice(
      -2
    )}${separation}${temps.getFullYear().toString()}`,
  };
};

export const tableColors = ['#D68F83', '#556B83', '#AADF94', '#E6AB7D', '#77A7C7'];

const lengthMonth = (date) => {
  const refMonth = dateFormat(date);
  const myMaxDay = (number) => dateFormat(`${refMonth.year}-${refMonth.month}-${number}`).month === refMonth.month;
  if (myMaxDay(31)) return 31;
  if (myMaxDay(30)) return 30;
  if (myMaxDay(29)) return 29;
  if (myMaxDay(28)) return 28;
};

export const plageDate = (date, day) => {
  const dateTemporaire = new Date(date);
  const refOneDay = 86400000; //Une journée en millisecondes
  let result = [];
  for (let index = 0; index < day; index++) {
    result.push(dateTemporaire.getTime() + refOneDay * index);
  }
  return result;
};

// Calcule la moyenne ds un tableau
export const moyenne = (tab, value) => {
  return tab.reduce((acc, currentValue) => acc + currentValue[value], 0) / tab.length;
};

// Calcule la somme ds un tableau
export const total = (tab, value) => {
  return tab.reduce((acc, currentValue) => (!!currentValue[value] ? acc + parseInt(currentValue[value]) : acc), 0);
};

//🍭 Par jour
export const byDay = (data, date) => {
  let result = [];
  const numberDay = lengthMonth(date);
  for (let index = 1; index <= numberDay; index++) {
    const tabDay = data.filter((item) => index === parseInt(dateFormat(item.date).day));

    result.push({ date: index, price: total(tabDay, 'price') });
    if (!result[index - 1].price) {
      result[index - 1].price = 0;
    }
  }
  return {
    result,
    label: result.map((item) => item.date),
    data: result.map((item) => item.price),
  };
};

//🍭 Par mois -12
export const byMonth = (date) => {
  let result = [];
  for (let index = 0; index < 12; index++) {
    const tabMonth = date.filter((item) => index + 1 === parseInt(dateFormat(item.date).month));

    result.push({ date: index, price: total(tabMonth, 'price') });
    if (!result[index].price) {
      result[index].price = 0;
    }
  }
  return {
    result,
    label: result.map((item) => listMonth[item.date]),
    data: result.map((item) => item.price),
  };
};

//🍭 Par année  🍗
export const byYear = (date, yearIn, yearOut) => {
  let result = [];
  let roleIndex = 0;
  for (let index = yearIn; index <= yearOut; index++) {
    const tabYear = date.filter((item) => index === parseInt(dateFormat(item.date).year));

    result.push({ date: index, price: total(tabYear, 'price') });
    if (!result[roleIndex].price) {
      result[roleIndex].price = 0;
    }
    roleIndex++;
  }

  return {
    result,
    label: result.map((item) => item.date),
    data: result.map((item) => item.price),
  };
};
export const listMonth = ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Dec'];

