import { action } from 'easy-peasy';

const route = {
  // is user connected
  connected: null,
  setConnected: action((state, payload) => {
    state.connected = payload;
  }),
};

export default route;
