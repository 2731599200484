import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Table from '../../Table';
import Modal from '../../Modal';
import { dateFormat } from '../../../tools/date';

// Import icons
import ReceiveSquare from '../../../../assets/images/svg/receive-square.svg';
import DeleteIcon from '../../../../assets/images/svg/delete.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

//env
const urlS3 = process.env.REACT_APP_URL_S3_OBJECT;

const TechnicalControl = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getListControlTechniques = useStoreActions(
    (actions) => actions.middleware.ControlTechniqueMiddleware.getListControlTechniques
  );
  const deleteTechniques = useStoreActions((actions) => actions.middleware.ControlTechniqueMiddleware.deleteTechniques);

  //  🌱🌱  Etats (données)  🌱🌱
  const [controlsTechnique, setControlsTechnique] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [ctSelected, setCtSelected] = useState({});

  //  🌱🌱 Request call List Trucks  🌱🌱
  const callListControlTechniques = async () => {
    try {
      const res = await getListControlTechniques(idTruck);
      setControlsTechnique(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des controls techniques');
    }
  };

  //  🌱🌱 Request call List Trucks  🌱🌱
  const deleteProfileControlTechniques = async () => {
    try {
      const res = await deleteTechniques(ctSelected?.id);
      callListControlTechniques();
      setShowModalDelete(false);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la suppression du control technique');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListControlTechniques();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-16 flex flex-col  justify-betwee grow">
      {/* div table Control Technique */}
      <div className="px-4 md:px-9 grow">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Réalisé par</th>

              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {controlsTechnique.length ? (
              controlsTechnique.map((controlTechnique) => {
                const dateNow = dateFormat(controlTechnique?.date, '-').full;
                return (
                  <tr key={controlTechnique?.id}>
                    <td>{dateNow}</td>
                    <td>{controlTechnique?.garage}</td>
                    <td>
                      <div className="flex justify-end gap-x-2">
                        <button
                          className=" border border-separate p-3 rounded-xl"
                          onClick={() => {
                            setCtSelected(controlTechnique);
                            setShowModalDelete(true);
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt="more icon"
                          />
                        </button>
                        <a
                          href={`${urlS3}${controlTechnique?.document}`}
                          className="border border-separate p-3 rounded-xl"
                        >
                          <img
                            src={ReceiveSquare}
                            alt="more icon"
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center text-lg"
                >
                  Ajouter un Control technique
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className=" gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
      <Modal
        title="Supprimer le control technique"
        text="Êtes-vous sûre de vouloir supprimer ce control technique ? 
        Cette action est définitive. Vous n’aurez plus accès à ce control technique."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileControlTechniques();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default TechnicalControl;

