import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

//components custom
import Container from '../shared/layout/Container';

//Pages and components
import NotFound from '../pages/Notfound';
import Dashboard from '../pages/Dashboard';
import Administrators from '../pages/Administrators';
import Drivers from '../pages/Drivers';
import Trucks from '../pages/Trucks';
import Clients from '../pages/Clients';
import NameCompany from '../pages/NameCompany';
import AddClients from '../shared/components/clients/AddClients';
import EditClient from '../shared/components/clients/clientFolder/EditClient';
import Orders from '../pages/Orders';
import EditOrderFinish from '../shared/components/Orders/OrdersFinish/EditListOrderFinish';
import EditOrderNotFinish from '../shared/components/Orders/OrdersNotFinish/EditListOrderNotFinish';
import ListOrderNotFinish from '../shared/components/Orders/OrdersNotFinish/ListOrderNotFinish';
import ListOrderFinish from '../shared/components/Orders/OrdersFinish/ListOrderFinish';
import Planning from '../pages/Planning';
import Settings from '../pages/Settings';
import Anomalie from '../pages/Anomalie';
import AddAdministrators from '../shared/components/admin/AddAdministrators';
import EditAdministrators from '../shared/components/admin/EditAdministrators';
import AddDrivers from '../shared/components/drivers/AddDrivers';
import EditDrivers from '../shared/components/drivers/EditDrivers';
import AddTrucs from '../shared/components/trucks/AddTrucs';
import Matriculation from '../pages/Matriculation';
import VehicleFile from '../shared/components/trucks/matriculation/VehicleFile';
import Refueling from '../shared/components/trucks/matriculation/Refueling';
import TechnicalControl from '../shared/components/trucks/matriculation/TechnicalControl';
import AddTechnicalControl from '../shared/components/trucks/matriculation/AddTechnicalControl';
import AddOrder from '../shared/components/Orders/AddOrder';
import EditOrder from '../shared/components/Orders/OrdersNotFinish/EditOrder';
import PreviewOrder from '../shared/components/Orders/OrdersFinish/PreviewOrder';
import PreviewDoc from '../shared/components/Orders/OrdersFinish/PreviewDoc';
import Materials from '../shared/components/settings/Materials';
import AddMaterials from '../shared/components/settings/materials/AddMaterials';
import EditMaterials from '../shared/components/settings/materials/EditMaterials';
import Preview from '../shared/components/drivers/Preview';
import ListRevision from '../shared/components/trucks/matriculation/ListRevision';
import ListFollow from '../shared/components/trucks/matriculation/ListFollow';
import TruckPreview from '../shared/components/trucks/matriculation/Preview';
import AddRevision from '../shared/components/trucks/matriculation/AddRevision';
import AddFollow from '../shared/components/trucks/matriculation/AddFollow';
import ListAdress from '../shared/components/clients/address/ListAdress';
import EditAddress from '../shared/components/clients/address/EditAddress';
import AddAddress from '../shared/components/clients/address/AddAddress';
import ListPricesDay from '../shared/components/settings/ListPricesDay';
import AddPicesDay from '../shared/components/settings/PricesDay/AddPicesDay';
import EditPricesDay from '../shared/components/settings/PricesDay/EditPricesDay';
import Analyse from '../shared/components/clients/Analyse';

import Cgu from '../pages/Cgu';
import AddAnomalie from '../shared/components/anomalie/AddAnomalie';

const Registed = () => {
  return (
    <>
      <Routes>
      <Route
        path="/politique-de-confidentialite&mentions-legales"
        element={<Cgu />}
      />
        <Route
          path="/"
          element={<Container />}
        >
          <Route
            path="/"
            element={<Dashboard />}
          />
          <Route path="administrators">
            <Route
              index
              element={<Administrators />}
            />
            <Route
              path="edit/:idAdmin"
              element={<EditAdministrators />}
            />
            <Route
              path="add"
              element={<AddAdministrators />}
            />
          </Route>
          <Route path="/drivers">
            <Route
              index
              element={<Drivers />}
            />

            <Route
              path="add"
              element={<AddDrivers />}
            />
            <Route
              path="edit/:idDriver"
              element={<EditDrivers />}
            />
            <Route
              path="preview/:idDriver"
              element={<Preview />}
            />
          </Route>

          <Route path="trucks">
            <Route
              index
              element={<Trucks />}
            />
            <Route
              path="add-technical-control/:idTruck"
              element={<AddTechnicalControl />}
            />
            <Route
              path="add-revision/:idTruck"
              element={<AddRevision />}
            />
            <Route
              path="add-follow/:idTruck"
              element={<AddFollow />}
            />
            <Route
              path="preview/:idTruck"
              element={<TruckPreview />}
            />
            <Route
              path="add"
              element={<AddTrucs />}
            />
            <Route
              path="matriculation/:idTruck"
              element={<Matriculation />}
            >
              <Route
                index
                element={
                  <Navigate
                    replace
                    to="vehicle-file"
                  />
                }
              />
              <Route
                path="vehicle-file"
                element={<VehicleFile />}
              />
              <Route
                path="refueling"
                element={<Refueling />}
              />
              <Route
                path="technical-control"
                element={<TechnicalControl />}
              />
              <Route
                path="list-revision"
                element={<ListRevision />}
              />
              <Route
                path="list-follow"
                element={<ListFollow />}
              />
              <Route />
            </Route>
          </Route>
          <Route path="clients">
            <Route
              index
              element={<Clients />}
            />
            <Route
              path="add"
              element={<AddClients />}
            />
            <Route
              path="editNotFinish/:idOrder"
              element={<EditOrderNotFinish />}
            />
            <Route
              path="editFinish/:idOrder"
              element={<EditOrderFinish />}
            />
            <Route
              path="add-address/:idClient"
              element={<AddAddress />}
            />
            <Route
              path="edith-address/:idAddress"
              element={<EditAddress />}
            />
            <Route
              path="name-company/:idClient"
              element={<NameCompany />}
            >
              <Route
                index
                element={
                  <Navigate
                    replace
                    to="analyse"
                  />
                }
              />
              <Route
                path="analyse"
                element={<Analyse />}
              />
              <Route
                path="list-order-not-finish"
                element={<ListOrderNotFinish />}
              />

              <Route
                path="list-order-finish"
                element={<ListOrderFinish />}
              />

              <Route
                path="edit-client"
                element={<EditClient />}
              />

              <Route
                path="list-address"
                element={<ListAdress />}
              />
            </Route>
          </Route>
          <Route path="orders">
            <Route
              index
              element={
                <Navigate
                  replace
                  to="orders/list-order-not-finish"
                />
              }
            />
            <Route
              path="add-order"
              element={<AddOrder />}
            />
            <Route
              path="edit-order/:idOrder"
              element={<EditOrder />}
            />
            <Route
              path="preview-order/:idOrder"
              element={<PreviewOrder />}
            />
            <Route
              path="preview/:idOrder"
              element={<PreviewDoc />}
            />
            <Route
              path="orders"
              element={<Orders />}
            >
              <Route
                path="list-order-not-finish"
                element={<ListOrderNotFinish />}
              />
              <Route
                path="list-order-finish"
                element={<ListOrderFinish />}
              />
            </Route>
          </Route>

          <Route
            path="/planning"
            element={<Planning />}
          />
          <Route path="settings">
            <Route
              index
              element={
                <Navigate
                  replace
                  to="settings/materials"
                />
              }
            />

            <Route
              path="add-materials"
              element={<AddMaterials />}
            />
            <Route
              path="edit-materials/:idMaterial"
              element={<EditMaterials />}
            />

            <Route
              path="add-prices-day"
              element={<AddPicesDay />}
            />
            <Route
              path="edit-prices-day/:idPricesDay"
              element={<EditPricesDay />}
            />

            <Route
              path="settings"
              element={<Settings />}
            >
              <Route
                path="materials"
                element={<Materials />}
              />
              <Route
                path="list-prices-day"
                element={<ListPricesDay />}
              />
            </Route>
          </Route>
          <Route path="/problems">
          <Route
              index
              element={<Anomalie/>
              }
            />
             <Route
              path="add"
              element={<AddAnomalie />}
            />

          </Route>
        </Route>
        <Route
          path="/*"
          element={<NotFound />}
        />
      </Routes>
    </>
  );
};

export default Registed;
