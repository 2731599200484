import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { typeFile } from '../../tools/file';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../Modal';
import DatePicker_date from '../DatePicker_date';

//import icons
import Check from '../../../assets/images/svg/check.svg';
import FileUpload from '../../../assets/images/svg/file-upload.svg';
import Download from '../../../assets/images/svg/download.svg';

//  🌱🌱 Data Form Driver  🌱🌱
const formDriver = [
  {
    label: 'Prénom',
    inputType: 'text',
    valueName: 'firstName',
    placeholder: 'Prénom',
    errorMsg: 'Veuillez entrer le prénom du chauffeur',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Nom',
    inputType: 'text',
    valueName: 'lastName',
    placeholder: 'Nom',
    errorMsg: 'Veuillez entrer le nom du chauffeur',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Numéro',
    inputType: 'text',
    valueName: 'phone',
    placeholder: '+596696000000',
    errorMsg: 'Veuillez saisir un numéro de téléphone au format international +00...',
    required: true,
    regex: /^([+])([0-9]{11,12})$/,
    halfWidth: true,
  },
];

const AddDrivers = () => {
  //  🌱🌱 Init store(services)  🌱🌱
  const createDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.createDriver);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const createDriverUser = useStoreActions((action) => action.middleware.Cognito.createDriverUser);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [selectedFilePermis, setSelectedFilePermis] = useState(null);
  const [selectedFileVm, setSelectedFileVm] = useState(null);
  const [msgErrorDocVm, setMsgErrorDocVm] = useState(false);
  const [msgErrorInputVm, setMsgErrorInputVm] = useState(false);
  const [msgErrorDocPermis, setMsgErrorDocPermis] = useState(false);
  const [msgErrorInputPermis, setMsgErrorInputPermis] = useState(false);
  const [datePermis, setDatePermis] = useState('');
  const [dateVm, setDateVm] = useState('');

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱 Init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`${Date.now()}`).current;

  //  🌱🌱 Request create driver Cognito  🌱🌱
  const createCognitodriver = async (data) => {
    try {
      const res = await createDriverUser(data.phone);
      const newData = {
        ...data,
        id_cognito: res.userSub,
      };
      createProfileDriver(newData);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
    }
  };

  //  🌱🌱 Request create Driver  🌱🌱
  const createProfileDriver = async (data) => {
    try {
      const res = await createDriver(data);
      setShowModal(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
    }
  };

  //  🌱🌱 Function  handleFileInput permis  🌱🌱
  const handleFilePermisInput = (e) => {
    setSelectedFilePermis(e.target.files[0]);
    setMsgErrorDocPermis(false);
  };

  //  🌱🌱 Function  handleFileInput vm  🌱🌱
  const handleFileVmInput = (e) => {
    setSelectedFileVm(e.target.files[0]);
    setMsgErrorDocVm(false);
  };

  useEffect(() => {
    if (datePermis) {
      setMsgErrorInputPermis(false);
    }
  }, [datePermis]);

  useEffect(() => {
    if (dateVm) {
      setMsgErrorInputVm(false);
    }
  }, [dateVm]);

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status && selectedFilePermis && selectedFileVm && datePermis && dateVm) {
      const newData = {
        ...value.values,
        id_cognito: 'XXXX',
        docPermis: `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}`,
        datePermis: datePermis,
        docVm: `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}`,
        dateVm: dateVm,
      };
      try {
        await uploadFile({
          file: selectedFilePermis,
          nameFile: `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}`,
          contentType: selectedFilePermis.type,
        });
        try {
          await uploadFile({
            file: selectedFileVm,
            nameFile: `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}`,
            contentType: selectedFileVm.type,
          });
          createCognitodriver(newData);
        } catch (error) {
          toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
        }
      } catch (error) {
        toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
      }
    }
    if (!selectedFilePermis) {
      setMsgErrorDocPermis(true);
    }
    if (!selectedFileVm) {
      setMsgErrorDocVm(true);
    }
    if (!datePermis) {
      setMsgErrorInputPermis(true);
    }
    if (!dateVm) {
      setMsgErrorInputVm(true);
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Ajouter un nouveau chauffeur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formDriver}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
        {/* div permis */}
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Permis de conduire</label>
          <label
            htmlFor="dropzone-file-permis"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFilePermis ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFilePermis ? selectedFilePermis.name : ' Cliquez pour ajouter le permis de conduire'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFilePermis ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-permis"
              type="file"
              className="hidden"
              onChange={handleFilePermisInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocPermis ? 'Veuillez ajouter un permis de conduire' : ''}</p>
          <div>
            <label>Date de validité</label>
            <DatePicker_date
              selected={datePermis}
              onChange={setDatePermis}
            />
            <p className="text-red-error">{msgErrorInputPermis ? 'Veuillez ajouter la date de fin validité' : ''}</p>
          </div>
        </div>
        {/* div Vm */}
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Visite Médicale</label>
          <label
            htmlFor="dropzone-file-vm"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFileVm ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFileVm ? selectedFileVm.name : ' Cliquez pour ajouter la visite médical'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFileVm ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-vm"
              type="file"
              className="hidden"
              onChange={handleFileVmInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocVm ? 'Veuillez ajouter une visite médical' : ''}</p>
          <div>
            <label>Date de validité</label>
            <DatePicker_date
              onChange={setDateVm}
              selected={dateVm}
            />
            <p className="text-red-error">{msgErrorInputVm ? 'Veuillez ajouter la date de fin validité' : ''}</p>
          </div>
        </div>
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un nouveau chauffeur"
          style="bg-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>

      {/* Modal Add Driver */}
      <Modal
        title="Nouveau chauffeur enregistré"
        text="Nous avons bien enregistré votre nouveau chauffeur. Vous le retrouverez dans la liste des chauffeurs et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddDrivers;

