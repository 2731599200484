import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const MonitoringMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST CLIENT
  //✨✨==========================================✨✨
  getListClients: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listClients {
          items {
            id
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listClients.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST ORDER
  //✨✨==========================================✨✨
  getListOrders: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {eq: "done"}}) {
          items {
            price
            quantity
          }
        }
      }      
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST ORDER BY MONTH
  //✨✨==========================================✨✨
  getListOrdersByMonth: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    console.log("payload orders Month",payload)
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {eq: "done"}, and: [{datePlanned: {contains: "${payload?.year}"}},{datePlanned: {contains: "${payload?.month}"}}]}) {
          items {
            price
            datePlanned
            quantity
            price
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST ORDER BY YEAR
  //✨✨==========================================✨✨
  getListOrdersByYear: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {eq: "done"}, and: {datePlanned: {contains: "${payload?.year}"}}}) {
          items {
            price
            datePlanned
            quantity
            price
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET MATERIALS BY CLIENT (ORDER)
  //✨✨==========================================✨✨
  getListMaterilasByClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listOrders(filter: {statut: {eq: "done"}, and: {clientOrderId: {eq: "${payload}"}}}) {
          items {
            id
            price
            quantity
            marchandise {
              name
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrders.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default MonitoringMiddleware;

