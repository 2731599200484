import { action, thunk } from 'easy-peasy';

const global = {
  activity: false,
  setActivity: action((state, payload) => {
    state.activity = payload;
  }),

  selectDriver: '',
  setSelectDriver: action((state, payload) => {
    state.selectDriver = payload;
    state.selectMarchandise = '';
  }),

  selectMarchandise: '',
  setSelectMarchandise: action((state, payload) => {
    state.selectMarchandise = payload;
    state.selectDriver = '';
  }),

  warnningOrder: false,
  setWarnningOrder: action((state, payload) => {
    state.warnningOrder = payload;
  }),

  warnningNewClient: false,
  setWarnningNewClient: action((state, payload) => {
    state.warnningNewClient = payload;
  }),
};

export default global;
