export const typeFile = (namefile) => namefile.split('.').pop();

export const lastNumberCoupon = (table, key = null) => {
  const maxNumber = table.reduce((r, b) => Math.max(r, key ? b[key] : b), 0);
  const final = maxNumber + 1;
  return final;
};

export const lastDate = (table, key) => {
  const myTable = table.map((el) => {
    return { ...el, lastGetTime: new Date(el[key]).getTime() };
  });
  return lastNumberCoupon(myTable, 'lastGetTime');
};

export const showZipCode = (adresse) => {
  return adresse
    .replace(/[a-zA-Z]/g, '', '')
    .split(' ')
    .find((text) => text.length === 5);
};

export const sorted = (table, key, reverse = false) => {
  let newTable = JSON.parse(JSON.stringify(table));
  newTable.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
  if (reverse) newTable.reverse();
  return newTable;
};

export const fusionOccurrence = (table, key, key2, key3) => {
  let result = [];
  table.forEach((el) => {
    if (el[key]) {
      const lot = result.findIndex((it) => it[key] == el[key]);
      if (lot === -1) {
        result.push({ ...el, count: 1, total: el[key2], total2: el[key3] });
      } else {
        result[lot] = {
          ...result[lot],
          count: result[lot].count + 1,
          total: parseInt(result[lot].total) + parseInt(el[key2]),
          total2: parseInt(result[lot].total2) + parseInt(el[key3]),
        };
      }
    }
  });
  return result;
};

export const getRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
};

const intToHex = (nrmber) => {
  return nrmber.toString(16).padStart(2, '0');
};

