import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

const Table = ({ children }) => {
  return (
    <ScrollContainer
      className="componentTable"
      hideScrollbars={false}
    >
      <table>{children}</table>
    </ScrollContainer>
  );
};

export default Table;
