/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';
import { dateFormat } from '../../tools/date';
import HoverStatutsAnomalie from './HoverStatutsAnomalie';


const ListProblems = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListProblem = useStoreActions((actions) => actions.middleware.ProblemsMiddleware.getListProblems);
  const updateStautsProblem = useStoreActions((actions) => actions.middleware.ProblemsMiddleware.updateStautsProblem);

  //  🌱🌱  Etats (données)  🌱🌱
  const [problems, setProblems] = useState([]);

  //  🌱🌱 Request call List Trucks  🌱🌱
  const callListAnomalie = async () => {
    try {
        const res = await getListProblem()
        console.log(res)
        setProblems(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des camions');
    }
  };

  const changeStatus = async (id, status) => {
    try {
        const res = await updateStautsProblem({id, status})
        console.log('res', res)
        callListAnomalie();
        // setProblems(res);
    } catch (error) {
        console.log('err',error)
        toast.error('❗️ Une erreur est survenue lors du changement de statut de l\'anomalie');
    }
    };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListAnomalie();
  }, []);


  //  🎉🎉  Render  🎉🎉
  return (
    <section className="px-4 md:pt-14 md:px-9">
      {/* div Title +button */}
      <div className="flex flex-col justify-between pb-4 mb-4 space-y-1 border-b items center border-gray-admin pt-11 md:pt-0 md:flex-row md:space-y-0">
        <h3 className="text-xl font-bold ">Liste des Anomalies</h3>
        <SecondaryButton
          title=" + Ajouter une nouvelle Anomalie"
          link={'add'}
        />
      </div>

      {/* Table list Anomalie */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th className='min-w-[100px]'>Immatriculation</th>
              <th className='min-w-[100px]'>Modèle du véhicule</th>
              <th className='min-w-[100px]'>Date du problème</th>
              <th className='min-w-[100px]'>
                <HoverStatutsAnomalie text="Statut ☉" />
              </th>
              <th className='min-w-[100px]'>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {console.log(problems)}
            {problems.length ? (
              problems.map((problem) => {
                const createDate = dateFormat(problem?.date).full;
                const setpcolor = () => {
                    if (problem.status==="Anomalie non réglée") {
                      return 'bg-statut-step-0';
                    }
                    if (problem.status==="Anomalie réglée") {
                      return 'bg-statut-step-2';
                    } else {
                      return 'bg-statut-step-1';
                    }
                };
        
                return (
                  <tr key={problem.id}>
                    <td>{problem.truck.registration}</td>
                    <td>{problem.truck.model}</td>
                    <td>{createDate}</td>
                    <td><span className={`w-4 h-4 block rounded-md shadow-2xl ${setpcolor()}`}></span></td>
                    <td>{problem.description}</td>
                    <td className="flex items-center pt-4 space-x-1">
                      {problem.status==="Anomalie non réglée" &&
                      (
                        <div className='flex justify-between gap-3'>
                            <div className="w-full h-full m-2 rounded-md shadow-2xl">
                                <div onClick={(e)=> {
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie réglée')}
                                    } className="absolute flex w-40 p-2 font-semibold text-white duration-300 opacity-0 hover:opacity-80 rounded-xl bg-gray-file">Passer cette anomalie en "Réparer"</div>
                                <button  className="block w-full h-full p-2 font-semibold text-white rounded-md shadow-2xl bg-statut-step-2">Changer le stauts</button>
                            </div>
                            <div className="w-full h-full m-2 rounded-md shadow-2xl">
                                <div className="absolute flex w-40 p-2 font-semibold text-white duration-300 opacity-0 hover:opacity-80 rounded-xl bg-gray-file">Passer l'anomalie en "en cours"</div>
                                <button onClick={(e)=>changeStatus(problem.id, 'En cours')} className="block w-full h-full p-2 font-semibold text-white rounded-md shadow-2xl bg-statut-step-1">Changer le stauts</button>
                            </div>
                        </div>
                      )
                      }
                       {problem.status==="Anomalie réglée" &&
                      (
                        <div className='flex justify-between gap-3'>
                            <div className="w-full h-full m-2 rounded-md shadow-2xl">
                                <div onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie non réglée')}} className="absolute flex w-40 p-2 font-semibold text-white duration-500 opacity-0 hover:-translate-y-16 hover:opacity-80 rounded-xl bg-gray-file">Passer cette anomalie en "Non réglée"</div>
                                <button onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie non réglée')}} className="block w-full h-full p-2 font-semibold text-white rounded-md shadow-2xl bg-statut-step-0">Changer le stauts</button>
                            </div>
                            <div className="w-full h-full m-2 rounded-md shadow-2xl">
                                <div onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'En cours')}}className="absolute flex w-40 p-2 font-semibold text-white duration-300 opacity-0 hover:opacity-80 rounded-xl bg-gray-file">Passer l'anomalie en "en cours"</div>
                                <button onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'En cours')}} className="block w-full h-full p-2 font-semibold text-white rounded-md shadow-2xl bg-statut-step-1">Changer le stauts</button>
                            </div>
                        </div>
                      )
                      }
                       {problem.status==="En cours" &&
                      (
                        <div className='flex justify-between gap-3'>
                            <div className="w-full h-full m-2 rounded-md shadow-2xl">
                                <div onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie réglée')}} 
                                    className="absolute flex w-40 p-2 font-semibold text-white duration-300 opacity-0 hover:opacity-80 rounded-xl bg-gray-file">Passer cette anomalie en "Réparer"</div>
                                <button onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie réglée')}} className="block w-full h-full p-2 font-semibold text-white rounded-md shadow-2xl bg-statut-step-0">Changer le stauts</button>
                            </div>
                            <div className="w-full h-full m-2 rounded-md shadow-2xl">
                                <div onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie non réglée')}}
                                className="absolute flex w-40 p-2 font-semibold text-white duration-300 opacity-0 hover:opacity-80 rounded-xl bg-gray-file">Passer l'anomalie en "en cours"</div>
                                <button onClick={(e)=>{
                                    console.log(e)
                                    changeStatus(problem.id, 'Anomalie non réglée')}} className="block w-full h-full p-2 font-semibold text-white rounded-md shadow-2xl bg-statut-step-1">Changer le stauts</button>
                            </div>
                        </div>
                      )
                      }
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-lg text-center"
                >
                  La liste des anomalies est vide.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListProblems;

