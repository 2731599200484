import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const PricesDayMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST PRICES DAY
  //✨✨==========================================✨✨

  getListPricesDays: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listModelDays(filter: {archived: {eq: false}}) {
          items {
            id
            name
            price
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listModelDays.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET PRICES DAY
  //✨✨==========================================✨✨

  getPricesDay: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        getModelDay(id: "${payload}") {
          id
          name
          price
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getModelDay;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE PRICES DAY
  //✨✨==========================================✨✨

  createPricesDay: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createModelDay(input: {
            archived: false, 
            name: "${payload?.name}", 
            price: "${payload?.price}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createModelDay;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE PRICES DAY
  //✨✨==========================================✨✨

  updatePricesDay: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateModelDay(input: {
            id: "${payload?.id}", 
            name: "${payload?.name}", 
            price: "${payload?.price}"
        })
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateModelDay;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            DELETE PRICES DAY (UPDATE ARCHIVED)
  //✨✨============================================✨✨

  deletePricesDay: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateModelDay(input: {
            id: "${payload}", 
            archived: true
        })
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateModelDay;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default PricesDayMiddleware;

