import React from 'react';
import ListAdministrators from '../shared/components/admin/ListAdministrators';

const Administrators = () => {
  return (
    <div>
      <ListAdministrators />
    </div>
  );
};

export default Administrators;

