import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../SecondaryButton';
import { typeFile } from '../../../tools/file';

// import logo
import FilePdf from '../../../../assets/images/svg/file-pdf.svg';

//env
const S3url = process.env.REACT_APP_URL_S3_OBJECT;

const Preview = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();

  //  🌱🌱 Init store(services)  🌱🌱
  const getTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.getTruck);

  //  🌱🌱  Etats (données)  🌱🌱
  const [truck, setTruck] = useState({});
  const [typeDoc, setTypeDoc] = useState(null);

  //  🌱🌱 Request call Get Truck  🌱🌱
  const callTruck = async () => {
    try {
      const res = await getTruck(idTruck);
      setTruck(res);
      setTypeDoc(typeFile(res.document));
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement du camion');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callTruck();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-4 md:pt-14 px-4 md:px-9 h-full flex flex-col">
      <h3 className="font-bold text-xl border-b border-gray-admin pb-[2.125rem]">
        Carte grise {truck?.id && `${truck.model} ${truck.registration}`}
      </h3>

      <label className="text-bleu-admin mt-4">Carte grise </label>
      <div className="flex flex-col md:flex-row justify-center grow">
        {truck?.document && typeDoc === 'pdf' && (
          <div className="border p-4 rounded-lg m-4 flex flex-col items-center justify-center w-full aspect-square">
            <p>PDF</p>
            <img
              className="h-40 px-10"
              src={FilePdf}
              alt="file"
            />
            <iframe
              src={`${S3url}${truck.document}`}
              width="100%"
              height="100%"
            ></iframe>
            <a
              className="px-4 py-3 text-blue-admin  rounded-lg cursor-pointer border border-gray-admin font-medium text-sm text-center bg-white-admin  mt-8"
              href={`${S3url}${truck.document}`}
            >
              Télécharger
            </a>
          </div>
        )}
        {truck?.document && typeDoc !== 'pdf' && (
          <div className="border p-4 rounded-lg m-4 flex flex-col items-center justify-center w-full">
            <img
              className="w-full"
              src={`${S3url}${truck.document}`}
              alt="file"
            />
            <a
              className="px-4 py-3 text-blue-admin  rounded-lg cursor-pointer border border-gray-admin font-medium text-sm text-center bg-white-admin mt-8"
              href={`${S3url}${truck.document}`}
            >
              Télécharger
            </a>
          </div>
        )}
      </div>
      <div className="flex flex-col-reverse gap-y-4  pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
    </section>
  );
};

export default Preview;

