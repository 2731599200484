import React from 'react';
import ListTrucks from '../shared/components/trucks/ListTrucks';

const Trucks = () => {
  return (
    <>
      <ListTrucks />
    </>
  );
};

export default Trucks;

