import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Modal from '../../Modal';

//import icons
import Check from '../../../../assets/images/svg/check.svg';

const AddMaterials = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 init store(services)   🌱🌱
  const createMaterials = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.createMaterials);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);

  //  🌱🌱  formulaire material  🌱🌱
  const formulairMaterial = [
    {
      label: 'Matériau',
      inputType: 'text',
      valueName: 'name',
      placeholder: 'Matériau',
      errorMsg: 'Entrer un materials',
      required: true,
      halfWidth: true,
    },
  ];

  //  🌱🌱 Request create material  🌱🌱
  const createProlfilematerial = async (data) => {
    try {
      const res = await createMaterials(data);
      setShowModal(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la creation de la marchandise');
    }
  };

  // 🌱🌱 submit Form 🌱🌱
  const submit = (value) => {
    if (value.status) {
      createProlfilematerial(value.values);
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-16 h-full flex flex-col overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-9">
        <h3 className="font-bold text-xl ">Ajouter un matériau</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formulairMaterial}
          resultSubmit={submit}
          ref={submitRef}
        />
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un matériau"
          style="bg-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      <Modal
        title="Nouvelle marchantise enregistrée"
        text="Nous avons bien enregistré votre nouvelle marchandise. Vous la retrouverez dans la liste des marchandises et pouvez la modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddMaterials;

