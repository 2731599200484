import React, { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import FormCustom from '../../FormCustom';
import Modal from '../../Modal';
import { typeFile } from '../../../tools/file';

//import icons
import Check from '../../../../assets/images/svg/check.svg';
import FileUpload from '../../../../assets/images/svg/file-upload.svg';
import Download from '../../../../assets/images/svg/download.svg';

//  🌱🌱 Data Form Driver  🌱🌱
const formDriver = [
  {
    label: 'Titre',
    inputType: 'text',
    valueName: 'title',
    placeholder: 'Sujet du suivi',
    errorMsg: 'Veuillez entrer le titre du suivi',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Date de réalisation',
    inputType: 'text',
    valueName: 'date',
    placeholder: '01-12-2023',
    errorMsg: 'Veuillez entrer une date valide',
    required: true,
    halfWidth: true,
    regex: /^\d{2}-\d{2}-\d{4}$/,
  },
  {
    label: 'Description',
    inputType: 'text',
    valueName: 'description',
    placeholder: "description de l'intervention",
    errorMsg: 'Veuillez entrer une description',
    required: true,
  },
];

const AddFollow = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();

  //  🌱🌱 Init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`${Date.now()}`).current;

  //  🌱🌱  init store (service)  🌱🌱
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const createFollow = useStoreActions((actions) => actions.middleware.FollowMiddleware.createFollow);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [msgError, setMsgError] = useState(false);

  //  🌱🌱 Request create revision  🌱🌱
  const createProfileFollow = async (data) => {
    try {
      const res = await createFollow(data);
      setShowModal(true);
    } catch (error) {
      toast.error("❗️  Une erreur est survenue durant l'ajout de la Revision.");
    }
  };

  //  🌱🌱 Function  handleFileInput  🌱🌱
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setMsgError(false);
  };

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status) {
      const splitDate = value.values.date.split('-');
      const newData = {
        ...value.values,
        date: new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`),
        document: selectedFile ? `follow-${fileNameRef}.${typeFile(selectedFile.name)}` : '',
        idTruck: idTruck,
      };
      if (selectedFile) {
        try {
          await uploadFile({
            file: selectedFile,
            nameFile: `follow-${fileNameRef}.${typeFile(selectedFile.name)}`,
            contentType: selectedFile.type,
          });
          createProfileFollow(newData);
        } catch (error) {
          toast.error("❗️  Une erreur est survenue durant l'ajout du suivi.");
        }
      } else {
        createProfileFollow(newData);
      }
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col overflow-y-auto">
      <div className="flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Ajouter un suivi</h3>
      </div>
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formDriver}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Document</label>
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFile ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFile ? selectedFile.name : ' Cliquez pour ajouter un Document'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFile ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgError ? 'Veuillez sélectionner une revision' : ''}</p>
        </div>
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un suivi"
          style="bg-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      <Modal
        title="Nouveau Suivi enregistré"
        text="Nous avons bien enregistré votre suivi."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddFollow;

