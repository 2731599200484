import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './css/datePickers.css';
import { twMerge } from 'tailwind-merge';
import iconArrow from '../../assets/images/svg/down-arrow.svg';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const DatePicker_month = ({ selected = new Date(), minDate = undefined, onChange, styleInput = '' }) => {
  const [myDate, setmyDate] = useState(selected);

  const CustomInput = ({ value, onClick }) => (
    <button
      className={twMerge(`inputCt ${styleInput}`)}
      onClick={onClick}
    >
      {value}
      <img
        className="imgInput"
        src={iconArrow}
        alt="icon"
      />
    </button>
  );

  const MyContainer = ({ className, children }) => {
    return (
      <div className="boxCalendar">
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const handleChangeDate = (date) => {
    setmyDate(date);
    onChange(date);
  };

  useEffect(() => {
    setmyDate(selected);
  }, [selected]);

  return (
    <>
      <div className="superBox">
        <DatePicker
          selected={myDate}
          minDate={minDate}
          onChange={handleChangeDate}
          calendarContainer={MyContainer}
          dateFormat="MMMM"
          locale="fr"
          customInput={<CustomInput />}
          showMonthYearPicker
        />
      </div>
    </>
  );
};

export default DatePicker_month;

