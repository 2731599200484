/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { dateFormat, jours, mois, whatMorning, requestFormatDate } from '../../tools/date';
import More  from '../../../assets/images/svg/more.svg';
import HoverStatuts from '../HoverStatuts';

//import img
import ArrowImg from '../../../assets/images/svg/arrow.svg';

//ref planning stucture
const refManager = () => [
  { id: 0, tasks: [] },
  { id: 1, tasks: [] },
  { id: 2, tasks: [] },
  { id: 3, tasks: [] },
  { id: 4, tasks: [] },
  { id: 5, tasks: [] },
  { id: 6, tasks: [] },
];


const Plannings = () => {
  const navigate = useNavigate();
  //  🔘🔘  items Task jsx 🔘🔘
  const TaskCard = ({ item, isOver, overing }) => {
    const [changeDriver, setchangeDriver] = useState(true);
    let plDate = new Date(item?.datePlanned);
    const setpcolor = () => {
      if (item?.statut === 'done') {
        return 'border-statut-step-3';
      }
      if (plDate.getTime() < Date.now() - 21600000) {
        return 'border-statut-step-0';
      }
      if (item?.driver) {
        return 'border-statut-step-2';
      } else {
        return 'border-statut-step-1';
      }
    };
    return (
      <div
        className={`w-[240px] px-2 py-2 ${isOver === item.id && isOver !== taskMoveData.dragTask.id && 'pt-40'}`}
        onDragOver={(e) => {
          e.preventDefault();
          overing(item.id);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          overing(null);
        }}
        onDrop={() => (taskMoveData.dropTask = item)}
      >
        <div
          className={`bg-white rounded-lg ${
            item.statut !== 'done' && 'cursor-grab active:cursor-grabbing'
          } p-2 border-b-[7px] ${setpcolor()}`}
          
          draggable={item.statut === 'done' ? false : true}
          onDragStart={() => {
            taskMoveData.dragTask = item;
            taskMoveData.dropDay = undefined;
            taskMoveData.dropTask = undefined;
            taskMoveData.direction = undefined;
          }}
        >
          <div className={`bg-white  rounded-lg text-black w-auto select-none flex flex-col`}>
          <div className="flex items-center justify-between">
            <span className="p-1 px-2 text-white rounded-md bg-red-admin w-fit">N° {item.numero}</span>
            <span className="w-1/6 p-1 px-2"
              onClick={() => {
                navigate(`/orders/edit-order/${item.id}`);
              }}
            ><img src={More}></img></span>
          </div>
            
            <div className="flex items-center justify-between">
              {item.client.company}
              {item?.driver && changeDriver === false &&  (
                <div className="cursor-pointer select-none hoverInitial-top" onClick={setchangeDriver(true)}>
                  {item?.driver?.firstName[0]}
                  {item?.driver?.lastName[0]}
                  <span >{`${item?.driver?.firstName || ''} ${item?.driver?.lastName || ''}`}</span>
                </div>
              )}
              {changeDriver===true  && (
                <select
                  className="w-1/3 h-12 pl-3 pr-2 mt-3 font-medium border rounded-lg text-blue-admin md:mt-0 inputFocus bg-white-admin"
                  onChange={(e) => {
                    const value = e.target.value;
                    setchangeDriver(false);
                    updateDriverOrder({ id: item.id, driverOrderId: value });
                  }}  
                >
                  <option value="item?.driver.id">{item?.driver?.firstName[0]}{item?.driver?.lastName[0]}</option>
                  {drivers.map((driver) => (
                    <option
                      key={driver.id}
                      value={driver.id}
                    >
                      {driver.fullName}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //  🌱🌱 init store(services)   🌱🌱
  const getListOrderByDate = useStoreActions((actions) => actions.middleware.OrderMiddleware.getListOrderByDate);
  const updateMoveOrder = useStoreActions((actions) => actions.middleware.OrderMiddleware.updateMoveOrder);
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);
  const sendNotificationPush = useStoreActions((actions) => actions.middleware.Notification.sendNotificationPush);
  const getListOrderByDateByDriver = useStoreActions(
    (actions) => actions.middleware.OrderMiddleware.getListOrderByDateByDriver
  );
  const createNotif = useStoreActions((actions) => actions.middleware.Notification.createNotif);
  const updateDriverOrder = useStoreActions((actions) => actions.middleware.OrderMiddleware.updateDriverOrder);

  //  🌱🌱  Ref  🌱🌱
  const taskMoveData = useRef({}).current; // donnée du drag and drop
  const overBtn = useRef(false);
  const overBtnTimer = useRef(null);
  const selectDriver = useRef('');
  const orderSelected = useRef(null);

  //  🌱🌱  state  🌱🌱
  
  const [curentMorning, setCurentMorning] = useState({ Morning: [] }); // la semaine affiché
  const [MorningNumber, setMorningNumber] = useState(0); // le numero de la semain, O est la semaine en cours
  const [myDay, setMyday] = useState(null); // le numero du jour actuel entre 0-6 => lundi-dimanche
  const [planning, setplanning] = useState(refManager); // données complete du planing sur une semaine
  const [drivers, setDrivers] = useState([]);
  const [cardOver, setCardOver] = useState(null);

  //  🌱🌱 Request call order day  🌱🌱
  const callListOrderDay = async (data) => {
    try {
      const res = await getListOrderByDate(data);
      if (res?.length) {
        const myIndex = curentMorning.Morning.findIndex(
          (day) => dateFormat(day.date).full === dateFormat(res[0].datePlanned).full
        );
        let _Planning = [...planning];
        _Planning[myIndex].tasks = res;
        setplanning(_Planning);
      }
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des commandes du jour');
    }
  };

  //  🌱🌱 Request call order by driver  🌱🌱
  const callListOrderDayByDriver = async (data) => {
    try {
      const res = await getListOrderByDateByDriver(data);
      if (res?.length) {
        const myIndex = curentMorning.Morning.findIndex(
          (day) => dateFormat(day.date).full === dateFormat(res[0].datePlanned).full
        );
        let _Planning = [...planning];
        _Planning[myIndex].tasks = res;
        setplanning(_Planning);
      }
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des commandes du driver');
    }
  };

  //  🌱🌱 Request update order  🌱🌱
  const updateMoveOrderDay = async (data) => {
    try {
      await updateMoveOrder(data);
      sendDriverPush();
      addDate(data.datePlanned);
    } catch (error) {
      console.log('error: ', error);
      toast.error('❗️ Une erreur est survenue lors du chargement dans le planning des commandes');
    }
  };

  //  🌱🌱 Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      // Sort by name
      res.sort((a, b) => {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      });
      setDrivers(
        res.map((truck) => {
          return { ...truck, fullName: `${truck.firstName} ${truck.lastName}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱 Request create notification (history)  🌱🌱
  const callcreateNotif = async (data) => {
    try {
      await createNotif(data);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la mise à jours de l'historique");
    }
  };

  //  🌱🌱  send notification push   🌱🌱
  const sendDriverPush = () => {
    console.log('current.driver: ', orderSelected.current.driver);
    if(orderSelected.current.driver!==null){
      if (!!orderSelected.current.driver.tokenFcm ) {
        const newData = {
          title: 'Ozier Transport',
          message: `La commande n°${orderSelected.current.numero} prévu le ${
            dateFormat(orderSelected.current.datePlanned).stringFull
          } est déplacer au ${dateFormat(orderSelected.current.newDate).stringFull}`,
          tokenFcm: orderSelected.current.driver.tokenFcm,
        };
        sendNotificationPush(newData);
        callcreateNotif({
          ...newData,
          idUser: orderSelected.current.driver.id,
          date: Date.now(),
          title: 'Commande',
        });
      }
    }
    orderSelected.current = null;
  };

  //  🌱🌱  function add date (generate Morning)  🌱🌱
  const addDate = (valueDate) => {
    const newDateMorning = whatMorning(valueDate);
    setCurentMorning(newDateMorning);
    setplanning(refManager);
    if (myDay === null) {
      setMyday(newDateMorning.curentDayIndex);
    }
  };

  //  🌱🌱  next Morning  🌱🌱
  const nextMorning = () => {
    const oneDay = 86400000; // milliseconde
    const currentMonday = curentMorning.Morning[0].date.getTime();
    let next = currentMonday + oneDay * 7;
    setMorningNumber((cureent) => cureent + 1);
    addDate(next);
  };

  //  🌱🌱  Previous Morning  🌱🌱
  const previousMorning = () => {
    const oneDay = 86400000; // milliseconde
    const currentMonday = curentMorning.Morning[0].date.getTime();
    let previous = currentMonday - oneDay * 7;
    setMorningNumber((cureent) => cureent - 1);
    addDate(previous);
  };

  //  🌱🌱  function Action Drop  🌱🌱
  const dropEnd = () => {
    taskMoveData.dragDay = taskMoveData.dragTask.day;
    if (!taskMoveData.direction && !taskMoveData.dropTask) {
      taskMoveData.direction = 'bottom';
    }
    setCardOver(null);

    // console.log('taskMoveData: ', taskMoveData); 🔸

    //security
    if (taskMoveData.dragTask.id === taskMoveData?.dropTask?.id) return;
    // if (taskMoveData.dragDay === taskMoveData.dropDay) return;

    let _Planning = [...planning];

    //delete task
    let _tasksListDrag = _Planning[taskMoveData.dragDay].tasks;
    _Planning[taskMoveData.dragDay].tasks = _tasksListDrag.filter((task) => task.id !== taskMoveData.dragTask.id);

    //add task
    let _tasksListDrop = _Planning[taskMoveData.dropDay].tasks;
    _Planning[taskMoveData.dropDay].tasks = [..._tasksListDrop, taskMoveData.dragTask];

    setplanning(_Planning);
    orderSelected.current = { ...taskMoveData.dragTask, newDate: curentMorning.Morning[taskMoveData.dropDay].date };
    updateMoveOrderDay({ id: taskMoveData.dragTask.id, datePlanned: curentMorning.Morning[taskMoveData.dropDay].date }); // request
  };

  const handleChangeSelectDriver = (e) => {
    const value = e.target.value;
    selectDriver.current = value;
    addDate(curentMorning.Morning[1].date);
  };

  //  🌱🌱  first effect  🌱🌱
  useEffect(() => {
    callListDriver();
    addDate(Date.now());
  }, []);

  //  🌱🌱  watcher MorningNumber (refresh current Morning)  🌱🌱
  useEffect(() => {
    if (MorningNumber === 0) {
      setplanning(refManager);
    }
  }, [MorningNumber]);

  //  🌱🌱  watcher curentMorning  🌱🌱
  useEffect(() => {
    if (curentMorning.Morning.length) {
      curentMorning.Morning.forEach((cMorgning) => {
        if (selectDriver.current) {
          callListOrderDayByDriver({ ...requestFormatDate(cMorgning.date), idDriver: selectDriver.current });
        } else {
          callListOrderDay(requestFormatDate(cMorgning.date));
        }
      });
    }
  }, [curentMorning]);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full px-4 pt-4 md:pt-14 md:px-9">
      <h3 className="font-bold text-xl border-b border-gray-admin pb-[2.125rem]">Planning des commandes</h3>
      <div>
        <HoverStatuts text="Statut ☉" />
      </div>
      <div className="flex flex-col items-center justify-between pt-4 md:flex-row md:space-x-3 ">
        <div className="flex flex-col md:flex-row">
          {curentMorning?.Morning[0] && (
            <p>
              Semaine du {dateFormat(curentMorning?.Morning[0].date).day} au{' '}
              {dateFormat(curentMorning?.Morning[6].date).day} {mois[curentMorning?.Morning[6].date.getMonth()]}{' '}
              {curentMorning?.Morning[6].date.getFullYear()}
            </p>
          )}
          <div className="flex justify-center pt-3 md:pt-0">
            <img
              alt="arrow"
              src={ArrowImg}
              className="cursor-pointer w-8 font-black transform  hover:scale-[120%] px-1 select-none"
              onClick={previousMorning}
              onDragOver={(e) => {
                e.preventDefault();
                if (overBtn.current === false) {
                  overBtn.current = true;
                  overBtnTimer.current = setTimeout(() => {
                    previousMorning();
                    if ((overBtn.current = true)) {
                      overBtn.current = false;
                    }
                  }, 1000);
                }
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                overBtn.current = false;
                clearTimeout(overBtnTimer.current);
              }}
            />
            <img
              alt="arrow"
              src={ArrowImg}
              className="cursor-pointer w-8 font-black transform  hover:scale-[120%] px-1 select-none rotate-180"
              onClick={nextMorning}
              onDragOver={(e) => {
                e.preventDefault();
                if (overBtn.current === false) {
                  overBtn.current = true;
                  overBtnTimer.current = setTimeout(() => {
                    nextMorning();
                    if ((overBtn.current = true)) {
                      overBtn.current = false;
                    }
                  }, 1000);
                }
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                overBtn.current = false;
                clearTimeout(overBtnTimer.current);
              }}
            />
          </div>
        </div>
        <select
          onChange={handleChangeSelectDriver}
          className="w-full h-12 pl-3 pr-2 mt-3 font-medium border rounded-lg text-blue-admin md:mt-0 inputFocus md:w-fit bg-white-admin"
        >
          <option value=""> Tous les chauffeurs</option>
          {drivers.map((driver) => (
            <option
              key={driver.id}
              value={driver.id}
            >
              {driver.fullName}
            </option>
          ))}
        </select>
      </div>
      <section className="flex flex-grow w-full mt-4 space-x-4 overflow-x-auto pb-28 md:pb-14">
        <div className="flex w-1/6 space-x-4 mdr h-fit md:mr-4">
          {curentMorning?.Morning?.map((c_day, index) => (
            <div
              key={index}
              className={`bg-gray-sidebar pb-11 mt-5 rounded-xl w-fit border ${
                MorningNumber === 0 && myDay === index ? 'border-blue-admin border-2' : 'border-gray-sidebar'
              } `}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={() => {
                taskMoveData.dropDay = index;
                dropEnd();
              }}
            >
              <p
                className="py-4 text-sm font-bold text-center"
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={() => {
                  taskMoveData.direction = 'top';
                }}
              >
                {jours[index]} {dateFormat(c_day?.date).day} {mois[parseInt(dateFormat(c_day?.date).month) - 1]}{' '}
                {dateFormat(c_day?.date).year}
              </p>
              <div className="w-[240px] ">
                {!!planning.length &&
                  planning[index]?.tasks?.map((taskIndex) => (
                    <TaskCard
                      key={taskIndex.id}
                      item={{ ...taskIndex, day: index }}
                      overing={setCardOver}
                      isOver={cardOver}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Plannings;

