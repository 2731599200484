import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import SecondaryButton from '../SecondaryButton';
import PrimaryButton from '../PrimaryButton';
import Modal from '../Modal';

//import icons
import Check from '../../../assets/images/svg/check.svg';

//Data form
const formCreateClient = [
  {
    label: 'Nom de la société',
    inputType: 'text',
    valueName: 'company',
    placeholder: 'Nom de la société',
    errorMsg: 'Veuillez entrer le nom de la société',
    required: true,
    halfWidth: true,
  },
  {
    label: 'N° de Siret',
    inputType: 'number',
    valueName: 'siret',
    placeholder: 'Entrer votre numéro',
    errorMsg: 'Veuillez entrer un n° de siret valide',
    regex: /^[0-9]{14}$/,
    halfWidth: true,
  },
  {
    label: 'Prénom',
    inputType: 'text',
    valueName: 'firstName',
    placeholder: 'Prénom du client',
    errorMsg: 'Veuillez entrer le prénom du client',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Nom',
    inputType: 'text',
    valueName: 'lastName',
    placeholder: 'Nom du client',
    errorMsg: 'Veuillez entrer le nom du client',
    required: true,
    halfWidth: true,
  },
  {
    label: 'E-mail',
    inputType: 'mail',
    valueName: 'mail',
    placeholder: 'Mail du client',
    errorMsg: 'Veuillez entrer une adresse mail valide',
    required: true,
    halfWidth: true,
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  },
  {
    label: 'Téléphone',
    inputType: 'numero',
    valueName: 'phone',
    placeholder: '+596696000000',
    errorMsg: 'Veuillez saisir un numéro de téléphone au format international +00...',
    required: true,
    halfWidth: true,
    regex: /^([+])([0-9]{11,12})$/,
  },
];

const AddClients = () => {
  //  🌱🌱  Init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 init store(services)   🌱🌱
  const createClient = useStoreActions((actions) => actions.middleware.ClientsMiddleware.createClient);
  const createClientUser = useStoreActions((action) => action.middleware.Cognito.createClientUser);

  // 🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);

  //  🌱🌱  test function FormCustom  🌱🌱
  const submitForm = (value) => {
    if (value.status) {
      createCognitoClient(value.values);
    }
  };

  //  🌱🌱 Request create client  🌱🌱
  const createProfileClient = async (data) => {
    try {
      const res = await createClient(data);
      setShowModal(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau client, veuillez réessayer.");
    }
  };

  //  🌱🌱 Request create client Cognito  🌱🌱
  const createCognitoClient = async (data) => {
    try {
      const res = await createClientUser(data.phone);
      const newData = {
        ...data,
        id_cognito: res.userSub,
      };
      createProfileClient(newData);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouvel administrateur, veuillez réessayer.");
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Ajouter un nouveau client</h3>
      </div>
      <div className="max-w-[1100px] flex-grow mx-4 md:mx-9">
        <FormCustom
          data={formCreateClient}
          resultSubmit={submitForm}
          ref={submitRef}
        />
      </div>
      {/* Div 2 boutons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5 md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <PrimaryButton
          title="Créer un nouveau client"
          style="bg-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      <Modal
        title="Nouveau client enregistré"
        text="Nous avons bien enregistré votre nouveau Client. Vous le retrouverez dans la liste des clients et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddClients;

