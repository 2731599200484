/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useEffect } from 'react';
import Routes from './Routes';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Provider = () => {
  console.log('ENV:', process.env.REACT_APP_ENV);

  //  🌱🌱 init store(services)   🌱🌱
  const connected = useStoreState((state) => state.route.connected);
  const userAdmin = useStoreState((state) => state.userData.userAdmin);
  const isAuth = useStoreActions((action) => action.middleware.Cognito.isAuth);
  const getAdminCognito = useStoreActions((actions) => actions.middleware.AdminMiddleware.getAdminCognito);
  const setConnected = useStoreActions((actions) => actions.route.setConnected);
  const getListOrderNotFinish = useStoreActions((actions) => actions.middleware.OrderMiddleware.getListOrderNotFinish);
  const getListClients = useStoreActions((actions) => actions.middleware.ClientsMiddleware.getListClients);
  const setWarnningOrder = useStoreActions((actions) => actions.global.setWarnningOrder);
  const setWarnningNewClient = useStoreActions((actions) => actions.global.setWarnningNewClient);

  //  🌱🌱  verification auth user  🌱🌱
  const verifAuth = async () => {
    try {
      const res = await isAuth();
      getAdminCognito(res.username);
      setConnected(true);
    } catch (error) {
      setConnected(false);
      toast.info('Bienvenue');
    }
  };

  //  🌱🌱  post first effect    🌱🌱
  useLayoutEffect(() => {
    verifAuth();
  }, []);

  // 🌱🌱 --------------------- 🌱🌱
  //  🌱🌱 Request call List order  🌱🌱
  const callListOrderNotFinish = async () => {
    try {
      const res = await getListOrderNotFinish();
      const action = res.some(
        (el) => el?.driver?.id === undefined || new Date(el?.datePlanned).getTime() < Date.now() - 21600000
      );
      if (action) {
        setWarnningOrder(true);
      }
    } catch (error) {
      toast.error('❗️ #0 Une erreur est survenue lors du chargement de la liste des commandes');
    }
  };

  //  🌱🌱 Request call List Clients  🌱🌱
  const callListClients = async () => {
    try {
      const res = await getListClients();
      const action = res.some((el) => el?.confirmed !== true);
      if (action) {
        setWarnningNewClient(true);
      }
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des clients');
    }
  };

  // 🌱🌱 effect watch is Auth 🌱🌱
  useEffect(() => {
    if (userAdmin?.id) {
      callListOrderNotFinish();
      callListClients();
    }
  }, [userAdmin]);

  //  🎉🎉  Render  🎉🎉
  return (
    <>
      {connected !== null && <Routes />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Provider;
