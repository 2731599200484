import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';
import { timeToMs, dateFormat } from '../../tools/date';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import Eye from '../../../assets/images/svg/eye.svg';

const ListDrivers = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);

  //  🌱🌱  Etats (données)  🌱🌱
  const [drivers, setDrivers] = useState([]);

  //  🌱🌱 Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      setDrivers(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListDriver();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 px-4 md:px-9">
      {/* div Title = button */}
      <div className=" flex flex-col space-y-4 items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row md:space-y-0">
        <h3 className="font-bold text-xl ">Liste des chauffeurs</h3>
        <SecondaryButton
          title=" + Ajouter un nouveau chauffeur"
          link={'add'}
        />
      </div>

      {/* Table list Drivers */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Prénom</th>
              <th>Nom </th>
              <th>Téléphone</th>
              <th>Documents</th>
              <th></th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {drivers.length ? (
              drivers.map((driver) => {
                const datePermis = new Date(driver.datePermis);
                const dateVm = new Date(driver.dateVm);
                return (
                  <tr key={driver.id}>
                    <td>{driver.firstName}</td>
                    <td>{driver.lastName}</td>
                    <td>{driver.phone}</td>
                    <td>
                      <Link
                        to={`preview/${driver.id}`}
                        className="flex items-center space-x-2 border border-gray-admin w-20 py-1 px-2 rounded-md cursor-pointer"
                      >
                        <p>Ouvrir</p>
                        <img
                          src={Eye}
                          alt="icone tick circle"
                        />
                      </Link>
                    </td>
                    <td>
                      {datePermis.getTime() < Date.now() + timeToMs.mounth && (
                        <p className="text-red-error text-xs">
                          - Permis Valide jusqu' au {dateFormat(datePermis, '/').full}
                        </p>
                      )}
                      {dateVm.getTime() < Date.now() + timeToMs.mounth && (
                        <p className="text-red-error text-xs">
                          - Visite Médical valide jusqu' au {dateFormat(dateVm, '/').full}
                        </p>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`edit/${driver.id}`}
                        className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                      >
                        <img
                          src={More}
                          alt="more icon"
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center text-lg"
                >
                  La liste des chauffeurs est vide. Veuillez ajouter un chauffeur.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListDrivers;

