import React from 'react';
import '../css/HoverStatutsAnomalie.css';

const HoverStatutsAnomalie = ({ text }) => {
  return (
    <>
      <div className="hoverText">{text}</div>
      <div className="boxHover">
        <div className="boxInfos">
          <ul>
            <li>
              <div>
                <span style={{ backgroundColor: '#5BB264' }} /> Anomalie réglée
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#5354E5' }} /> Anomalie en cours de gestion
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#ED4337' }} /> Anomalie non réglée
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HoverStatutsAnomalie;

