import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';

const ListAdministrators = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListAdmin = useStoreActions((actions) => actions.middleware.AdminMiddleware.getListAdmin);

  //  🌱🌱  Etats (données)  🌱🌱
  const [admins, setAdmins] = useState([]);

  //  🌱🌱 Request call List Admins  🌱🌱
  const callListAdmin = async () => {
    try {
      const res = await getListAdmin();
      setAdmins(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des administrateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListAdmin();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 px-4 md:px-9">
      {/* div Title = button */}
      <div className=" flex flex-col space-y-4 items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row md:space-y-0">
        <h3 className="font-bold text-xl ">Liste des administrateurs</h3>
        <SecondaryButton
          title=" + Ajouter un nouvel administrateur"
          link={'add'}
        />
      </div>
      {/* Table liste clients */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Prénom</th>
              <th>Nom </th>
              <th>Adresse mail</th>
              <th>Statut</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.id}>
                <td>{admin.firstName}</td>
                <td>{admin.lastName}</td>
                <td>{admin.mail}</td>
                <td>{admin.statut}</td>
                <td>
                  <Link
                    to={`edit/${admin.id}`}
                    className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                  >
                    <img
                      src={More}
                      alt="more icon"
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListAdministrators;

