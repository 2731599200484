import { thunk } from 'easy-peasy';
import { Amplify, Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { awsAuthAdmin, awsAuthClient, awsAuthDriver } from './config/awsCognito';
import awsIAMConfigure from './config/awsIAMConfigure';

import { getRandomString } from '../../shared/tools/file';

const middleware = {
  //✨✨===========================================✨✨
  //            CONNEXTION USER AWS COGNITO ADMIN
  //✨✨===========================================✨✨
  loginAws: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthAdmin);
    try {
      const user = await Auth.signIn({
        username: payload?.mail,
        password: payload?.password,
      });
      getStoreActions().userData.setCognitoData(user);
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨===========================================✨✨
  //            VERIF IS AUTH COGNITO ADMIN
  //✨✨===========================================✨✨
  isAuth: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    Amplify.configure(awsAuthAdmin);
    try {
      const user = await Auth.currentAuthenticatedUser();
      getStoreActions().userData.setCognitoData(user);
      return user;
    } catch (error) {
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            CREATE USER AWS COGNITO ADMIN
  //✨✨=============================================✨✨
  createAdminUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthAdmin);
    try {
      const user = await Auth.signUp({
        username: payload?.mail,
        password: payload?.password,
      });
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            LOGOUT USER COGNITO ADMIN
  //✨✨=============================================✨✨
  logOutAdminUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    Amplify.configure(awsAuthAdmin);
    try {
      await Auth.signOut();
      getStoreActions().route.setConnected(false);
    } catch (error) {
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            SET PASSWORD AWS SDK ADMIN
  //✨✨=============================================✨✨
  setAdminPassword: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Password: payload?.password,
      Permanent: true,
      Username: payload?.id_cognito,
      UserPoolId: awsAuthAdmin.userPoolId,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminSetUserPassword(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            SET EMAIL AWS SDK ADMIN
  //✨✨=============================================✨✨
  setAdminEmail: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'email',
          Value: payload?.mail,
        },
      ],
      Username: payload?.id_cognito,
      UserPoolId: awsAuthAdmin.userPoolId,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            DELETE USER AWS SDK ADMIN COGNITO
  //✨✨=============================================✨✨
  deleteAdminUserCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Username: payload?.id_cognito,
      UserPoolId: awsAuthAdmin.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminDeleteUser(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            CREATE USER AWS COGNITO CLIENT
  //✨✨=============================================✨✨
  createClientUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthClient);
    try {
      const user = await Auth.signUp({
        username: payload,
        password: `-@-N${getRandomString(30)}`,
        attributes: {
          phone_number: payload,
        },
      });
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            SET PHONE NUMBER AWS SDK CLIENT
  //✨✨=============================================✨✨
  setClientPhone: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'phone_number',
          Value: payload?.phone,
        },
        {
          Name: 'phone_number_verified',
          Value: 'true',
        },
      ],
      Username: payload?.id_cognito,
      UserPoolId: awsAuthClient.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            DELETE USER AWS SDK CLIENT COGNITO
  //✨✨=============================================✨✨
  deleteClientUserCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Username: payload?.id_cognito,
      UserPoolId: awsAuthClient.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminDeleteUser(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            CREATE USER AWS COGNITO DRIVER
  //✨✨=============================================✨✨
  createDriverUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthDriver);
    try {
      const user = await Auth.signUp({
        username: payload,
        password: `-N-@${getRandomString(30)}`,
        attributes: {
          phone_number: payload,
        },
      });
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            SET PHONE NUMBER AWS SDK DRIVER
  //✨✨=============================================✨✨
  setDriverPhone: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'phone_number',
          Value: payload?.phone,
        },
        {
          Name: 'phone_number_verified',
          Value: 'true',
        },
      ],
      Username: payload?.id_cognito,
      UserPoolId: awsAuthDriver.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            DELETE USER AWS SDK DRIVER COGNITO
  //✨✨=============================================✨✨
  deleteDriverUserCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Username: payload?.id_cognito,
      UserPoolId: awsAuthDriver.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminDeleteUser(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),
};

export default middleware;

