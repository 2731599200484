import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Table from '../../Table';
import Modal from '../../Modal';
import { dateFormat } from '../../../tools/date';

// Import icons
import ReceiveSquare from '../../../../assets/images/svg/receive-square.svg';
import DeleteIcon from '../../../../assets/images/svg/delete.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

//env
const urlS3 = process.env.REACT_APP_URL_S3_OBJECT;

const ListFollows = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getListFollow = useStoreActions((actions) => actions.middleware.FollowMiddleware.getListFollow);
  const deleteFollow = useStoreActions((actions) => actions.middleware.FollowMiddleware.deleteFollow);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listFollow, setListFollow] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [ctSelected, setCtSelected] = useState({});

  //  🌱🌱 Request call List follow  🌱🌱
  const callListFollow = async () => {
    try {
      const res = await getListFollow(idTruck);
      setListFollow(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste de suivi');
    }
  };

  //  🌱🌱 Request delete follow  🌱🌱
  const deleteProfileFollow = async () => {
    try {
      const res = await deleteFollow(ctSelected?.id);
      callListFollow();
      setShowModalDelete(false);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression du suivi');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListFollow();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-16 flex flex-col h-5/6 justify-between">
      {/* div table Control Technique */}
      <div className="px-4 md:px-9">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Titre</th>
              <th>description</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listFollow.length ? (
              listFollow.map((follow) => {
                const dateNow = dateFormat(follow?.date, '-').full;
                return (
                  <tr key={follow?.id}>
                    <td>{dateNow}</td>
                    <td>{follow?.title}</td>
                    <td>{follow?.description}</td>
                    <td>
                      <div className="flex justify-end gap-x-2">
                        <button
                          className=" border border-separate p-3 rounded-xl"
                          onClick={() => {
                            setCtSelected(follow);
                            setShowModalDelete(true);
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt="more icon"
                          />
                        </button>
                        {follow?.document && (
                          <a
                            href={`${urlS3}${follow?.document}`}
                            className="border border-separate p-3 rounded-xl"
                          >
                            <img
                              src={ReceiveSquare}
                              alt="more icon"
                            />
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-center text-lg"
                >
                  Ajouter un suivi
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className=" gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
      <Modal
        title="Supprimer le suivi"
        text="Êtes-vous sûre de vouloir supprimer le suivi ? 
        Cette action est définitive."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileFollow();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default ListFollows;

