import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const MaterialsMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST MATERIALS
  //✨✨==========================================✨✨

  getListMaterials: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listMarchandises(filter: {archived: {eq: false}}) {
          items {
            id
            name
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listMarchandises.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET MATERIAL
  //✨✨==========================================✨✨

  getMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        getMarchandise(id: "${payload}") {
          id
          name
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getMarchandise;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE MATERIALS
  //✨✨==========================================✨✨

  createMaterials: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createMarchandise(input: {
            archived: false, 
            name: "${payload?.name}", 
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createMarchandise;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE MATERIALS
  //✨✨==========================================✨✨

  updateMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateMarchandise(input: {
            id: "${payload?.id}", 
            name: "${payload?.name}", 
        })
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateMarchandise;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            DELETE MATERIALS (UPDATE ARCHIVED)
  //✨✨============================================✨✨

  deleteMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateMarchandise(input: {
            id: "${payload}", 
            archived: true
        })
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateMarchandise;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default MaterialsMiddleware;

