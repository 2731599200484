import React from 'react';

//import page
import Plannings from '../shared/components/planning/Plannings';

const Planning = () => {
  return <Plannings />;
};

export default Planning;

