import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// import Component
import SecondaryButton from '../components/SecondaryButton';

const NavigationSettings = () => {
  // init location
  let { pathname } = useLocation();
  return (
    <section className="px-5 md:pt-14 md:px-9">
      <div className=" flex flex-col gap-y-6 items center justify-between border-b border-gray-admin pb-7 pt-11 md:pt-0 mb-4 md:flex-row md:pb-[2.125rem] md:gap-y-3">
        <h3 className="font-bold text-xl ">Paramètres</h3>
        <div>
          {pathname.includes('materials') && (
            <SecondaryButton
              title={'+ Ajouter un nouveau matériau'}
              link={'/settings/add-materials'}
            />
          )}

          {pathname.includes('list-prices-day') && (
            <SecondaryButton
              title={'+ Ajouter un nouveau prix à la journée'}
              link={'/settings/add-prices-day'}
            />
          )}
        </div>
      </div>
      <div className="flex md:flex-row md:space-x-4 flex-col space-y-4 md:space-y-0">
        <NavLink
          to="materials"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Matériaux
        </NavLink>
        <NavLink
          to="list-prices-day"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Prix / Journée
        </NavLink>
      </div>
    </section>
  );
};

export default NavigationSettings;

