import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

//import env
const urlServerFirebase = process.env.REACT_APP_FIREBASE_URL_SERVER;
const apiKeyFirebase = process.env.REACT_APP_FIREBASE_API_KEY;

const middleware = {
  //✨✨===============================================✨✨
  //            NOTIFICATION PUSH REQUEST FIRE-BASE
  //✨✨===============================================✨✨
  sendNotificationPush: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    const myBody = {
      notification: {
        title: payload?.title,
        body: payload?.message,
      },
      to: payload?.tokenFcm,
    };

    try {
      const response = await fetch(urlServerFirebase, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: apiKeyFirebase,
        },
        body: JSON.stringify(myBody),
      });
      const data = response.json();
    } catch (error) {
      console.log('error Notification:', error);
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST NOTIFICATIONS BY USER
  //✨✨============================================✨✨
  getListNotif: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listNotifications(filter: {idUser: {eq: "${payload}"}}) {
        items {
          id
          date
          title
          message
          seen
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listNotifications.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            CREATE NOTIFICATIONS
  //✨✨============================================✨✨
  createNotif: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createNotification(input: {
        idUser: "${payload.idUser}",
        message: "${payload.message}",
        title: "${payload.title}",
        date: "${payload.date}"
        seen: false,
      })
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createNotification;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default middleware;

