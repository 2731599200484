import { total, tableColors } from '../../tools/chartJs';
import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChartJs = ({ data }) => {
  //init state
  const [myData, setMyData] = useState([]);
  const [totalMaterialPrice] = useState(total(data, 'total2'));

  const dataDoughnut = {
    labels: myData.map((el) => el.mc),
    datasets: [
      {
        label: 'Marchandises',
        test: ['1', '2', '3'],
        data: myData.map((el) => el.total2),
        backgroundColor: myData.map((el) => el.color),
        borderColor: 'transparent',
        hoverOffset: 4,
      },
    ],
  };

  useEffect(() => {
    if (data.length > 5) {
      setMyData(
        data.slice(0, 5).map((el, index) => {
          return { ...el, color: tableColors[index] };
        })
      );
    } else {
      setMyData(
        data.map((el, index) => {
          return { ...el, color: tableColors[index] };
        })
      );
    }
  }, [data]);

  const optionDoughnut = {
    plugins: {
      tooltip: {
        //Permet de rajouter des info supllémentaires dans l'info bulle du 🍩
        callbacks: {
          label: (context) => {
            const datasetIndex = context.datasetIndex;
            const dataPoint = context.dataIndex;
            return `${context.label}: ${context.dataset.data[dataPoint]} Tonne - ${myData[dataPoint].total}€ `;
          },
        },
      },
    },
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = 'bold 16px font-mulish';
      ctx.fillStyle = '#C55F4EB2';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${totalMaterialPrice} T `, chart.getDatasetMeta(0).data[0]?.x, chart.getDatasetMeta(0).data[0]?.y);
    },
  };

  return (
    <div>
      <div className="flex flex-col">
        <Doughnut
          data={dataDoughnut}
          options={optionDoughnut}
          plugins={[textCenter]}
        />
      </div>
    </div>
  );
};

export default DonutChartJs;

