import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const ProblemsMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST PROMBLEMS
  //✨✨==========================================✨✨
  getListProblems: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listProblems {
        items {
          id
          description
          status
          date
          truck {
            model
            registration
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('query',query.data?.listProblems.items)
      const myData = query.data?.listProblems.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET PROBLEMS
  //✨✨==========================================✨✨
  getProblem: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getProblem(id: "${payload.id}") {
        id
        description
        date
        status
        truck {
          model
          registration
          year
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE STATUS PROBLEMS 
  //✨✨==========================================✨✨
  updateStautsProblem: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    console.log('payload',payload)
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateProblem(input: {
        id: "${payload.id}", 
        status: "${payload.status}"}) {
        id
        status
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE PROBLEMS
  //✨✨==========================================✨✨
  createProblem: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createProblem(input: {
        date: "${payload.date}", 
        description: "${payload.description}", 
        problemTruckId: "${payload.problemTruckId}", 
        status: "${payload.status}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default ProblemsMiddleware;

