import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const FuelMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST FUELS
  //✨✨==========================================✨✨

  getListFuels: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listFuels(filter: {truckFuelId: {eq: "${payload}"}}) {
        items {
          id
          quantity
          date
          truckFuelId
          startKm
          endKm
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listFuels.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  getListFuelsInMonth : thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listFuels(filter: { and: [{date: {contains: "${payload.year}"}},{date: {contains: "${payload.month}"}}]} ){
        items {
          date
          endKm
          id
          quantity
          startKm
          truckFuelId
          updatedAt
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listFuels.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  getListFuelsInYear : thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listFuels(filter: {date: {contains: "${payload.year}"}} ){
        items {
          date
          endKm
          id
          quantity
          startKm
          truckFuelId
          updatedAt
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listFuels.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),


  getListFuelsAll : thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
  // body request
  const graphqlQuery = `
  query MyQuery {
    listFuels{
      items {
        date
        endKm
        id
        quantity
        startKm
        truckFuelId
        updatedAt
      }
    }
  }
  `;
  getStoreActions().global.setActivity(true);
  try {
    const query = await API.graphql({
      query: graphqlQuery,
    });
    const myData = query.data?.listFuels.items;
    return myData;
  } finally {
    getStoreActions().global.setActivity(false);
  }
  }),
};

export default FuelMiddleware;

