/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Modal from '../../Modal';

//import icons
import Check from '../../../../assets/images/svg/check.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

const EditClient = () => {
  //  🌱🌱  Init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idClient } = useParams();

  //  🌱🌱 init store(services) 🌱🌱
  const getClient = useStoreActions((actions) => actions.middleware.ClientsMiddleware.getClient);
  const updateDataClient = useStoreActions((actions) => actions.middleware.ClientsMiddleware.updateDataClient);
  const deleteClient = useStoreActions((actions) => actions.middleware.ClientsMiddleware.deleteClient);
  const setClientPhone = useStoreActions((action) => action.middleware.Cognito.setClientPhone);
  const deleteClientUserCognito = useStoreActions((action) => action.middleware.Cognito.deleteClientUserCognito);

  //  🌱🌱  init state  🌱🌱
  const [client, setClient] = useState(null);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  Data form  🌱🌱
  const formEditClient = [
    {
      label: 'Nom de la société',
      inputType: 'text',
      valueName: 'company',
      placeholder: 'Nom de la société',
      errorMsg: 'Veuillez entrer le nom de la société',
      required: true,
      halfWidth: true,
      defaultValue: client?.company,
    },
    {
      label: 'N° de Siret',
      inputType: 'number',
      valueName: 'siret',
      placeholder: 'Entrer votre numéro',
      errorMsg: 'Veuillez entrer un n° de siret valide',
      regex: /^[0-9]{14}$/,
      halfWidth: true,
      defaultValue: client?.siret,
    },
    {
      label: 'Prénom',
      inputType: 'text',
      valueName: 'firstName',
      placeholder: 'Prénom du client',
      errorMsg: 'Veuillez entrer le prénom du client',
      required: true,
      halfWidth: true,
      defaultValue: client?.firstName,
    },
    {
      label: 'Nom',
      inputType: 'text',
      valueName: 'lastName',
      placeholder: 'Nom du client',
      errorMsg: 'Veuillez entrer le nom du client',
      required: true,
      halfWidth: true,
      defaultValue: client?.lastName,
    },
    {
      label: 'E-mail',
      inputType: 'mail',
      valueName: 'mail',
      placeholder: 'Mail du client',
      errorMsg: 'Veuillez entrer une adresse mail valide',
      required: true,
      halfWidth: true,
      // eslint-disable-next-line no-useless-escape
      regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      defaultValue: client?.mail,
    },
    {
      label: 'Téléphone',
      inputType: 'numero',
      valueName: 'phone',
      placeholder: '596696000000',
      errorMsg: 'Veuillez saisir un numéro de téléphone au format international +00...',
      required: true,
      halfWidth: true,
      regex: /^([+])([0-9]{11,12})$/,
      defaultValue: client?.phone,
    },
  ];

  //  🌱🌱  request data client  🌱🌱
  const callClients = async () => {
    try {
      const res = await getClient(idClient);
      setClient(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des donnée du client');
    }
  };

  //  🌱🌱  request update client  🌱🌱
  const updateProfileDataClient = async (data) => {
    try {
      await updateDataClient(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors des changement des donnée du client');
    }
  };

  //  🌱🌱  request set Phone client  🌱🌱
  const setPhoneClient = async (data) => {
    try {
      await setClientPhone(data);
      updateProfileDataClient(data);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors des changement des donnée du client');
    }
  };

  //  🌱🌱  request Delete client  🌱🌱
  const deleteProfileClient = async () => {
    try {
      await deleteClient(idClient);
      navigate('/clients');
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression du client');
    }
  };

  //  🌱🌱  request Delete client  🌱🌱
  const deleteCognitoClient = async () => {
    setShowModalDelete(false);
    try {
      await deleteClientUserCognito(client);
      deleteProfileClient();
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression du client');
    }
  };

  //  🌱🌱  HandleSubmit  🌱🌱
  const SubmitForm = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idClient,
        id_cognito: client.id_cognito,
      };
      if (client.phone !== newData.phone) {
        setPhoneClient(newData);
      } else {
        updateProfileDataClient(newData);
      }
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callClients();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full px-4 pt-16 md:px-9">
      {/* Formulaire client */}
      <div className="max-w-[1100px] flex-grow">
        {client?.id && (
          <FormCustom
            data={formEditClient}
            resultSubmit={SubmitForm}
            ref={submitRef}
          />
        )}
      </div>
      {/* Div des 3 boutons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer ce client"
            onClick={() => setShowModalDelete(true)}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      <Modal
        title="Modification enregistrée"
        text="Nous avons bien enregistré les modifications effectuées sur le dossier du client."
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>
      <Modal
        title="Supprimer un client"
        text="Êtes-vous sûre de vouloir supprimer ce profil ? 
        Cette action est définitive. Vous n’aurez plus accès à ce client"
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteCognitoClient();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditClient;

