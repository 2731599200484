import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import Table from '../../Table';

// Import icons
import More from '../../../../assets/images/svg/more.svg';

const ListAdress = () => {
  //  🌱🌱  init navigation  🌱🌱
  const { idClient } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getListAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.getListAddress);

  //  🌱🌱  Etats (données)  🌱🌱
  const [address, setAddress] = useState([]);

  //  🌱🌱 Request call List adresse  🌱🌱
  const callListAddress = async () => {
    try {
      const res = await getListAddress(idClient);
      setAddress(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des adresses');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListAddress();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 px-4 md:px-9">
      {/* div Title = button */}
      <div className=" flex flex-col space-y-4 items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row md:space-y-0">
        <h3 className="font-bold text-xl ">Liste des adresses</h3>
      </div>
      {/* Table liste clients */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Adresse</th>
              <th>Prix</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {address.map((oneAddress) => (
              <tr key={oneAddress.id}>
                <td>{oneAddress.address}</td>
                <td>{oneAddress.price} €</td>
                <td>
                  <Link
                    to={`/clients/edith-address/${oneAddress.id}`}
                    className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                  >
                    <img
                      src={More}
                      alt="more icon"
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListAdress;

