import React from 'react';
import ListProblems from '../shared/components/anomalie/ListProblems';


const Anomalie = () => {
  return (
    <div>
      <ListProblems/>
    </div>
  );
};

export default Anomalie;