/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components Chart JS
import { total } from '../../tools/chartJs';
import { requestFormatDate } from '../../tools/date';
import LineChartJs from '../DashBoard/LineChartJs';
import PrimaryButton from '../PrimaryButton';



const DashboardHome = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListClients = useStoreActions((actions) => actions.middleware.MonitoringMiddleware.getListClients);
  const getListOrders = useStoreActions((actions) => actions.middleware.MonitoringMiddleware.getListOrders);
  const getListOrdersByMonth = useStoreActions(
    (actions) => actions.middleware.MonitoringMiddleware.getListOrdersByMonth
  );
  const getListOrdersByYear = useStoreActions((actions) => actions.middleware.MonitoringMiddleware.getListOrdersByYear);
  const getListFuelInMonth = useStoreActions((actions) => actions.middleware.FuelMiddleware.getListFuelsInMonth);
  const getListFuelInYear = useStoreActions((actions) => actions.middleware.FuelMiddleware.getListFuelsInYear);
  const getListFuelAll = useStoreActions((actions) => actions.middleware.FuelMiddleware.getListFuelsAll);
  //  🌱🌱  Etats (données)  🌱🌱
  const [listClients, setlistClients] = useState([]);
  const [listOrder, setListOrder] = useState([]);
  const [listOrderByDate, setListOrderByDate] = useState([]);
  const [listFuel, setListFuel]= useState([]);
  const [fuelQuantity, setFuelQuantity]= useState(0);
  const [materialQuantity, setMaterialQuantity] = useState(0);
  const [totalPrices, setTotalPrices] = useState(0);

  //  🌱🌱 Request call List client  🌱🌱
  const callListClients = async () => {
    try {
      const res = await getListClients();
      setlistClients(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des données clients.');
    }
  };

  //  🌱🌱 Request call List Order  🌱🌱
  const callListOrders = async () => {
    try {
      const res = await getListOrders();
      setListOrder(res);
      setMaterialQuantity(total(res, 'quantity'));
      setTotalPrices(total(res, 'price'));
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des données des commades.');
    }
  };

  //  🌱🌱 Request call List Order by month  🌱🌱
  const callListOrdersMonth = async (data) => {
    try {
      const res = await getListOrdersByMonth(data);
      setMaterialQuantity(total(res, 'quantity'));
      setTotalPrices(total(res, 'price'));
      setListOrderByDate(
        res.map((d) => {
          return { ...d, date: d.datePlanned };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des données des commades.');
    }
  };

  //  🌱🌱 Request call List Order by year 🌱🌱
  const callListOrdersYear = async (data) => {
    try {
      const res = await getListOrdersByYear(data);
      setMaterialQuantity(total(res, 'quantity'));
      setTotalPrices(total(res, 'price'));
      setListOrderByDate(
        res.map((d) => {
          return { ...d, date: d.datePlanned };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des données des commades.');
    }
  };
  const callListFuelMonth= async (data) => {
    try {
      const res = await getListFuelInMonth(data);
      console.log('res fuel', res)
      setListFuel(
        res.map((d) => {
          return { ...d, date: d.date };
        })
      );
      setFuelQuantity(total(res, 'quantity'))
    } catch (error) {
      console.log(error)
      toast.error('❗️ Une erreur est survenue lors du chargement des données des ravitaillement.');
    }
  };

  const callListFuelInYear= async (data) => {
    console.log( 'test')
    try {
      const res = await getListFuelInYear(data);
      console.log('res fuel', res)
      setListFuel(
        res.map((d) => {
          return { ...d, date: d.date };
        })
      );
      setFuelQuantity(total(res, 'quantity'))
    } catch (error) {
      console.log(error)
      toast.error('❗️ Une erreur est survenue lors du chargement des données des ravitaillement.');
    }
  };

  const callListFuelAll= async () => {
    try {
      const res = await getListFuelAll();
      console.log('res fuel', res)
      setListFuel(
        res.map((d) => {
          return { ...d, date: d.date };
        })
      );
      setFuelQuantity(total(res, 'quantity'))
    } catch (error) {
      console.log(error)
      toast.error('❗️ Une erreur est survenue lors du chargement des données des ravitaillement.');
    }
  };

  //  🌱🌱  Manage event date select LineChartJs  🌱🌱
  const handleChangeDataDate = (data) => {
    console.log('data',data.date);
    if (data.type ==='day') {
      console.log(data);
      callListOrdersMonth(requestFormatDate(data.date));
      callListFuelMonth(requestFormatDate(data.date))
    }
    if (data.type ==='month') {
      console.log(data);
      callListOrdersYear(requestFormatDate(data.date));
      callListFuelInYear(requestFormatDate(data.date))
    }
  };

//  const callAllForTotal=()=> {
//     callListClients();
//     callListOrders();
//     callListFuelAll(); 
//     callListOrdersMonth(requestFormatDate(Date.now()));
//   }

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListClients();
    callListOrders();
    callListFuelAll(); 
    callListOrdersMonth(requestFormatDate(Date.now()));
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full px-4 pt-4 md:pt-14 md:px-9 pb-9">
      {/* div Title  */}
      <div className="flex flex-col justify-between mb-4 border-b items center border-gray-admin md:flex-row">
        <h3 className="font-bold text-xl pb-[2.125rem]">Tableau de bord</h3>
      </div>
      {/*  div avec les 4 cards*/}
      <div className="flex flex-wrap items-center">
        <div className="card-dashboard">
          <p className="text-sm font-bold">Chiffre d’affaires</p>
          <p className="text-4xl font-bold">{totalPrices} €</p>
          {/* Div 3 éléments */}
          <div className="flex items-center justify-between space-x-11 font-medium text-xs min-w-[200px]">
          </div>
        </div>
        <div className=" card-dashboard">
          <p className="text-sm font-bold">Clients</p>
          <p className="text-4xl font-bold">{listClients?.length}</p>

          <div className=" flex items-center justify-between space-x-11 font-medium text-xs min-w-[200px]">
          </div>
        </div>
        <div className="card-dashboard">
          <p className="text-sm font-bold">Agrégats (en tonnes)</p>
          <p className="text-4xl font-bold">{materialQuantity}</p>

          <div className=" flex items-center justify-between space-x-11 font-medium text-xs min-w-[200px]">
          </div>
        </div>
        <div className="card-dashboard">
          <p className="text-sm font-bold">Ravitaillement (en litres)</p>
          <p className="text-4xl font-bold">{fuelQuantity} L</p>
          <div className="flex items-center justify-between space-x-11 font-medium text-xs min-w-[200px]">
          </div>
        </div>
        {/* <PrimaryButton
        title={'Total'}
        onClick={callAllForTotal()}
        >
    
        </PrimaryButton> */}
      </div>
      {/* Div Suivi des commandes */}
      <div className="p-4 pb-12 mt-6 space-y-5 border rounded-lg border-1 border-gray-admin">
        <LineChartJs
          data={listOrderByDate}
          data1={listFuel}
          onChangeDate={handleChangeDataDate}
        />
      </div>
      {/* Div Sous total marchandise */}
    </section>
  );
};

export default DashboardHome;

