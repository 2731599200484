import React from 'react';
import ListDrivers from '../shared/components/drivers/ListDrivers';

const Drivers = () => {
  return (
    <>
      <ListDrivers />
    </>
  );
};

export default Drivers;

