import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import DonutChartJs from '../DashBoard/DonutChartJs';
import { fusionOccurrence, sorted } from '../../tools/file';
import Table from '../Table';

const Analyse = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idClient } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getListMaterilasByClient = useStoreActions(
    (actions) => actions.middleware.MonitoringMiddleware.getListMaterilasByClient
  );

  //  🌱🌱  Etats (données)  🌱🌱
  const [listMaterials, setListMaterials] = useState([]);

  //  🌱🌱 Request call List Materials  🌱🌱
  const callListMaterials = async () => {
    try {
      const res = await getListMaterilasByClient(idClient);
      const listMarchandises = res
        .filter((el) => el?.marchandise?.name)
        .map((mc) => {
          return { ...mc, mc: mc.marchandise.name };
        });
      const listMarchandises2 = fusionOccurrence(listMarchandises, 'mc', 'price', 'quantity');
      const listMarchandises3 = sorted(listMarchandises2, 'count', true);
      setListMaterials(listMarchandises3);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des données clients.');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListMaterials();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-4 px-4 md:px-9 h-full flex flex-col ">
      <div className=" flex items-center bg-gray-sidebar mb-4 rounded-md">
        <h3 className="font-bold text-xl p-4">Analyse</h3>
      </div>

      <div className="grow">
        {/* Div donuts */}
        <div className="flex justify-center w-full pb-5">
          {listMaterials.length ? <DonutChartJs data={listMaterials} /> : null}
        </div>
        <div>
          <Table>
            <thead>
              <tr>
                <th>Matériau</th>
                <th>Nombre de commandes </th>
                <th>Quantités en Tonne </th>
                <th>Total €</th>
              </tr>
            </thead>
            <tbody>
              {listMaterials.map((mat) => (
                <tr key={mat.id}>
                  <td>{mat.mc}</td>
                  <td>{mat.count}</td>
                  <td>{mat.total2}</td>
                  <td>{mat.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Div 1 bouton */}
      <div className="flex flex-col-reverse gap-y-4  pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
    </section>
  );
};

export default Analyse;

