import React from 'react';

const Cgu = () => {
  return (
    <>
        <h1 className='mt-10 font-extrabold text-center'>Mentions légales et politique de confidentialité</h1>
        <div className='mx-5 my-14'>
            <h3 className='text-justify'>La société TRANSPORTS OZIER ET FILS, soucieuse des droits des individus, notamment au regard des traitements
            automatisés et dans une volonté de transparence avec ses clients, a mis en place une politique reprenant l’ensemble de
            ces traitements, des finalités poursuivies par ces derniers ainsi que des moyens d’actions à la disposition des individus
            afin qu’ils puissent au mieux exercer leurs droits.
            </h3>
            <h3 className='text-justify'>
            Pour toute information complémentaire sur la protection des données personnelles, nous vous invitons à consulter le
            site : <a className='underline underline-offset-2' href='https://www.cnil.fr/'>https://www.cnil.fr/</a>
            </h3>
            <h3 className='text-justify'>
            La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui
            suivent.
            </h3>
            <h3 className='text-justify'>
            La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée
            d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.
            </h3>
            <h2 className='mt-3 mt-6 font-bold'>
            Article 1 - Mentions légales
            </h2>
            <h3 className='mt-3 text-justify'>
            1.1 Site (ci-après « le site ») : 
            </h3>
            <h3 className='mt-3'>
            <a className='underline underline-offset-2' href='https://www.ozier.fr/'>https://www.ozier.fr/</a>
            </h3>
            <h3 className='mt-3 text-justify underline underline-offset-2'>
            1.2 Éditeur (ci-après « l'éditeur ») :
            </h3>
            <h3 className='mt-3 text-justify'>
            La société TRANSPORTS OZIER ET FILS, SAS au capital de 100 000 euros, dont le siège social est situé au 1 rue de la
            Gare 89100 SENS, immatriculée au RCS de SENS sous le numéro 572 880 034, représentée par M. OZIER en sa qualité
            de Président
            </h3>
            <h3>
            immatriculée au RCS de Fort-de-France B 918 381 765
            </h3>
            <h3 className='text-justify'>
            Numéro de téléphone : 0696 44 71 12
            </h3>
            <h3 className='text-justify'>
            Adresse mail : <a className='underline underline-offset-2' href='mailto:'>: gabrielozier@gmail.com </a>
            </h3>
            <h3 className='mt-3 text-justify underline underline-offset-2'>
            1.3 Hébergeur (ci-après « l'hébergeur ») :
            </h3>
            <h3 className='mt-3 text-justify '>
            Tps Ozier et fils est hébergé par Amazon Web Services La Défense CDG 11 AWS, dont le siège social est situé 31 Pl.
            des Corolles 92400 Courbevoie.
            </h3>
            <h3 className='mt-3 text-justify underline underline-offset-2'>
            1.4 Délégué à la protection des données (DPO) :
            </h3>
            <h3 className='mt-3 text-justify'>
            Un délégué à la protection des données : Andji SACACARABANY, asacarabany@novwell.fr, est à votre disposition
            pour toute question relative à la protection de vos données personnelles.
            </h3>
            <h2 className='mt-6 font-bold'>
            Article 2 - Accès au site
            </h2>
            <h3 className='mt-3 text-justify'>
            L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce
            site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme
            de sollicitation commerciale et notamment l'envoi de courriers électroniques non sollicités.
            </h3>
            <h2 className='mt-6 font-bold'>
            Article 3 - Contenu du site
            </h2>
            <h3 className='mt-3 text-justify'>
            Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons,
            ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus
            généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la
            propriété intellectuelle.
            </h3>
            <h3 className='text-justify'>
            Ils sont la propriété pleine et entière de l'éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou
            adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques,
            sans l'accord préalable et écrit de l'éditeur, sont strictement interdites. Le fait pour l'éditeur de ne pas engager de
            procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et
            renonciation aux poursuites.
            </h3>
            <h2 className='mt-6 font-bold'>
            Article 4 - Gestion du site
            </h2>
            <h3 className='mt-3 text-justify'>
            Pour la bonne gestion du site, l'éditeur pourra à tout moment :
            </h3>
            <h3 className='text-justify'>
            - suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site,
            à une catégorie déterminée d'internautes ;
            </h3>
            <h3 className='text-justify'>
            - supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois
            nationales ou internationales ;
            </h3>
            <h3 className='text-justify'>
            - suspendre le site afin de procéder à des mises à jour.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 5 - Responsabilités
            </h2>
            <h3 className='mt-3 text-justify'>
            La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de
            fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.
            </h3>
            <h3 className='text-justify'>
            Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les
            mesures appropriées pour protéger votre matériel et vos propres données notamment d'attaques virales par Internet.
            Vous êtes par ailleurs seul responsable des sites et données que vous consultez.
            </h3>
            <h3 className='mt-3 text-justify'>
            L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
            </h3>
            <h3 className='text-justify'>
            - du fait de l'usage du site ou de tout service accessible via Internet ;
            </h3>
            <h3 className='text-justify'>
            - du fait du non-respect par vous des présentes conditions générales.
            </h3>
            <h3 className='mt-3 text-justify'>
            L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de
            votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.
            </h3>
            <h3 className='mt-3 text-justify'>
            Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de votre utilisation du site, il pourra se
            retourner contre vous pour obtenir l'indemnisation de tous les préjudices, sommes, condamnations et frais qui
            pourraient découler de cette procédure.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 6 - Liens hypertextes
            </h2>
            <h3 className='mt-3 text-justify'>
            La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf
            autrisation préalable et écrite de l'éditeur.
            </h3>
            <h3 className='text-justify'>
            L'éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision. Dans le
            cas où l'éditeur accorderait son autorisation, celle-ci n'est dans tous les cas que temporaire et pourra être retirée à tout
            moment, sans obligation de justification à la charge de l'éditeur.
            </h3>
            <h3 className='text-justify'>
            Toute information accessible via un lien vers d'autres sites n'est pas publiée par l'éditeur. L'éditeur ne dispose d'aucun
            droit sur le contenu présent dans ledit lien.
            </h3>
            <h2>
            Article 7 - Collecte et protection des données
            </h2>
            <h3 className='mt-3 text-justify'>
            Vos données sont collectées par la société TRANSPORTS OZIER ET FILS.
            </h3>
            <h3 className='text-justify'>
            Une donnée à caractère personnel désigne toute information concernant une personne physique identifiée ou
            identifiable (personne concernée) ; est réputée identifiable une personne qui peut être identifiée, directement ou
            indirectement, notamment par référence à un nom, un numéro d'identification ou à un ou plusieurs éléments
            spécifiques, propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.
            </h3>
            <h3 className='text-justify'>
            Les informations personnelles pouvant être recueillies sur le site sont principalement utilisées par l'éditeur pour la
            gestion des relations avec vous, et le cas échéant pour le traitement de vos commandes.
            </h3>
            <h3 className='mt-3 text-justify'>
            Les données personnelles collectées sont les suivantes :
            </h3>
            <h3 className='text-justify'>
            - Nom et prénom
            </h3>
            <h3 className='text-justify'>
            - Adresse mail
            </h3>
            <h3 className='text-justify'>
            - Numéro de téléphone
            </h3>
            <h3 className='text-justify'>
            Un délégué à la protection des données : Andji SACACARABANY, <a className='underline underline-offset-2' href='mailto:'>: asacarabany@novwell.fr </a>, est à votre disposition
            pour toute question relative à la protection de vos données personnelles.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 8 - Droit d’accès, de rectification et de déréférencement de vos données
            </h2>
            <h3 className='mt-3 text-justify'>
            En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des
            droits suivants :
            </h3>
            <h3 className='text-justify'>
            • le droit d’accès : ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant,
            en écrivant à l'adresse électronique ci-dessous mentionnée. Dans ce cas, avant la mise en œuvre de ce droit, la
            Plateforme peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude ;
            </h3>
            <h3 className='text-justify'>
            • le droit de rectification : si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent
            demander la mise à jour des informations ;
            </h3>
            <h3 className='text-justify'>
            • le droit de suppression des données : les utilisateurs peuvent demander la suppression de leurs données à caractère
            personnel, conformément aux lois applicables en matière de protection des données ;
            </h3>
            <h3 className='text-justify'>
            • le droit à la limitation du traitement : les utilisateurs peuvent de demander à la Plateforme de limiter le traitement
            des données personnelles conformément aux hypothèses prévues par le RGPD ;
            </h3>
            <h3 className='text-justify'>
            • le droit de s’opposer au traitement des données : les utilisateurs peuvent s’opposer à ce que leurs données soient
            traitées conformément aux hypothèses prévues par le RGPD ;
            </h3>
            <h3 className='text-justify'>
            • le droit à la portabilité : ils peuvent réclamer que la Plateforme leur remette les données personnelles qu'ils ont
            fournies pour les transmettre à une nouvelle Plateforme.
            </h3>

            <h3 className='mt-3 text-justify'>
            Vous pouvez exercer ce droit en nous contactant, à l’adresse suivante :
            </h3>
            <h3 className='text-justify'>
            6 LOT LES MOUBINS 3 97218 BASSE-POINTE.
            </h3>
            <h3 className='text-justify'>
            Ou par email, à l’adresse :
            </h3>
            <h3 className='text-justify'>
            <a className='underline underline-offset-2' href='mailto:'>: gabrielozier@gmail.com </a>
            </h3>
            <h3 className='mt-3 text-justify'>
            Vous pouvez aussi vous adresser à notre délégué à la protection des données :
            </h3>
            <h3 className='text-justify'>
            Andji SACACARABANY, <a className='underline underline-offset-2' href='mailto:'>: asacarabany@novwell.fr </a>
            qui est à votre disposition pour toute question relative à la protection
            de vos données personnelles.
            </h3>
            <h3 className='mt-3 text-justify'>
            Toute demande doit être accompagnée de la photocopie d’un titre d’identité en cours de validité signé et faire mention
            de l’adresse à laquelle l'éditeur pourra contacter le demandeur. La réponse sera adressée dans le mois suivant la
            réception de la demande. Ce délai d'un mois peut être prolongé de deux mois si la complexité de la demande et/ou le
            nombre de demandes l'exigent.
            </h3>
            <h3 className='mt-3 text-justify'>
            De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes qui le souhaitent, ont la possibilité d’organiser
            le sort de leurs données après leur décès. Pour plus d’information sur le sujet, vous pouvez consulter le site Internet de
            la CNIL : <a className='underline underline-offset-2' href='https://www.cnil.fr/'>https://www.cnil.fr/</a>.
            </h3>
            <h3 className='mt-3 text-justify'>
            Les utilisateurs peuvent aussi introduire une réclamation auprès de la CNIL sur le site de la CNIL : <a className='underline underline-offset-2' href='https://www.cnil.fr/'>https://www.cnil.fr/</a>.
            </h3>
            <h3 className='mt-3 text-justify'>
            Nous vous recommandons de nous contacter dans un premier temps avant de déposer une réclamation auprès de la
            CNIL, car nous sommes à votre entière disposition pour régler votre problème.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 9 - Utilisation des données
            </h2>
            <h3 className='mt-3 text-justify'>
            Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de la
            Plateforme, leur amélioration et le maintien d'un environnement sécurisé. La base légale des traitements est l’exécution
            du contrat entre l’utilisateur et la Plateforme. Plus précisément, les utilisations sont les suivantes :
            </h3>
            <h3 className='mt-3 text-justify'>
            - accès et utilisation de la Plateforme par l'utilisateur ;
            </h3>
            <h3 className='text-justify'>
            - gestion du fonctionnement et optimisation de la Plateforme ;
            </h3>
            <h3 className='text-justify'>
            - mise en œuvre d'une assistance utilisateurs ;
            </h3>
            <h3 className='text-justify'>
            - vérification, identification et authentification des données transmises par l'utilisateur ;
            </h3>
            <h3 className='text-justify'>
            - personnalisation des services en affichant des publicités en fonction de l'historique de navigation de l'utilisateur, selon
            ses préférences ;
            </h3>
            <h3 className='text-justify'>
            - prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents
            de sécurité ;
            </h3>
            <h3 className='text-justify'>
            - gestion des éventuels litiges avec les utilisateurs ;
            </h3>
            <h3 className='text-justify'>
            - envoi d'informations commerciales et publicitaires, en fonction des préférences de l'utilisateur ;
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 10 - Politique de conservation des données
            </h2>
            <h3 className='mt-3 text-justify'>
            La Plateforme conserve vos données pour la durée nécessaire pour vous fournir ses services ou son assistance.
            Dans la mesure raisonnablement nécessaire ou requise pour satisfaire aux obligations légales ou réglementaires, régler
            des litiges, empêcher les fraudes et abus ou appliquer nos modalités et conditions, nous pouvons également conserver
            certaines de vos informations si nécessaire, même après que vous ayez fermé votre compte ou que nous n'ayons plus
            besoin pour vous fournir nos services.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 11- Partage des données personnelles avec des tiers
            </h2>
            <h3 className='mt-3 text-justify'>
            Les données personnelles peuvent être partagées avec des sociétés tierces exclusivement dans l’Union européenne,
            dans les cas suivants :
            </h3>
            <h3 className='text-justify'>
            - lorsque l'utilisateur publie, dans les zones de commentaires libres de la Plateforme, des informations accessibles au
            public ;
            </h3>
            <h3 className='text-justify'>
            - quand l'utilisateur autorise le site web d'un tiers à accéder à ses données ;
            </h3>
            <h3 className='text-justify'>
            - quand la Plateforme recourt aux services de prestataires pour fournir l'assistance utilisateurs, la publicité et les services
            de paiement. Ces prestataires disposent d'un accès limité aux données de l'utilisateur, dans le cadre de l'exécution de ces
            prestations, et ont l'obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation
            applicable en matière protection des données à caractère personnel ;
            </h3>
            <h3 className='text-justify'>
            - si la loi l'exige, la Plateforme peut effectuer la transmission de données pour donner suite aux réclamations présentées
            contre la Plateforme et se conformer aux procédures administratives et judiciaires ;
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 12 - Offres commerciales
            </h2>
            <h3 className='mt-3 text-justify'>
            Vous êtes susceptible de recevoir des offres commerciales de l'éditeur. Si vous ne le souhaitez pas, veuillez cliquer sur
            le lien suivant : <a className='underline underline-offset-2' href='mailto:'>: gabrielozier@gmail.com </a>
            </h3>
            <h3 className='text-justify'>
            Vos données sont susceptibles d’être utilisées par les partenaires de l'éditeur à des fins de prospection commerciale, si
            vous ne le souhaitez pas, envoyez un mail à l’adresse suivante : <a className='underline underline-offset-2' href='mailto:'>: gabrielozier@gmail.com </a>
            </h3>
            <h3 className='text-justify'>
            Si, lors de la consultation du site, vous accédez à des données à caractère personnel, vous devez vous abstenir de toute
            collecte, de toute utilisation non autorisée et de tout acte pouvant constituer une atteinte à la vie privée ou à la
            réputation des personnes. L'éditeur décline toute responsabilité à cet égard.
            </h3>
            <h3 className='text-justify'>
            Les données sont conservées et utilisées pour une durée conforme à la législation en vigueur.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 13 - Cookies
            </h2>
            <h3 className='mt-3 text-justify'>
            Qu’est-ce qu’un « cookie » ?
            </h3>
            <h3 className='text-justify'>
            Un « Cookie » ou traceur est un fichier électronique déposé sur un terminal (ordinateur, tablette, smartphone, …) et lu
            par exemple lors de la consultation d'un site internet, de la lecture d'un courrier électronique, de l'installation ou de
            l'utilisation d'un logiciel ou d'une application mobile et ce, quel que soit le type de terminal
            utilisé (source : <a className='underline underline-offset-2' href='https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi)'></a>).
            </h3>
            <h3 className='font-thin text-justify'>
            Le site peut collecter automatiquement des informations standards. Toutes les informations collectées indirectement ne seront utilisées
            que pour suivre le volume, le type et la configuration du trafic utilisant ce site, pour en développer la conception et l'agencement et à
            d'autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous offrons.
            </h3>
            <h3 className='text-justify'>
            Le cas échéant, des « cookies » émanant de l'éditeur du site et/ou des sociétés tiers pourront être déposés sur votre
            terminal, avec votre accord. Dans ce cas, lors de la première navigation sur ce site, une bannière explicative sur
            l’utilisation des « cookies » apparaîtra. Avant de poursuivre la navigation, le client et/ou le prospect devra accepter ou
            refuser l’utilisation desdits « cookies ». Le consentement donné sera valable pour une période de treize (13)
            mois. L'utilisateur a la possibilité de désactiver les cookies à tout moment
            </h3>
            <h3 className='text-justify'>
            Les cookies suivants sont présents sur ce site :
            </h3>
            <h3 className='font-bold text-justify'>
            Cookies Google :
            </h3>
            <h3 className='text-justify'>
            - Google analytics : permet de mesurer l'audience du site ;
            </h3>
            <h3 className='text-justify'>
            - Google tag manager : facilite l'implémentation des tags sur les pages et permet de gérer les balises Google ;
            </h3>
            <h3 className='text-justify'>
            - Google Adsense : régie publicitaire de Google utilisant les sites web ou les vidéos YouTube comme support pour ses
            annonces ;
            </h3>
            <h3 className='text-justify'>
            - Google Dynamic Remarketing : permet de vous proposer de la publicité dynamique en fonction des précédentes
            recherches ;
            </h3>
            <h3 className='text-justify'>
            - Google Adwords Conversion : outil de suivi des campagnes publicitaires adwords ;
            </h3>
            <h3 className='text-justify'>
            - DoubleClick : cookies publicitaires de Google pour diffuser des bannières.
            </h3>
            <h3 className='font-bold text-justify'>
            Cookies Facebook :
            </h3>
            <h3 className='text-justify'>
            - Facebook connect : permet de se connecter via son compte Facebook ;
            </h3>
            <h3 className='text-justify'>
            - Facebook social plugins : permet d'aimer, de partager, de commenter du contenu avec un compte Facebook.
            </h3>
            <h3 className='text-justify'>
            - Facebook Custom Audience : permet d'interagir avec l'audience sur Facebook.
            </h3>
            <h3 className='font-bold text-justify'>
            Cookies Twitter :
            </h3>
            <h3 className='text-justify'>
            - Twitter button : permet de partager le contenu du site sur Twitter ;
            </h3>
            <h3 className='text-justify'>
            - Twitter advertising : permet de proposer des publicités Twitter.
            </h3>
            <h3 className='mt-3 text-justify'>
            La durée de vie de ces cookies est de treize mois.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 14 - Photographies et représentation des produits
            </h2>
            <h3 className='mt-3 text-justify'>
            Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas
            l'éditeur.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 15 - Loi applicable
            </h2>
            <h3 className='mt-3 text-justify'>
            Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux
            du siège social de l'éditeur, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou
            réglementaire particulier.
            </h3>
            <h2 className='mt-3 font-bold'>
            Article 16 - Contactez-nous
            </h2>
            <h3 className='mt-3 text-justify'>
            Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous pouvez
            laisser un message à l'adresse suivante : <a className='underline underline-offset-2' href='mailto:'> gabrielozier@gmail.com </a>
            </h3>
            <h3 className='mt-16 text-justify'>
                              
            </h3>
        </div>

    </>
  );
};

export default Cgu;