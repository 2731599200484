import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const RevisionMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST REVISION
  //✨✨==========================================✨✨
  getListRevision: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listRevisions(filter: {truckRevisionId: {eq: "${payload}"}}) {
          items {
            date
            document
            garage
            id
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listRevisions.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE REVISION
  //✨✨==========================================✨✨
  createRevision: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createRevision(input: {
            truckRevisionId: "${payload?.idTruck}", 
            garage: "${payload?.garage}", 
            document: "${payload?.document}", 
            date: "${payload?.date}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createRevision;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE REVISION
  //✨✨==========================================✨✨
  deleteRevision: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        deleteRevision(input: {id: "${payload}"}) {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteRevision;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default RevisionMiddleware;

