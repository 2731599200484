import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import Table from '../../Table';
import PrimaryInput from '../../PrimaryInput';
import { dateFormat } from '../../../tools/date';

// Import icons
import More from '../../../../assets/images/svg/more.svg';
import Search from '../../../../assets/images/svg/search.svg';
import TruckDay from '../../../../assets/images/svg/truckDay.svg';
import Eye from '../../../../assets/images/svg/eye.svg';

const ListOrderFinish = () => {
  const { pathname } = useLocation();
  const { idClient } = useParams();

  const path = pathname.includes('clients') ? '/clients/editFinish/' : '/orders/preview-order/';
  const isClientMode = pathname.includes('clients');

  //  🌱🌱 init store(services)   🌱🌱
  //🌿 Actions
  const getListOrderFinish = useStoreActions((actions) => actions.middleware.OrderMiddleware.getListOrderFinish);
  const getListOrderFinishByClient = useStoreActions(
    (actions) => actions.middleware.OrderMiddleware.getListOrderFinishByClient
  );

  // 🌿 State
  const selectDriver = useStoreState((state) => state.global.selectDriver);
  const selectMarchandise = useStoreState((state) => state.global.selectMarchandise);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listOrder, setListOrder] = useState([]);
  const [orders, setOrders] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  //  🌱🌱  useRef  🌱🌱
  const listRefOrder = useRef([]);
  const textSearchRef = useRef(null);
  const isFilterRef = useRef(false);

  //  🌱🌱 Request call List order finish  🌱🌱
  const callListOrderFinish = async () => {
    try {
      const res = await getListOrderFinish();
      setListOrder(res);
      setOrders(res);
      listRefOrder.current = res;
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des commandes');
    }
  };

  //  🌱🌱 Request call List order by client  🌱🌱
  const callListOrderNotFinishByClient = async () => {
    try {
      const res = await getListOrderFinishByClient(idClient);
      setListOrder(res);
      listRefOrder.current = res;
      setOrders(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des commandes');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    if (isClientMode) {
      callListOrderNotFinishByClient();
    } else {
      callListOrderFinish();
    }
  }, []);

  //  🌱🌱  2de effect for search 🌱🌱
  useEffect(() => {
    if (listRefOrder.current.length) {
      if (isFilterRef.current === false) {
        if (textSearch === '') {
          setListOrder(orders);
        } else {
          const tabFilter = orders.filter(
            (element) =>
              element.client?.firstName?.toLowerCase().includes(textSearch.toLowerCase()) ||
              element.client?.lastName?.toLowerCase().includes(textSearch.toLowerCase()) ||
              element.numero?.toLowerCase().includes(textSearch.toLowerCase())
          );
          setListOrder(tabFilter);
        }
      }

      isFilterRef.current = false;
    }
  }, [textSearch]);

  //  🌱🌱  3e effect for filter by driver 🌱🌱
  useEffect(() => {
    if (listRefOrder.current.length) {
      if (selectDriver) {
        setListOrder(listRefOrder.current.filter((element) => element?.driver?.id === selectDriver));
        setOrders(listRefOrder.current.filter((element) => element?.driver?.id === selectDriver));
      }
      if (selectDriver === '' && selectMarchandise === '') {
        setListOrder(listRefOrder.current);
        setOrders(listRefOrder.current);
      }
      textSearchRef.current.value = '';

      setTextSearch('');
      if (textSearchRef) {
        isFilterRef.current = false;
      } else {
        isFilterRef.current = true;
      }
    }
  }, [selectDriver]);

  //  🌱🌱  4e effect for filter by marchandise 🌱🌱
  useEffect(() => {
    if (listRefOrder.current.length) {
      if (selectMarchandise) {
        setListOrder(listRefOrder.current.filter((element) => element?.marchandise?.id === selectMarchandise));
        setOrders(listRefOrder.current.filter((element) => element?.marchandise?.id === selectMarchandise));
      }
      if (selectDriver === '' && selectMarchandise === '') {
        setListOrder(listRefOrder.current);
        setOrders(listRefOrder.current);
      }
      textSearchRef.current.value = '';
      setTextSearch('');
      if (textSearchRef) {
        isFilterRef.current = false;
      } else {
        isFilterRef.current = true;
      }
    }
  }, [selectMarchandise]);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-8 px-4 md:px-9">
      {/* div Search bar */}
      <div className=" relative mb-10">
        <PrimaryInput
          styleInput={'pl-10 h-12 '}
          type="text"
          placeholder="Recherche ( client ou n° de commande)"
          onChangeText={setTextSearch}
          myRef={textSearchRef}
        />
        <img
          src={Search}
          alt="search icon"
          className=" absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
        />
      </div>
      {/* Liste des commandes */}
      <div className="mt-4 pb-5">
        <Table>
          <thead>
            <tr>
              <th>Créé le </th>
              <th>N° de commande</th>
              <th>Nom du client</th>
              <th>Adresse</th>
              <th>Bon de commande</th>
              <th>Marchandise</th>
              <th>Fait le</th>
              <th>Réaliser par</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listOrder.length ? (
              listOrder.map((order) => {
                const createDate = dateFormat(order?.date).full;
                const Planneddate = dateFormat(order?.datePlanned).full;
                return (
                  <tr key={order.id}>
                    <td>{createDate}</td>
                    <td>{order.numero}</td>
                    <td>
                      {order?.client?.firstName} {order?.client?.lastName}
                    </td>
                    <td>{order?.address?.address}</td>
                    <td>
                      {' '}
                      {!!order?.document && (
                        <Link
                          to={`/orders/preview/${order.id}`}
                          className="flex items-center space-x-2 border border-gray-admin w-20 py-1 px-2 rounded-md cursor-pointer"
                        >
                          <p>Ouvrir</p>
                          <img
                            src={Eye}
                            alt="icone tick circle"
                          />
                        </Link>
                      )}
                    </td>
                    <td>
                      {' '}
                      {order.shipType == 'Journée' ? (
                        <img
                          src={TruckDay}
                          className="w-10"
                          alt="icon truck"
                        />
                      ) : (
                        `${order?.marchandise?.name}`
                      )}
                    </td>
                    <td>{Planneddate}</td>

                    <td>
                      <div className="flex items-center ">
                        <div className="hoverInitial">
                          {order?.driver?.firstName[0]}
                          {order?.driver?.lastName[0]}
                          <span>
                            {order?.driver?.firstName}
                            {order?.driver?.lastName}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex space-x-8 justify-end items-center">
                        <Link
                          to={`${path}${order.id}`}
                          className="w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                        >
                          <img
                            src={More}
                            alt="more icon"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={8}
                >
                  {textSearch
                    ? "Votre recherche n'a pas donné de résultats. Veuillez essayer avec d'autres mots-clés."
                    : 'La liste des commandes est vide.'}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListOrderFinish;

