import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationOrder from '../shared/layout/OrderNavigation/NavigationOrder';

const Orders = () => {
  return (
    <div>
      <NavigationOrder />
      <Outlet />
    </div>
  );
};

export default Orders;

