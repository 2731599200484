import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const AdminMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST ADMIN
  //✨✨==========================================✨✨

  getListAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listAdmins(filter: {archived: {eq: false}}) {
        items {
          firstName
          id
          lastName
          mail
          statut
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listAdmins.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ADMIN
  //✨✨==========================================✨✨
  getAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getAdmin(id: "${payload}") {
        firstName
        id
        id_cognito
        lastName
        mail
        statut
      }
    }

    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getAdmin;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ADMIN BY COGNITO
  //✨✨==========================================✨✨
  getAdminCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listAdmins(filter: {id_cognito: {eq: "${payload}"}}) {
        items {
          firstName
          id
          lastName
          mail
          statut
          id_cognito
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listAdmins?.items[0];
      getStoreActions().userData.setUserAdmin(myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE ADMIN
  //✨✨==========================================✨✨
  createAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createAdmin(input: {
        firstName: "${payload?.firstName}", 
        id_cognito: "${payload?.id_cognito}", 
        lastName: "${payload?.lastName}", 
        mail: "${payload?.mail}", 
        statut: "${payload?.statut}",
        archived: false
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createAdmin;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE ADMIN
  //✨✨==========================================✨✨
  updateAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateAdmin(input: {
          id: "${payload?.id}", 
          firstName: "${payload?.firstName}", 
          lastName: "${payload?.lastName}", 
          mail: "${payload?.mail}",
           statut: "${payload?.statut}",
          }) 
          {
          id
      }
    }
    
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateAdmin;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE ADMIN (UPDATE ARCHIVED)
  //✨✨==========================================✨✨
  deleteAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateAdmin(input: {
          id: "${payload?.id}", 
          archived: true
          }) 
          {
          id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateAdmin;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default AdminMiddleware;

