import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import { dateFormat } from '../../../tools/date';

const PreviewOrder = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idOrder } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getOrder = useStoreActions((actions) => actions.middleware.OrderMiddleware.getOrder);

  //  🌱🌱  Etats (données)  🌱🌱
  const [order, setOrder] = useState({});

  const formulairExemple = [
    {
      label: 'Prénom du responsable',
      inputType: 'text',
      valueName: 'firstName',
      placeholder: 'Prénom',
      errorMsg: 'Veuillez entrer un prénom valide',
      required: true,
      halfWidth: true,
      disabled: true,
      defaultValue: order?.client?.firstName,
    },
    {
      label: 'Nom du responsable',
      inputType: 'text',
      valueName: 'lastName',
      placeholder: 'Prénom',
      errorMsg: 'Veuillez entrer un prénom valide',
      required: true,
      halfWidth: true,
      disabled: true,
      defaultValue: order?.client?.lastName,
    },
    {
      label: 'Nom de la société',
      inputType: 'text',
      valueName: 'Nom de la société',
      placeholder: 'Nom de la société',
      required: true,
      halfWidth: '1/2-full',
      disabled: true,
      defaultValue: order?.client?.company,
    },
  ];
  const FormulaireCommande = [
    {
      label: 'Créée le :',
      inputType: 'text',
      valueName: 'date',
      placeholder: 'XX . XX . XX',
      errorMsg: 'erreur date',
      required: false,
      halfWidth: true,
      disabled: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: dateFormat(order?.date, '.').full,
    },
    {
      label: 'Type de commande',
      inputType: 'text',
      valueName: 'order',
      halfWidth: true,
      placeholder: 'Rotation',
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: order?.shipType,
    },
    {
      label: 'Lieu de livraison',
      inputType: 'text',
      valueName: 'adress',
      placeholder: 'Adresse de livraison du client / Adresse du chantier',
      errorMsg: 'Veuillez entrer une adresse de livraison valide',
      required: true,
      halfWidth: false,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: order?.address?.address,
    },
  ];

  const FormulaireShipInfos = [
    {
      label: 'Marchandises',
      inputType: 'text',
      valueName: 'Marchandise',
      placeholder: 'Nom de la marchandise',
      errorMsg: '',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: order?.marchandise?.name,
    },
    {
      label: 'Tonnage',
      inputType: 'text',
      valueName: 'tonnage',
      placeholder: '4 tonnes',
      errorMsg: '',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: order?.quantity,
    },
  ];
  const FormulaireShipInfos2 = [
    {
      label: 'Type de journée',
      inputType: 'text',
      valueName: 'Marchandise',
      placeholder: 'Nom de la marchandise',
      errorMsg: '',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: order?.modelDay?.name,
    },
  ];

  const FormPrice = [
    {
      label: 'Prix',
      inputType: 'number',
      valueName: 'price',
      placeholder: '€',
      errorMsg: 'Choisir un prix',
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: order?.price,
      disabled: true,
    },
  ];

  const FormulaireLivraison = [
    {
      label: 'Réaliser par',
      inputType: 'text',
      valueName: 'nameDriver',
      errorMsg: 'Veuillez sélectionner un chauffeur valide',
      halfWidth: '1/2-full',
      required: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: `${order?.driver?.firstName} ${order?.driver?.lastName}`,
    },
    {
      label: 'Date de livraison',
      inputType: 'text',
      valueName: 'dateLivraison',
      errorMsg: 'Veuillez sélectionner une date de livraison valide',
      halfWidth: true,
      required: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: dateFormat(order?.datePlanned, '.').full,
    },
  ];

  //  🌱🌱 Request call  order  🌱🌱
  const callOrder = async () => {
    try {
      const res = await getOrder(idOrder);
      setOrder(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la commande');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callOrder();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 px-4 md:px-9 h-full flex flex-col pt-9">
      <div className=" flex items-center bg-gray-sidebar pb-4 md:pt-7 mb-4 rounded-md px-4">
        <div className="flex md:items-center pt-5 w-full justify-between flex-col md:flex-row">
          <h3 className="font-bold text-xl md:pl-5 md:pt-5">Commande N° {order?.id && order?.numero}</h3>
          <div className="flex flex-col pr-5 md:items-end">
            <h3 className="md:pb-4 py-2.5 ">{order?.id && order?.client?.company}</h3>
            <h3 className="bg-white-admin p-2.5 rounded-lg font-medium text-sm">{order?.id && order?.client?.name}</h3>
          </div>
        </div>
      </div>
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-7 mb-4">
        <h3 className="font-bold text-xl ">Infos client</h3>
      </div>
      <div className="max-w-[1100px]">{order?.id && <FormCustom data={formulairExemple} />}</div>
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-7 mb-4">
        <h3 className="font-bold text-xl ">Infos commande</h3>
      </div>

      {/* 2e formulaire  : Commande*/}
      <div className="max-w-[1100px] pb-5">{order?.id && <FormCustom data={FormulaireCommande} />}</div>
      {order.shipType == 'Tournée' ? (
        <div className="max-w-[1100px] pb-5">{order?.id && <FormCustom data={FormulaireShipInfos} />}</div>
      ) : (
        <div className="max-w-[1100px] pb-5">{order?.id && <FormCustom data={FormulaireShipInfos2} />}</div>
      )}
      <div className="max-w-[1100px] pb-5">{order?.id && <FormCustom data={FormPrice} />}</div>
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-7 mb-4">
        <h3 className="font-bold text-xl ">Infos livraison</h3>
      </div>

      {/* 3e formulaire: Livraison*/}
      <div className="max-w-[1100px] pb-5 flew-grow">{order?.id && <FormCustom data={FormulaireLivraison} />}</div>
      {/* Div 1 bouton */}
      <div className="flex flex-col-reverse gap-y-4  pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
    </section>
  );
};

export default PreviewOrder;

