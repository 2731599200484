import React from 'react';
import { twMerge } from 'tailwind-merge';

const PrimaryInput = ({
  placeholder = 'Primary Input',
  styleInput,
  type = 'text',
  myRef,
  onChangeText,
  disabled,
  name,
  id,
  defaultValue,
}) => {
  //Render
  return (
    <input
      autoComplete="new-password"
      ref={myRef}
      type={type}
      placeholder={placeholder}
      className={twMerge(`h-14 rounded-xl inputFocus w-full pl-3 border ${styleInput}`)}
      onInput={(e) => onChangeText(e.target.value)}
      readOnly={disabled}
      name={name}
      id={id}
      defaultValue={defaultValue}
    />
  );
};
export default PrimaryInput;
