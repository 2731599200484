import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const PrimaryButton = ({ title, link, style, state, disabled = false, onClick }) => {
  const [offBtn, setoffBtn] = useState(disabled);
  useEffect(() => {
    setoffBtn(disabled);
  }, [disabled]);
  return (
    <>
      {link ? (
        <Link
          className={twMerge(
            `px-4 py-3 text-white rounded-lg cursor-pointer bg-blue-admin font-medium text-sm text-center ${style}`
          )}
          to={link}
          state={state}
        >
          {title}
        </Link>
      ) : (
        <button
          className={twMerge(
            `px-4 py-3 text-white rounded-lg cursor-pointer bg-red-admin text-center font-medium text-sm ${
              offBtn ? 'opacity-30' : ''
            } ${style}`
          )}
          disabled={offBtn}
          onClick={onClick}
        >
          {title}
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
