/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { byMonth, byDay } from '../../tools/chartJs';
import { Line } from 'react-chartjs-2';
import DatePicker_month from '../DatePicker_month';
import DatePicker_year from '../DatePicker_year';

import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale, // Axe X
  LinearScale, // Axe Y
  Tooltip,
  Legend,
  PointElement,
  Title,
} from 'chart.js';
ChartJS.register(BarElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement, Title);

const LineChartJs = ({ data, onChangeDate }) => {
  const [myData, setMyData] = useState([]);
  const [typeDisplay, setTypeDisplay] = useState(0);
  const [selectDate, setSelectDate] = useState(new Date());

  // effect watch data event
  useEffect(() => {
    if (typeDisplay ===0) setMyData(byDay(data, selectDate));
    if (typeDisplay ===1) setMyData(byMonth(data));
    // if (typeDisplay ===2) setMyData(byYear(data, 2020, 2025));
  }, [data]);

  useEffect(() => {
    let result = null;
    if (typeDisplay ===0) result = { type: 'day', date: selectDate };
    if (typeDisplay ===1) result = { type: 'month', date: selectDate };
    // if (typeDisplay ===2) result = { type: 'year', date: selectDate };
    onChangeDate(result);
  }, [typeDisplay, selectDate]);

  const dataLine = (data) => {
    return {
      labels: data.label,
      datasets: [
        {
          label: 'Euro €',
          data: data.data,
          backgroundColor: ['#C55F4E'],
          borderColor: '#C55F4E',
          //   pointBorderColor: 'green',
          // fill: true,
          tension: 0.4, // TENSION :c'est pour avoir une courbe arrondie
        },
      ],
    };
  };

  const optionsLine = {
    plugins: {
      legend: false,
      title: {
        display: true,
        // text: ['Suivi des commandes'],
        color: '#1F2A37',
        align: 'start', // Align correspond à l'axe horizontal
        //position: "right", //position correspond à l'axe vertical
        font: {
          size: 16,
          family: 'Mulish',
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Mulish',
          },
          color: '#1F2A37',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Mulish',
          },
          color: '#1F2A37',
          callback: function (label) {
            return `${this.getLabelForValue(label)} €`;
          },
        },
      },
    },
  };

  const onChange = (event) => {
    setTypeDisplay(parseInt(event.target.value));
  };

  return (
    <div className="flex flex-col md:h-[450px] h-[250px]">
      <div className="flex items-center justify-between ">
        <p className="font-bold text-blue-admin font-mulish ">Suivi des commandes</p>
        <div className="flex space-x-3">
          {typeDisplay ===0 && (
            <DatePicker_month
              selected={selectDate}
              onChange={setSelectDate}
              styleInput="inputFocus p-2 pr-6"
            />
          )}
          {typeDisplay ===1 && (
            <DatePicker_year
              selected={selectDate}
              onChange={setSelectDate}
              styleInput="inputFocus p-2 pr-6"
            />
          )}
          <select
            className="p-2 text-white border-2 rounded-lg w-fit bg-red-admin font-mulish inputFocus"
            name=""
            id=""
            onChange={onChange}
          >
            <option value={0} >Par mois</option>
            <option value={1}>Par année</option>
          </select>
        </div>
      </div>

      <Line
        data={dataLine(myData)}
        options={optionsLine}
      ></Line>
    </div>
  );
};

export default LineChartJs;

