import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { typeFile } from '../../tools/file';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';
import DatePicker_date from '../../components/DatePicker_date';

//Import icons
import Check from '../../../assets/images/svg/check.svg';
import Warning from '../../../assets/images/svg/warning.svg';
import FileUpload from '../../../assets/images/svg/file-upload.svg';
import Download from '../../../assets/images/svg/download.svg';

const EditDrivers = () => {
  //  🌱🌱  Init Navigation   🌱🌱
  const navigate = useNavigate();
  const { idDriver } = useParams();

  //  🌱🌱 Init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`permis-${Date.now()}`).current;

  //  🌱🌱 Init store(services)  🌱🌱
  const getDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getDriver);
  const updateDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.updateDriver);
  const deleteDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.deleteDriver);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const setDriverPhone = useStoreActions((action) => action.middleware.Cognito.setDriverPhone);
  const deleteDriverUserCognito = useStoreActions((action) => action.middleware.Cognito.deleteDriverUserCognito);

  //  🌱🌱  Etats (données)  🌱🌱
  const [driver, setDriver] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedFilePermis, setSelectedFilePermis] = useState(null);
  const [selectedFileVm, setSelectedFileVm] = useState(null);
  const [msgErrorDocVm, setMsgErrorDocVm] = useState(false);
  const [msgErrorInputVm, setMsgErrorInputVm] = useState(false);
  const [msgErrorDocPermis, setMsgErrorDocPermis] = useState(false);
  const [msgErrorInputPermis, setMsgErrorInputPermis] = useState(false);
  const [datePermis, setDatePermis] = useState('');
  const [dateVm, setDateVm] = useState('');

  //  🌱🌱  Data Form Edit  🌱🌱
  const formEditDriver = [
    {
      label: 'Prénom',
      inputType: 'text',
      valueName: 'firstName',
      placeholder: 'Prénom',
      errorMsg: 'Veuillez entrer le prénom du chauffeur',
      required: true,
      halfWidth: true,
      defaultValue: driver?.firstName,
    },
    {
      label: 'Nom',
      inputType: 'text',
      valueName: 'lastName',
      placeholder: 'Nom',
      errorMsg: 'Veuillez entrer le nom du chauffeur',
      required: true,
      halfWidth: true,
      defaultValue: driver?.lastName,
    },
    {
      label: 'Numéro',
      inputType: 'text',
      valueName: 'phone',
      placeholder: '+596696000000',
      errorMsg: 'Veuillez saisir un numéro de téléphone au format international +00...',
      required: true,
      regex: /^([+])([0-9]{11,12})$/,
      halfWidth: true,
      defaultValue: driver?.phone,
    },
  ];

  //  🌱🌱 Request call get Driver  🌱🌱
  const callGetDriver = async () => {
    try {
      const res = await getDriver(idDriver);
      setDriver(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱  function updateProfileDriver   🌱🌱
  const updateProfileDriver = async (data) => {
    try {
      const res = await updateDriver(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la modification du chauffeur, veuillez réessayer.');
    }
  };

  //  🌱🌱  request set Phone driver  🌱🌱
  const setPhonedriver = async (data) => {
    try {
      const res = await setDriverPhone(data);
      updateProfileDriver(data);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la modification du chauffeur, veuillez réessayer.');
    }
  };

  //  🌱🌱  request Delete Driver cognito  🌱🌱
  const deleteCognitoDriver = async () => {
    setShowModalDelete(false);
    try {
      const res = await deleteDriverUserCognito(driver);
      deleteProfileDriver();
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la suppression de votre chauffeur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function DeleteProfileDriver   🌱🌱
  const deleteProfileDriver = async () => {
    try {
      const res = await deleteDriver(idDriver);
      navigate(-1);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la suppression de votre chauffeur, veuillez réessayer.');
    }
  };

  //  🌱🌱 Function  handleFileInput permis  🌱🌱
  const handleFilePermisInput = (e) => {
    setSelectedFilePermis(e.target.files[0]);
    setMsgErrorDocPermis(false);
  };

  //  🌱🌱 Function  handleFileInput vm  🌱🌱
  const handleFileVmInput = (e) => {
    setSelectedFileVm(e.target.files[0]);
    setMsgErrorDocVm(false);
  };

  useEffect(() => {
    if (datePermis) {
      setMsgErrorInputPermis(false);
    }
  }, [datePermis]);

  useEffect(() => {
    if (dateVm) {
      setMsgErrorInputVm(false);
    }
  }, [dateVm]);

  const postupdateProfileDriver = (data) => {
    if (data.phone !== driver.phone) {
      setPhonedriver(data);
    } else {
      updateProfileDriver(data);
    }
  };

  //  🌱🌱  function FormCustom Driver  🌱🌱
  const onSubmit = async (value) => {
    if (!datePermis && selectedFilePermis) {
      setMsgErrorInputPermis(true);
    }
    if (!dateVm && selectedFileVm) {
      setMsgErrorInputVm(true);
    }
    if (value.status) {
      if (!datePermis && selectedFilePermis) return;
      if (!dateVm && selectedFileVm) return;
      const newData = {
        ...value.values,
        id: idDriver,
        docPermis: selectedFilePermis ? `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}` : null,
        datePermis: selectedFilePermis ? datePermis : null,
        docVm: selectedFileVm ? `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}` : null,
        dateVm: selectedFileVm ? dateVm : null,
        id_cognito: driver.id_cognito,
      };
      if (selectedFilePermis && selectedFileVm) {
        try {
          await uploadFile({
            file: selectedFilePermis,
            nameFile: `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}`,
            contentType: selectedFilePermis.type,
          });
          try {
            await uploadFile({
              file: selectedFileVm,
              nameFile: `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}`,
              contentType: selectedFileVm.type,
            });
            postupdateProfileDriver(newData);
          } catch (error) {
            toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
          }
        } catch (error) {
          toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
        }
      } else if (selectedFilePermis) {
        try {
          await uploadFile({
            file: selectedFilePermis,
            nameFile: `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}`,
            contentType: selectedFilePermis.type,
          });
          postupdateProfileDriver(newData);
        } catch (error) {
          toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
        }
      } else if (selectedFileVm) {
        try {
          await uploadFile({
            file: selectedFileVm,
            nameFile: `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}`,
            contentType: selectedFileVm.type,
          });
          postupdateProfileDriver(newData);
        } catch (error) {
          toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
        }
      } else {
        postupdateProfileDriver(newData);
      }
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetDriver();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Modifier un compte chauffeur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {driver.id && (
          <FormCustom
            data={formEditDriver}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
        {/* div permis */}
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Permis de conduire</label>
          <label
            htmlFor="dropzone-file-permis"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFilePermis ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFilePermis ? selectedFilePermis.name : ' Cliquez pour ajouter le permis de conduire'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFilePermis ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-permis"
              type="file"
              className="hidden"
              onChange={handleFilePermisInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocPermis ? 'Veuillez ajouter un permis de conduire' : ''}</p>
          <div>
            <label>Date de validité</label>
            <DatePicker_date
              selected={datePermis}
              onChange={setDatePermis}
            />
            <p className="text-red-error">{msgErrorInputPermis ? 'Veuillez ajouter la date de fin validité' : ''}</p>
          </div>
        </div>
        {/* div Vm */}
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Visite Médicale</label>
          <label
            htmlFor="dropzone-file-vm"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFileVm ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFileVm ? selectedFileVm.name : ' Cliquez pour ajouter la visite médical'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFileVm ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-vm"
              type="file"
              className="hidden"
              onChange={handleFileVmInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocVm ? 'Veuillez ajouter une visite médical' : ''}</p>
          <div>
            <label>Date de validité</label>
            <DatePicker_date
              onChange={setDateVm}
              selected={dateVm}
            />
            <p className="text-red-error">{msgErrorInputVm ? 'Veuillez ajouter la date de fin validité' : ''}</p>
          </div>
        </div>
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer ce chauffeur"
            onClick={() => {
              setShowModalDelete(true);
            }}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur le profil de chauffeur ${driver.firstName}  ${driver.lastName} `}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>

      {/* Modal delete */}
      <Modal
        title="Supprimer un chauffeur"
        text="Êtes-vous sûre de vouloir supprimer ce profil ? 
        Cette action est définitive. Vous n’aurez plus accès à ce profil."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteCognitoDriver();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditDrivers;

