import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

import SecondaryButton from '../components/SecondaryButton';

const NavigationCompany = () => {
  //init navigation
  const { pathname } = useLocation();
  const { idClient } = useParams();

  //  🌱🌱 Init store(services)  🌱🌱
  const getClient = useStoreActions((actions) => actions.middleware.ClientsMiddleware.getClient);

  //  🌱🌱  init state  🌱🌱
  const [client, setClient] = useState(null);

  //  🌱🌱  request data client
  const callClients = async () => {
    try {
      const res = await getClient(idClient);
      setClient(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des donnée du client');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callClients();
  }, []);

  //render
  return (
    <section className="md:pt-14 px-4 md:px-9">
      <div className=" flex items center justify-between items-center border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4">
        <h3 className="font-bold text-xl ">{client?.company}</h3>
        {pathname.includes('list-address') && (
          <SecondaryButton
            title={'Ajouter une Adresse'}
            link={`/clients/add-address/${idClient}`}
          />
        )}
      </div>

      <div className="flex md:flex-row md:space-x-4 flex-col space-y-4 md:space-y-0">
        <NavLink
          to="analyse"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Analyses
        </NavLink>
        <NavLink
          to="list-order-not-finish"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Commandes non traitées
        </NavLink>
        <NavLink
          to="list-order-finish"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Commandes traitées
        </NavLink>
        <NavLink
          to="edit-client"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Dossier du client
        </NavLink>
        <NavLink
          to="list-address"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Adresses
        </NavLink>
      </div>
    </section>
  );
};

export default NavigationCompany;

