import React from 'react';
import ListClients from '../shared/components/clients/ListClients';

const Clients = () => {
  return (
    <>
      <ListClients />
    </>
  );
};

export default Clients;

