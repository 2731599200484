/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable react/style-prop-object */
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';

//Import icons
import Check from '../../../assets/images/svg/check.svg';
import Warning from '../../../assets/images/svg/warning.svg';

const EditAdministrators = () => {
  //  🌱🌱  Etats (données)  🌱🌱
  const [admin, setAdmin] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  Data option  🌱🌱
  const statutOptions = [{ name: 'admin' }, { name: 'super admin' }];

  //  🌱🌱  Data Form Edit  🌱🌱
  const formEditAdmin = [
    {
      label: 'Prénom',
      inputType: 'text',
      valueName: 'firstName',
      placeholder: 'Prénom',
      errorMsg: 'Veuillez entrer un prénom',
      required: true,
      halfWidth: true,
      defaultValue: admin?.firstName,
    },
    {
      label: 'Nom',
      inputType: 'text',
      valueName: 'lastName',
      placeholder: 'Nom',
      errorMsg: 'Veuillez entrer un nom',
      required: true,
      halfWidth: true,
      defaultValue: admin?.lastName,
    },
    {
      label: 'E-mail',
      inputType: 'email',
      valueName: 'mail',
      placeholder: 'Email',
      errorMsg: 'Veuillez entrer un email valide',
      required: true,
      regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      halfWidth: true,
      defaultValue: admin?.mail,
    },
    {
      label: 'Mot de passe',
      inputType: 'password',
      valueName: 'password',
      placeholder: 'Mot de passe',
      errorMsg:
        'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.',
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/,
      halfWidth: true,
    },

    {
      label: 'Statut',
      inputType: 'select',
      valueName: 'statut',
      options: statutOptions,
      keyOptions: 'name',
      keyValues: 'name',
      errorMsg: 'Veuillez choisir le statut',
      halfWidth: true,
      required: true,
      style: 'max-w-[318px] flex',
      defaultValue: admin?.statut,
    },
  ];

  //  🌱🌱 init store(services)  🌱🌱
  const getAdmin = useStoreActions((actions) => actions.middleware.AdminMiddleware.getAdmin);
  const updateAdmin = useStoreActions((actions) => actions.middleware.AdminMiddleware.updateAdmin);
  const deleteAdmin = useStoreActions((actions) => actions.middleware.AdminMiddleware.deleteAdmin);
  const deleteAdminUserCognito = useStoreActions((action) => action.middleware.Cognito.deleteAdminUserCognito);
  const setAdminPassword = useStoreActions((action) => action.middleware.Cognito.setAdminPassword);
  const setAdminEmail = useStoreActions((action) => action.middleware.Cognito.setAdminEmail);
  const userAdmin = useStoreState((state) => state.userData.userAdmin);

  //  🌱🌱  Init Navigation
  const navigate = useNavigate();
  const { idAdmin } = useParams();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 Request call get Admin  🌱🌱
  const callGetAdmin = async () => {
    try {
      const res = await getAdmin(idAdmin);
      setAdmin(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des administrateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetAdmin();
  }, []);

  //  🌱🌱  function updateProfileAdmin   🌱🌱
  const updateProfileAdmin = async (data) => {
    try {
      await updateAdmin(data);
      updateMailAdmin(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la modification de votre administrateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function update password Admin   🌱🌱
  const updatePasswordAdmin = async (data) => {
    const newData = {
      ...admin,
      ...data,
    };
    try {
      await setAdminPassword(newData);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la modification du mot de passe, veuillez réessayer.');
    }
  };

  //  🌱🌱  function update password Admin   🌱🌱
  const updateMailAdmin = async (data) => {
    const newData = {
      ...admin,
      ...data,
    };
    try {
      await setAdminEmail(newData);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la modification de l'e-mail, veuillez réessayer.");
    }
  };

  //  🌱🌱  function DeleteProfileAdmin   🌱🌱
  const deleteProfileAdmin = async () => {
    setShowModalDelete(false);
    try {
      await deleteAdmin(admin);
      navigate(-1);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la suppression de votre administrateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function Delete cognito Admin   🌱🌱
  const deleteAdminCognito = async () => {
    try {
     await deleteAdminUserCognito(admin);
      deleteProfileAdmin();
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la suppression de votre administrateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function FormCustom   🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idAdmin,
      };
      if (value.values.password) {
        updatePasswordAdmin(newData);
      }
      updateProfileAdmin(newData);
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full md:pt-14">
      {/* div title */}
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-11 md:pt-0 md:mx-9">
        <h3 className="text-xl font-bold ">Modifier un administrateur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {admin.id && (
          <FormCustom
            data={formEditAdmin}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          {admin?.id && userAdmin.id !== admin?.id && (
            <SecondaryButton
              title="Supprimer cet administrateur"
              onClick={() => {
                setShowModalDelete(true);
              }}
            />
          )}
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur le profil de l’admin ${admin.firstName}  ${admin.lastName} `}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>

      {/* Modal delete */}
      <Modal
        title="Supprimer un admin"
        text="Êtes-vous sûre de vouloir supprimer ce profil ? 
        Cette action est définitive. Vous n’aurez plus accès à ce profil."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteAdminCognito();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditAdministrators;

