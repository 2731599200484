/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';
import { lastDate } from '../../tools/file';
import { dateFormat, timeToMs } from '../../tools/date';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import FileEmpty from '../../../assets/images/svg/file-empty.svg';
import Eye from '../../../assets/images/svg/eye.svg';

const ListTrucks = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.getListTruck);

  //  🌱🌱  Etats (données)  🌱🌱
  const [trucks, setTrucks] = useState([]);

  //  🌱🌱 Request call List Trucks  🌱🌱
  const callListTruck = async () => {
    try {
      const res = await getListTruck();
      setTrucks(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des camions');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListTruck();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="px-4 md:pt-14 md:px-9">
      {/* div Title +button */}
      <div className="flex flex-col justify-between pb-4 mb-4 space-y-4 border-b items center border-gray-admin pt-11 md:pt-0 md:flex-row md:space-y-0">
        <h3 className="text-xl font-bold ">Liste des camions</h3>
        <SecondaryButton
          title=" + Ajouter un nouveau camion"
          link={'add'}
        />
      </div>

      {/* Table list Trucks */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Immatriculation</th>
              <th>Modèle du véhicule</th>
              <th>Année </th>
              <th>Chauffeur</th>
              <th>Carte grise</th>
              <th>Contrôle technique</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {trucks.length ? (
              trucks.map((truck) => {
                let lastDateCt = null;
                if (truck?.controlTechnique?.items.length) {
                  lastDateCt = lastDate(truck?.controlTechnique?.items, 'date');
                }

                return (
                  <tr key={truck.id}>
                    <td>{truck.registration}</td>
                    <td>{truck.model}</td>
                    <td>{truck.year}</td>
                    <td>
                      <div className="flex items-center gap-4">
                        <span className="hoverInitial">
                          {truck?.driver?.firstName[0]}
                          {truck?.driver?.lastName[0]}
                          <span>
                            {truck?.driver?.firstName}
                            {truck?.driver?.lastName}
                          </span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link
                        to={`preview/${truck.id}`}
                        className="flex items-center w-20 px-2 py-1 space-x-2 border rounded-md cursor-pointer border-gray-admin"
                      >
                        <p>Ouvrir</p>
                        <img
                          src={Eye}
                          alt="icone tick circle"
                        />
                      </Link>
                    </td>
                    <td className="text-red-error">
                      {lastDateCt && (Date.now() + timeToMs.mounth > lastDateCt + timeToMs.year)
                        ? `Contrôle technique valide jusqu'au ${dateFormat(lastDateCt + timeToMs.year, '/').full}`
                        : ''}
                      {truck?.controlTechnique?.items.length === 0 && 'Ajouter un contrôle technique !'}
                    </td>
                    <td className="flex items-center justify-end pt-4 space-x-4">
                      {truck?.controlTechnique.items.length === 0 && (
                        <img
                          src={FileEmpty}
                          alt="file"
                        />
                      )}
                      <Link
                        to={`matriculation/${truck.id}`}
                        className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1 "
                      >
                        <img
                          src={More}
                          alt="more icon"
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-lg text-center"
                >
                  La liste des camions est vide. Veuillez ajouter un camion.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListTrucks;

