import React from 'react';
import { Link } from 'react-router-dom';

// import icone
import NotFound from '../assets/images/svg/404.svg';

const Notfound = () => {
  //init store

  //Render
  return (
    <div className=" h-screen bg-red-admin text-white flex flex-col justify-center items-center px-5">
      <img
        src={NotFound}
        alt="notFound"
      />
      <h1 className="text-3xl pt-12 pb-8 font-bold">Oh non ! </h1>
      <h2 className="text-2xl font-semibold pb-12">Nous ne trouvons pas la page que vous recherchez !</h2>
      <Link
        className=" bg-white-admin text-blue-admin font-semibold text-xl py-5 w-[318px] text-center rounded-xl"
        to="/"
      >
        Retour au menu
      </Link>
    </div>
  );
};

export default Notfound;
